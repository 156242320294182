import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";

import api from "services/api";
import getStatusListing from "services/get-user-status-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

export const AppInventoryStatusModal = (props, ref) => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);

	const initialValues = useMemo(() => {
		const values = { status: "", current: "", name: "", id: "", description: "", items: "" };

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isCreate = useMemo(() => !formik.values.id, [formik.values.id]);

	const title = useMemo(() => (isCreate ? "Add Location" : "Edit Location"), [isCreate]);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({ status: obj?.status || "", name: obj?.name || "", current: obj?.name, id: obj?.id, description: obj?.description || "" });
		
		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { id: values.id, name: values.name, status: values.status, description: values.description };

			if (isCreate) {
				await api.post.inventory.createConfiguration(payload);
			}

			if (!isCreate) {
				await api.post.inventory.updateConfiguration(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			setVisible(false);

			formik.resetForm();

			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Location was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Location was updated successfully!" }));
			}

			props.onHandleGetList();
		}
	}, [isCreate, formik, dispatch, props]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-inventory-status-modal" }} open={visible}>
			<div className="inventory-status-modal">
				<h1 className="inventory-status-modal__title">{title}</h1>

				<form className="inventory-status-modal__form" onSubmit={formik.handleSubmit}>
					{!isCreate && <AppInput disabled type="text" name="current" label="Current Location Name" value={formik.values.current} onChange={formik.handleChange} />}

					<AppInput required type="text" name="name" label="New Location Name" placeholder="Enter Location Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

					<AppSelectInput required searchable={false} name="status" label="Status" placeholder="Select..." loadOptions={getStatusListing} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

					<AppInput multiline type="textarea" maxLength={255} name="description" placeholder="Enter Description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />

					<div className="inventory-status-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInventoryStatusModal));

AppInventoryStatusModal.propTypes = {
	ref: PropTypes.object
};
