import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import STATUS from "constants/status";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppEmployeeDirectoryViewContactModal from "components/pages/human-resources/app-employee-directory-view-contact-modal";

import eyeIcon from "assets/images/eye-open-icon.png";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";
import phoneIcon from "assets/images/pages/human-resources/phone-icon.svg";

const dummyData = [
	{ paginationNumbers: 1, name: "Dio", employeeId: "123", mobileNo: "0123456789", todayStatus: STATUS.WORKING, email: "email@email.com", firstMobileNumber: "0123456789", secondMobileNumber: "01234567899", contactFullName: "Bro", contactRelationship: "sister", contactMobileNumber: "012121212" },
	{ paginationNumbers: 2, name: "Jojo", employeeId: "124", mobileNo: "0123456789", todayStatus: STATUS.ON_LEAVE },
	{ paginationNumbers: 3, name: "Davai Lama", employeeId: "125", mobileNo: "0123456789", todayStatus: STATUS.UNACCOUNTED_ABSENCE }
];

const AppEmployeeDirectoryTable = () => {
	const selectedItem = useRef();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [menuAnchor, setMenuAnchor] = useState(null);
	const defaultStatus = useMemo(() => [STATUS.WORKING, STATUS.ON_LEAVE, STATUS.UNACCOUNTED_ABSENCE].join(","), []);
	const status = useMemo(() => {
		const currentStatuses = searchParams.get("status")?.split(",");
		const relevantStatus = currentStatuses?.every((e) => defaultStatus.split(",").includes(e));

		if (!relevantStatus) {
			return defaultStatus;
		} else {
			return searchParams.get("status");
		}
	}, [defaultStatus, searchParams]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", status: status, sort: searchParams.get("sort") || "", tab: "EMPLOYEE_DIRECTORY" });
	const [data, setData] = useState(dummyData);
	const viewContactRef = useRef();

	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");
	}, []);

	const formik = useFormik({
		initialValues: { startDate: "", endDate: "", status: [STATUS.WORKING, STATUS.ON_LEAVE, STATUS.UNACCOUNTED_ABSENCE] },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onViewAttendance = useCallback(() => {
		navigate(pathnames.humanResources.viewAttendance, { state: selectedItem.current });
	}, [navigate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Working", name: STATUS.WORKING },
			{ label: "On Leave", name: STATUS.ON_LEAVE },
			{ label: "Unaccounted Absence", name: STATUS.UNACCOUNTED_ABSENCE }
		];

		return data;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status ? queryParams.current.status.split(",") : []);
	}, [formik]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();
	}, [formik]);

	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;
	}, []);

	const onHandleCloseHolidayTableMenu = useCallback(() => {
		selectedItem.current = null;

		setMenuAnchor(null);
	}, []);

	//prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		setMenuAnchor(event.currentTarget);
	}, [data]);

	const onHandleViewContact = useCallback(() => {
		viewContactRef.current.onHandleShow(selectedItem.current);
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "employeeId",
			label: "Employee ID",
			options: {
				sort: false
			}
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "mobileNo",
			label: "Mobile Number",
			options: {
				sort: false
			}
		},
		{
			name: "todayStatus",
			label: "Today's Status",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => <AppStatus status={value} />
			}
		},
		{
			name: "edit",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={moreIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onToggleTableAction]);

	useEffect(() => {
		setData(dummyData);
	}, []);

	return (
		<div className="app-employee-directory-table">
			<div className="employee-directory-table">
				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Name or Employee ID" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="employee-directory-table-filter">
						<div className="employee-directory-table-filter__container">
							<p className="employee-directory-table-filter__label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

								return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
							})}
						</div>
					</div>
				</AppTableFilterHeader>

				<AppTable data={data} columns={tableColumns} />
			</div>

			<Menu classes={{ root: "employee-directory-table-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseHolidayTableMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onViewAttendance}>
					<img src={eyeIcon} alt="view-attandance" />
					View Attendance
				</MenuItem>

				<MenuItem onClick={onHandleViewContact}>
					<img src={phoneIcon} alt="view-employee-contract" />
					View Employee Contract
				</MenuItem>
			</Menu>

			<AppEmployeeDirectoryViewContactModal ref={viewContactRef} />
		</div>
	);
};

export default AppEmployeeDirectoryTable;
