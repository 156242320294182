import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppButton from "components/app-button";

export const AppEmployeeDirectoryViewContactModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState(null);

	const onHandleShow = useCallback((values) => {
		setData(values);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-employee-directory-view-contact-modal" }} open={visible}>
			<div className="employee-directory-view-contact-modal">
				<h1 className="employee-directory-view-contact-modal__title">Employee Contact</h1>

				<div className="employee-directory-view-contact-modal__container">
					<h2 className="employee-directory-view-contact-modal__header">{data?.name}</h2>

					<div className="employee-directory-view-contact-modal__box">
						<div className="employee-directory-view-contact-modal__details">
							<p className="employee-directory-view-contact-modal__text employee-directory-view-contact-modal__text--bold">Email</p>

							<p className="employee-directory-view-contact-modal__text">{data?.email}</p>
						</div>

						<div className="employee-directory-view-contact-modal__details">
							<p className="employee-directory-view-contact-modal__text employee-directory-view-contact-modal__text--bold">Primary Mobile Number</p>

							<p className="employee-directory-view-contact-modal__text">{data?.firstMobileNumber}</p>
						</div>

						<div className="employee-directory-view-contact-modal__details">
							<p className="employee-directory-view-contact-modal__text employee-directory-view-contact-modal__text--bold">Secondary Mobile Number</p>

							<p className="employee-directory-view-contact-modal__text">{data?.secondMobileNumber}</p>
						</div>
					</div>
				</div>

				<div className="employee-directory-view-contact-modal__container">
					<h2 className="employee-directory-view-contact-modal__header">Emergency Contact</h2>

					<div className="employee-directory-view-contact-modal__box">
						<div className="employee-directory-view-contact-modal__details">
							<p className="employee-directory-view-contact-modal__text employee-directory-view-contact-modal__text--bold">Full Name</p>

							<p className="employee-directory-view-contact-modal__text">{data?.contactFullName}</p>
						</div>

						<div className="employee-directory-view-contact-modal__details">
							<p className="employee-directory-view-contact-modal__text employee-directory-view-contact-modal__text--bold">Relationship</p>

							<p className="employee-directory-view-contact-modal__text">{data?.contactRelationship}</p>
						</div>

						<div className="employee-directory-view-contact-modal__details">
							<p className="employee-directory-view-contact-modal__text employee-directory-view-contact-modal__text--bold">Mobile Number</p>

							<p className="employee-directory-view-contact-modal__text">{data?.contactMobileNumber}</p>
						</div>
					</div>
				</div>

				<AppButton className="employee-directory-view-contact-modal__button" type="button" label="Done" onClick={onHandleDismiss} />
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppEmployeeDirectoryViewContactModal));

AppEmployeeDirectoryViewContactModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
