import React, { Fragment, useCallback, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import getAssetTypeListing from "services/get-asset-type-listing";

import ERRORS from "constants/errors";
import validateFileSize from "common/validate-file-size";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";

import trashIcon from "assets/images/trash-icon.png";
import addIcon from "assets/images/blue-add-icon.svg";

const AppWorkCompletionWorkDetails = (props, ref) => {
	const navigate = useNavigate();
	const [formVisible, setFormVisible] = useState(false);
	const signatureTypes = useMemo(() => ({ attachment: "attachment" }), []);

	const initialValues = useMemo(() => {
		const values = {
			workScope: "",
			assetName: "",
			spareParts: "",
			description: "",
			caption: "",
			attachment: [{ name: "", signature: null }]
		};

		return values;
	}, []);

	const onHandleSubmit = (values) => {
		props.onSubmit(values);

		navigate(pathnames.workCompletionReport.workCompletionReport);
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			workScope: yup.string().required(ERRORS.REQUIRED),
			assetName: yup.string().required(ERRORS.REQUIRED),
			spareParts: yup.string().required(ERRORS.REQUIRED),
			description: yup.string().required(ERRORS.REQUIRED),
			caption: yup.string().required(ERRORS.REQUIRED),
			attachment: yup.array().of(
				yup.object({
					name: yup.string().required(ERRORS.REQUIRED),
					signature: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize)
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleBack = useCallback(() => {
		navigate(pathnames.workCompletionReport.workCompletionReportCreateEdit + "CREATE");
	}, [navigate]);

	//prettier-ignore
	const onHandleRemoveConfirmBy = useCallback((type, removeIndex) => {
        const nextFields = [...formik.values[type]].filter((_, i) => i !== removeIndex);

        formik.setFieldValue(type, nextFields);
    }, [formik]);

	//prettier-ignore
	const onHandleAddConfirmBy = useCallback((type) => {
        if (!formVisible) {
            setFormVisible(true);
        } else {
            const field = { name: "", signature: null };
            const fields = [...formik.values[type]];

            fields.push(field);

            formik.setFieldValue(type, fields);
        }
    }, [formik, formVisible]);

	return (
		<div className="app-work-completion-details">
			<div className="work-completion-details">
				<form className="work-completion-report-details__form" onSubmit={formik.handleSubmit}>
					<div className="work-completion-details__container">
						<div className="work-completion-details__row work-completion-details__row--divider">
							<p className="work-completion-details__label">Finding Details</p>

							<AppButton className="work-completion-details__add-record-button" outline type="button" label="Add Record" icon={addIcon} onClick={() => onHandleAddConfirmBy(signatureTypes.attachment)} />
						</div>

						{formVisible && (
							<form className="work-completion-details__form" onSubmit={formik.handleSubmit}>
								{formik.values.attachment.map((o, i) => {
									const error = formik.errors.attachment?.[i];
									const touched = formik.touched.attachment?.[i];

									return (
										<Fragment key={i}>
											<div className="work-completion-details__row">
												<p className="work-completion-details__label">New Record</p>

												<AppButton className="work-completion-details__delete-record-button" outline type="button" label="Remove Record" icon={trashIcon} onClick={() => onHandleRemoveConfirmBy(signatureTypes.attachment, i)} />
											</div>

											<div className="work-completion-details__row">
												{/* prettier-ignore */}
												<AppSelectInput required pagination name="assetName" label="Asset Name" placeholder="Select..." loadOptions={getAssetTypeListing} value={formik.values.assetName} error={formik.errors.assetName} touched={formik.touched.assetName} onChange={formik.handleChange} />

												{/* prettier-ignore */}
												<AppInput disabled type="text" name="assetType" label="Asset Type" placeholder="" value={formik.values.assetType} error={formik.errors.assetType} touched={formik.touched.assetType} onChange={formik.handleChange} />
											</div>

											<div className="work-completion-details__row">
												{/* prettier-ignore */}
												<AppSelectInput required name="spareParts" label="Spare Parts" placeholder="Select..." options={[]} value={formik.values.spareParts} error={formik.errors.spareParts} touched={formik.touched.spareParts} onChange={formik.handleChange} />

												{/* prettier-ignore */}
												<AppInput required type="text" name="workScope" label="Work Scope" placeholder="Enter Work Scope" value={formik.values.workScope} error={formik.errors.workScope} touched={formik.touched.problem} onChange={formik.handleChange} />
											</div>

											<div className="work-completion-details__row">
												{/* prettier-ignore */}
												<AppInput required multiline type="textarea" name="caption" label="Caption" placeholder="Enter Caption" value={formik.values.caption} error={formik.errors.caption} touched={formik.touched.caption} onChange={formik.handleChange} />

												{/* prettier-ignore */}
												<AppInput required multiline type="textarea" name="description" label="Work Description" placeholder="Enter Work Description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />
											</div>

											<p className="work-completion-details__label">Attachments</p>

											<p className="work-completion-details__instructions">Please ensure that all images are in either jpg or png format with file size not exceeding 5MB.</p>

											<div className="work-completion-details__row">
												{/* prettier-ignore */}
												<AppInputDragAndDrop name={`attachment[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />

												{/* prettier-ignore */}
												<AppInputDragAndDrop name={`attachment[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />
											</div>

											<div className="work-completion-details__signature">
												{/* prettier-ignore */}
												<AppInputDragAndDrop name={`attachment[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />

												{/* prettier-ignore */}
												<AppInputDragAndDrop name={`attachment[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />
											</div>
										</Fragment>
									);
								})}
							</form>
						)}
					</div>

					<div className="work-completion-details__button-container">
						{/* prettier-ignore */}
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						{/* prettier-ignore */}
						<AppButton type="submit" label="Create" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</div>
	);
};
export default AppWorkCompletionWorkDetails;
