import React, { useMemo } from "react";

import AppTabs from "components/app-tabs";
import AppClaimSummary from "components/pages/human-resources/app-claim-summary-table";
import AppClaimHistoryTable from "components/pages/human-resources/app-claim-history-table";
import AppPendingClaimTable from "components/pages/human-resources/app-pending-claim-table";

import claimIcon from "assets/images/pages/human-resources/claim.svg";
import clockIcon from "assets/images/pages/human-resources/clock-icon.svg";
import activeClaimIcon from "assets/images/pages/human-resources/claim-active.svg";
import activeClockIcon from "assets/images/pages/human-resources/clock-active-icon.svg";
import claimApprovalIcon from "assets/images/pages/human-resources/claim-approval-icon.svg";
import activeClaimApprovalIcon from "assets/images/pages/human-resources/claim-approval-active-icon.svg";

const PageViewClaims = () => {
	//prettier-ignore
	const viewClaimTabs = useMemo(() => [
		{ label: "Claim Summary", icon: claimIcon, activeIcon: activeClaimIcon, name: "CLAIM_SUMMARY", component: <AppClaimSummary />, accessible: true },
		{ label: "Claim History", icon: clockIcon, activeIcon: activeClockIcon, name: "CLAIM_HISTORY", component: <AppClaimHistoryTable />, accessible: true },
		{ label: "Pending Claim", icon: claimApprovalIcon, activeIcon: activeClaimApprovalIcon, name: "PENDING_CLAIM", component: <AppPendingClaimTable />, accessible: true },
	], []);

	return (
		<div className="page-view-claims">
			<div className="view-claims">
				<div className="view-claims__header">
					<h1 className="view-claims__title">Vivienne Tang Jia Yi</h1>

					<h1 className="view-claims__id">MCE0002-24</h1>
				</div>

				<div className="view-claims__body">
					<AppTabs tabs={viewClaimTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageViewClaims;
