import React, { useCallback, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import { useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import sanitizeObject from "common/sanitize-object";
import { formatCurrency } from "common/format-currency-pattern";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";

import editIcon from "assets/images/edit-icon.png";
import filterIcon from "assets/images/filter-icon.png";
import searchIcon from "assets/images/search-icon.png";
import downloadIcon from "assets/images/download-icon.png";

const invoiceData = [
	{ paginationNumber: 1, id: "INV-001", customerName: "PAN X-PREM AC", total: 20, createdBy: "ADM0001 Ahmad Muhammad Ali", status: "Paid" },
	{ paginationNumber: 2, id: "INV-002", customerName: "Peter Pan", total: 10, createdBy: "ADM0001 Ahmad Muhammad Ali", status: "Unpaid" }
];

const PageInvoices = () => {
	const navigate = useNavigate();
	const [data, setData] = useState(invoiceData);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const queryParams = useRef({ page: 0, value: searchParams.get("value") || "" });
	const [menuAnchor, setMenuAnchor] = useState(null);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.value = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const formik = useFormik({ initialValues: { createdBy: "" } });

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);

		formik.resetForm();
	}, [formik]);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const onHandleDownload = useCallback(() => {}, []);

	const onHandleEditInvoice = useCallback(() => {
		navigate(pathnames.sales.invoiceCreateEdit + "INV-001");
	}, [navigate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Paid", name: "paid" },
			{ label: "Void", name: "void" },
			{ label: "Draft", name: "draft" },
			{ label: "Unpaid", name: "unpaid" }
		];

		return data;
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumber",
			label: "#",
			options: {
				sort: false,
			},
		},
		{
			name: "id",
			label: "Invoice ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "customerName",
			label: "Customer Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "total",
			label: "Total (MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true,
				setCellHeaderProps: () => ({ className: "table__price" }),
				setCellProps: () => ({ className: "table__price" }),
				customBodyRender: (value, tableMeta) => {
					return formatCurrency(value);
				}, 
			},
		},
		{
			name: "createdBy",
			label: "Created By",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowStatus = tableMeta.rowData[5].toLowerCase();

					if (rowStatus === "draft" || rowStatus === "unpaid") {
						return (
							<button type="button" className="table__action" onClick={() => onHandleEditInvoice(tableMeta)}>
								<img src={editIcon} alt="edit-icon" />
							</button>
						);
					}
				},
			},
		},
	], [onHandleEditInvoice]);

	return (
		<div className="page-invoices">
			<div className="invoices">
				<h1 className="invoices__title">Invoices</h1>

				<div className="invoices__header">
					<div className="invoices__search-input">
						<AppInput type="text" name="search" placeholder="Search by Customer Alias or PIC Name or Email Address" endIcon={searchIcon} iconPosition="end" defaultValue={queryParams.current.value} onChange={onHandleDebounceSearch} />

						<div className="invoices__filter-button">
							<AppButton outline type="button" label="Filter" icon={filterIcon} onClick={onToggleFilterMenu} />
						</div>
					</div>

					<div className="invoices__download-button">
						<AppButton outline type="button" label="Download Template" icon={downloadIcon} onClick={onHandleDownload} />
					</div>
				</div>

				<div className="invoices__body">
					<AppTable data={data} columns={tableColumns} />
				</div>
			</div>

			<Menu classes={{ root: "invoices-filter-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<div className="filter-menu">
					<div className="filter-menu__body">
						<div className="filter-menu__column">
							<AppInput type="text" label="Created by" name="createdBy" placeholder="User ID or Name" value={formik.values.createdBy} onChange={formik.handleChange} />
						</div>

						<div className="filter-menu__status">
							<p className="filter-menu__label">Status</p>

							<div className="filter-menu__status-checkbox">
								{menuFilterStatus.map((o) => {
									return <AppCheckbox key={o.label} onClick={(v) => formik.setFieldValue(o.name, v)} label={o.label} value={formik.values[o.name]} />;
								})}
							</div>
						</div>
					</div>

					<div className="filter-menu__footer">
						<button type="button" className="filter-menu__button" onClick={formik.resetForm}>
							Clear All Filters
						</button>

						<div className="filter-menu__buttons">
							<button type="button" className="filter-menu__button filter-menu__button--cancel" onClick={onHandleCloseMenu}>
								Cancel
							</button>
							<button type="button" className="filter-menu__button filter-menu__button--apply" onClick={formik.handleSubmit}>
								Apply
							</button>
						</div>
					</div>
				</div>
			</Menu>
		</div>
	);
};

export default PageInvoices;
