import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Menu, MenuItem } from "@mui/material";

import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppAddExistingAssetsModal from "components/pages/work-inspection-listing/app-add-existing-assets-modal";
import AppReportDetailsCreateEditModal from "components/pages/work-inspection-listing/app-report-details-create-edit-modal";
import AppWorkInspectionConfirmDeleteAssetModal from "components/pages/work-inspection-listing/app-work-inspection-confirm-delete-asset-modal";

import editIcon from "assets/images/edit-icon.png";
import addIcon from "assets/images/blue-add-icon.svg";
import removeIcon from "assets/images/trash-icon.png";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";

const AppReportDetailsAssetTable = (props) => {
	const siteId = useMemo(() => props.siteId, [props.siteId]);
	const isContract = useMemo(() => props.isContract, [props.isContract]);
	const onHandleRemoveAsset = useMemo(() => props.onHandleRemoveAsset, [props.onHandleRemoveAsset]);
	const onHandleUpdateWIRAssets = useMemo(() => props.onHandleUpdateWIRAssets, [props.onHandleUpdateWIRAssets]);
	const wirAssets = useMemo(() => props.wirAssets?.map((o, i) => ({ ...o, paginationNumbers: i + 1 })), [props.wirAssets]);
	const onHandleGetDetails = useMemo(() => props.onHandleGetDetails, [props.onHandleGetDetails]);
	const selectedItem = useRef();
	const reportDetailsAssetRef = useRef();
	const addExistingAssetsModalRef = useRef();
	const confirmDeleteAssetModalRef = useRef();
	const [data, setData] = useState({ content: wirAssets });
	const [menuAnchor, setMenuAnchor] = useState(null);

	const onHandleCloseMenu = useCallback(() => {
		selectedItem.current = null;

		setMenuAnchor(null);
	}, []);

	// prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data.content[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		setMenuAnchor(event.currentTarget);
	}, [data]);

	// prettier-ignore
	const onHandleRemoveReportDetailsAsset = useCallback((tableMeta) => {
		if (isContract) selectedItem.current = { ...data.content[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		confirmDeleteAssetModalRef.current.onHandleShow(selectedItem.current);

		onHandleCloseMenu();
	}, [data.content, onHandleCloseMenu, isContract]);

	const onHandleAddReportDetailsAsset = useCallback(() => {
		if (isContract) {
			addExistingAssetsModalRef.current.onHandleShow(wirAssets);
		} else {
			reportDetailsAssetRef.current.onHandleShow(selectedItem.current);
		}

		onHandleCloseMenu();
	}, [isContract, onHandleCloseMenu, wirAssets]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "assetName",
			label: "Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "customerAssetName",
			label: "Customer Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "assetType",
			label: "Asset Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "workspace",
			label: "Workspace",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		isContract ? {
			name: "remove",
			label: "Remove",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleRemoveReportDetailsAsset(tableMeta)}>
							<img src={removeIcon} alt="remove-icon" />
						</button>
					);
				}
			}
		} : {
			name: "actions",
			label: "Actions",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={moreIcon} alt="edit-icon" />
						</button>
					);
				},
			}
		}
	], [isContract, onHandleRemoveReportDetailsAsset, onToggleTableAction]);

	useEffect(() => {
		const wirAssetsCopy = structuredClone(wirAssets);

		setData({ content: wirAssetsCopy });
	}, [wirAssets]);

	return (
		<div className="app-report-details-asset-table">
			<div className="report-details-asset-table__header">
				<p className="report-details-asset-table__label report-details-asset-table__label--table">Assets</p>

				{siteId && <AppButton outline type="button" label="Add Assets" icon={addIcon} onClick={onHandleAddReportDetailsAsset} />}
			</div>

			<div className="report-details-asset-table">
				<AppTable data={data.content} columns={tableColumns} />
			</div>

			<AppAddExistingAssetsModal ref={addExistingAssetsModalRef} siteId={siteId} onHandleUpdateWIRAssets={onHandleUpdateWIRAssets} onHandleGetDetails={onHandleGetDetails} />

			<AppReportDetailsCreateEditModal ref={reportDetailsAssetRef} siteId={siteId} wirAssets={wirAssets} onHandleUpdateWIRAssets={onHandleUpdateWIRAssets} />

			<AppWorkInspectionConfirmDeleteAssetModal ref={confirmDeleteAssetModalRef} isContract={isContract} onHandleRemoveAsset={onHandleRemoveAsset} />

			<Menu classes={{ root: "table-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleAddReportDetailsAsset}>
					<img src={editIcon} alt="asset-edit" />
					Edit
				</MenuItem>

				<MenuItem onClick={onHandleRemoveReportDetailsAsset}>
					<img src={removeIcon} alt="asset-remove" />
					Remove
				</MenuItem>
			</Menu>
		</div>
	);
};

export default AppReportDetailsAssetTable;
