import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import INVENTORY_STOCK_ACTION from "constants/inventory-stock-action";

import AppInput from "components/app-input";
import AppButton from "components/app-button";

export const AppInventoryMinusStockModal = (props, ref) => {
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const [visible, setVisible] = useState(false);

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			status: "",
			costPrice: "",
			supplierName: "",
			retailPrice: "",
			inventoryLocationId: "",
			quantity: "",
			quantityToDeduct: "",
			remarks: ""
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			quantityToDeduct: yup
				.number()
				.test("exceedReservedQuantity", "Resulting quantity cannot be less than reserved quantity", function (value, { parent }) {
					return parent.quantity - value >= parent.reservedQuantity;
				})
				.test("quantityToDeductNotZero", "Quantity to deduct cannot be 0", function (value) {
					return value;
				})
				.required(ERRORS.REQUIRED),
			remarks: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleShow = useCallback((data) => {
		formik.setValues({ ...data, quantityToDeduct: 0 });

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			let payload = {
				inventoryId: values.id,
				inventoryLocationId: values.inventoryLocationId,
				supplierName: values.supplierName,
				quantity: values.quantityToDeduct,
				costPrice: values.costPrice,
				retailPrice: values.retailPrice,
				status: values.status,
				remark: values.remarks,
				action: INVENTORY_STOCK_ACTION.MINUS_STOCK
			};

			response = await api.post.inventory.updateStock(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			onHandleDismiss();

			props.onHandleGetDetails(formik.values.id);

			dispatch(promptLayoutAlertMessage({ message: "Stock count was updated successfully!" }));
		}
	}, [formik, onHandleDismiss, props, dispatch]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow
	}));

	return (
		<Modal classes={{ root: "app-inventory-minus-stock-modal" }} open={visible}>
			<div className="inventory-minus-stock-modal">
				<h1 className="inventory-minus-stock-modal__title">Minus Stock</h1>

				<div className="inventory-minus-stock-modal__text">Current Quantity {formik.values.quantity}</div>

				<form className="inventory-minus-stock-modal__form" onSubmit={formik.handleSubmit}>
					<AppInput type="number" required disabled={restricted} name="quantityToDeduct" label="Quantity to Deduct" placeholder="Enter Quantity to Deduct" value={formik.values.quantityToDeduct} error={formik.errors.quantityToDeduct} touched={formik.touched.quantityToDeduct} onChange={formik.handleChange} />

					<AppInput type="textarea" required multiline maxLength={255} disabled={restricted} name="remarks" label="Remarks" placeholder="Enter Remarks" value={formik.values.remarks} error={formik.errors.remarks} touched={formik.touched.remarks} onChange={formik.handleChange} />

					<div className="inventory-minus-stock-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInventoryMinusStockModal));

AppInventoryMinusStockModal.propTypes = {
	defaultValues: PropTypes.object
};
