import React, { useMemo } from "react";

import AppTabs from "components/app-tabs";
import AppInventoryHistoryMovementTable from "components/pages/inventory/app-inventory-history-movement-table";
import AppInventoryHistoryRequestsTable from "components/pages/inventory/app-inventory-history-requests-table";

import movementIcon from "assets/images/pages/inventory/movement-icon.svg";
import requestsIcon from "assets/images/pages/inventory/requests-icon.svg";
import movementActiveIcon from "assets/images/pages/inventory/movement-active-icon.svg";
import requestsActiveIcon from "assets/images/pages/inventory/requests-active-icon.svg";

const PageInventoryHistory = () => {
	//prettier-ignore
	const InventoryTabs = useMemo(() => [
		{ label: "Requests", icon: requestsIcon, activeIcon: requestsActiveIcon, name: "REQUESTS", component: <AppInventoryHistoryRequestsTable />, accessible: true },
		{ label: "Movement", icon: movementIcon, activeIcon: movementActiveIcon, name: "MOVEMENT", component: <AppInventoryHistoryMovementTable />, accessible: true },
	], []);

	return (
		<div className="page-inventory-history">
			<div className="inventory-history">
				<h1 className="inventory-history__title">Inventory History</h1>

				<div className="inventory-history__body">
					<AppTabs tabs={InventoryTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageInventoryHistory;
