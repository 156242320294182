import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import getSiteStatus from "services/get-site-status";
import getStateListing from "services/get-state-listing";
import getRegionListing from "services/get-region-listing";
import getPostcodeListing from "services/get-postcode-listing";
import getWorkspaceListing from "services/get-workspace-listing";
import getBuildingTypeListing from "services/get-building-type-listing";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppInputMultiSelect from "components/app-input-select-multiple";
import AppCustomerSiteDetailPicTable from "components/pages/customer/app-customer-site-details-pic-table";

const AppCustomerSiteDetails = (props, ref) => {
	const { id, contractId } = useParams();
	const navigate = useNavigate();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_CONTRACT_SITE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const siteDetails = useMemo(() => props.siteDetails, [props.siteDetails]);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const submitLabel = useMemo(() => (isCreate ? "Create" : "Update"), [isCreate]);

	const initialValues = useMemo(() => {
		let values = {
			customerContractId: siteDetails?.customerContractId || contractId,
			referenceNo: "",
			name: "",
			address1: "",
			address2: "",
			postcode: "",
			state: "",
			locationUrl: "",
			region: "",
			configBuildingTypeId: "",
			configWorkspaceIds: [],
			remark: "",
			status: "",
			picContacts: [],
			picContactIds: []
		};

		if (siteDetails) {
			values = {
				...siteDetails,
				configWorkspaceIds: siteDetails.siteWorkspaceIds,
				picContacts: [],
				picContactIds: []
			};
		}

		return values;
	}, [contractId, siteDetails]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			address1: yup.string().required(ERRORS.REQUIRED),
			postcode: yup.string().required(ERRORS.REQUIRED),
			state: yup.string().required(ERRORS.REQUIRED),
			locationUrl: yup.string().required(ERRORS.REQUIRED),
			configBuildingTypeId: yup.string().required(ERRORS.REQUIRED),
			configWorkspaceIds: yup.array().of(yup.string().required(ERRORS.REQUIRED)),
			status: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			props.onSubmit(values);
		}
	});

	// prettier-ignore
	const onHandleUpdatePicContacts = useCallback((values) => {
		if (restricted) return;

		formik.setFieldValue("picContacts", values);
	}, [formik, restricted]);

	// prettier-ignore
	const onHandleUpdatePicContactIds = useCallback((values) => {
		if (restricted) return;

		formik.setFieldValue("picContactIds", values);
	}, [formik, restricted]);

	// prettier-ignore
	const onHandleRemovePicContact = useCallback((id) => {
		if (restricted) return;

		let nextPicContactIds = structuredClone(formik.values.picContactIds);
		let nextPicContacts = structuredClone(formik.values.picContacts);

		nextPicContactIds = nextPicContactIds.filter((o) => id !== o);
		nextPicContacts = nextPicContacts.filter((o) => id !== o.id);

		formik.setFieldValue("picContactIds", nextPicContactIds);
		formik.setFieldValue("picContacts", nextPicContacts);
	}, [formik, restricted]);

	const getRegionLoadOptions = useCallback(() => getRegionListing({ state: formik.values.state }), [formik.values.state]);

	const getPostcodeLoadOptions = useCallback(() => getPostcodeListing(`${formik.values.state}/${formik.values.region}`), [formik.values.state, formik.values.region]);

	const onHandleBack = useCallback(() => {
		const nextContractId = siteDetails?.customerContractId || contractId;

		navigate(pathnames.customer.customerCreateEditContract + nextContractId);
	}, [navigate, siteDetails?.customerContractId, contractId]);

	useImperativeHandle(ref, () => ({
		onHandleSubmit: formik.handleSubmit
	}));

	return (
		<div className="app-customer-site-details">
			<div className="customer-site-details">
				<form className="customer-site-details__form" onSubmit={formik.handleSubmit}>
					<div className="customer-site-details__container">
						<p className="customer-site-details__label">Details</p>

						<div className="customer-site-details__row customer-site-details__row--divider">
							{!isCreate && /* prettier-ignore */ <AppInput disabled type="text" name="id" label="Site ID" placeholder="Site ID" value={formik.values.referenceNo} error={formik.errors.referenceNo} touched={formik.touched.referenceNo} onChange={formik.handleChange} />}

							<AppSelectInput required name="status" disabled={restricted} label="Site Status" placeholder="Select..." loadOptions={getSiteStatus} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

							<AppInput required type="text" name="name" disabled={restricted} label="Site Name" placeholder="Site Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

							<AppInput required type="text" name="locationUrl" disabled={restricted} label="Location URL" placeholder="Set location URL here" value={formik.values.locationUrl} error={formik.errors.locationUrl} touched={formik.touched.locationUrl} onChange={formik.handleChange} />
						</div>

						<p className="customer-site-details__label">Site PICs</p>

						<div className="customer-site-details__table">
							<AppCustomerSiteDetailPicTable disabled={restricted} picContacts={formik.values.picContacts} picContactIds={formik.values.picContactIds} localData={formik.values.picContacts} contractId={siteDetails?.customerContractId} onHandleUpdatePicContacts={onHandleUpdatePicContacts} onHandleUpdatePicContactIds={onHandleUpdatePicContactIds} onHandleRemovePicContact={onHandleRemovePicContact} />
						</div>

						<div className="customer-site-details__row">
							<AppInput required type="text" name="address1" disabled={restricted} label="Address Line 1" value={formik.values.address1} error={formik.errors.address1} touched={formik.touched.address1} onChange={formik.handleChange} />

							<AppInput required type="text" name="address2" disabled={restricted} label="Address Line 2" value={formik.values.address2} error={formik.errors.address2} touched={formik.touched.address2} onChange={formik.handleChange} />

							<AppSelectInput required name="state" disabled={restricted} label="State" placeholder="Select..." loadOptions={getStateListing} value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

							<AppSelectInput required type="text" name="region" disabled={restricted || !formik.values.state} label="Region" placeholder="Select..." loadOptions={getRegionLoadOptions} value={formik.values.region} error={formik.errors.region} touched={formik.touched.region} onChange={formik.handleChange} />

							<AppSelectInput required name="postcode" disabled={restricted || !formik.values.region} label="Postcode" placeholder="Select..." loadOptions={getPostcodeLoadOptions} value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />

							<AppSelectInput required pagination name="configBuildingTypeId" disabled={restricted} label="Building Type" placeholder="Select..." loadOptions={getBuildingTypeListing} value={formik.values.configBuildingTypeId} error={formik.errors.configBuildingTypeId} touched={formik.touched.configBuildingTypeId} onChange={formik.handleChange} />

							<AppInputMultiSelect required name="configWorkspaceIds" disabled={restricted} label="Work Space" loadOptions={getWorkspaceListing} value={formik.values.configWorkspaceIds} error={formik.errors.configWorkspaceIds} touched={formik.touched.configWorkspaceIds} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="customer-site-details__container">
						<p className="customer-site-details__label">Remarks</p>

						<div className="customer-site-details__row">
							<AppInput multiline type="textarea" maxLength={255} name="remark" disabled={restricted} placeholder="Enter Remarks" value={formik.values.remark} error={formik.errors.remark} touched={formik.touched.remark} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="customer-site-details__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton type="submit" disabled={restricted} label={submitLabel} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default memo(forwardRef(AppCustomerSiteDetails));
