import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import dayjs from "dayjs";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import AppButton from "components/app-button";

const AppHrViewHolidaysDeleteModal = (props, ref) => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const [holiday, setHoliday] = useState(null);

	const onHandleShow = useCallback((obj) => {
		const nextHoliday = {
			holidayId: obj.id,
			holidayName: obj.name,
			holidayDate: obj.holidayDate
		};

		setHoliday(nextHoliday);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleConfirm = useCallback(async () => {
		let response = null;

		const params = {
			"holiday-id": holiday.holidayId
		};

		try {
			await api.post.humanResource.deleteHoliday(params);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}
		if (response) {
			setVisible(false);

			props.onHandleGetList();

			dispatch(promptLayoutAlertMessage({ message: "Holiday was removed successfully!" }));
		}
	}, [dispatch, holiday, props]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-hr-view-holidays-delete-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="confirmation-modal" aria-describedby="confirmation-modal">
			<div className="hr-view-holidays-delete-modal">
				<p className="hr-view-holidays-delete-modal__title">Are you sure?</p>

				<div className="hr-view-holidays-delete-modal__body">
					<p className="hr-view-holidays-delete-modal__description">
						After clicking confirm, the holiday <span className="hr-view-holidays-delete-modal__description hr-view-holidays-delete-modal__description--bold">{holiday?.holidayName}</span> on {dayjs(holiday?.holidayDate).format("DD/MM/YYYY")} will be <span className="hr-view-holidays-delete-modal__description hr-view-holidays-delete-modal__description--red">deleted.</span>
					</p>

					<p className="hr-view-holidays-delete-modal__description hr-view-holidays-delete-modal__description--red">Kindly be informed that this action will delete all future instances of this holiday (if applicable) for all user’s.</p>

					<div className="hr-view-holidays-delete-modal__button-container">
						<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />

						<AppButton type="button" label="Confirm" onClick={onHandleConfirm} />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppHrViewHolidaysDeleteModal));

AppHrViewHolidaysDeleteModal.propTypes = {
	onHandleGetList: PropTypes.func.isRequired
};
