import React from "react";

import logo from "assets/images/logo.png";
import sharedGoalsAmico from "assets/images/shared-goals-amico.png";

const PageDeactivateMobileUserSuccess = () => {
	return (
		<div className="page-deactivate-mobile-user-success">
			<main className="deactivate-mobile-user-success">
				<div className="deactivate-mobile-user-success__container">
					<div className="deactivate-mobile-user-success__logo">
						<img src={logo} alt="logo" />
					</div>

					<h1 className="deactivate-mobile-user-success__title">Account Deleted</h1>

					<p className="deactivate-mobile-user-success__description">
						Your account has been deleted. <br /> Thank you for your confirmation.
					</p>

					<div className="deactivate-mobile-user-success__hero">
						<img src={sharedGoalsAmico} alt="hero" />
					</div>
				</div>
			</main>
		</div>
	);
};

export default PageDeactivateMobileUserSuccess;
