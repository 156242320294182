import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import serveRequestErrors from "common/serve-request-errors";

const getPICListing = async (params) => {
	let response = null;
	let errorMessage = null;

	try {
		const payload = {
			"customer-id": params.id,
			queryParams: sanitizeObject({ size: 10, page: 0, id: params.id, keyword: params.keyword || "" })
		};

		const res = await api.get.customer.customerPic(payload);

		const content = res.content.map((o) => ({ label: o.picName, value: o.id }));

		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getPICListing;
