import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ENDPOINT_PATH from "constants/end-point-path";

import AppTable from "components/app-table/app-table";
import AppViewUpcomingOngoingEmployeeLeaveTable from "components/pages/human-resources/app-view-upcoming-ongoing-employee-leave-table";

const AppViewEmployeeLeaveSummaryTable = () => {
	const { id } = useParams();
	const [data, setData] = useState([]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;

	const onHandleGetList = useCallback(async (uniqueId) => {
		let response = null;

		const params = sanitizeObject({
			"employee-id": uniqueId
		});

		try {
			response = await api.get.humanResource.employeeLeaveSummary(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setData(response);
		}
	}, []);

	const tableColumns = [
		{
			name: "leaveType",
			label: "Leave Type",
			options: {
				sort: false
			}
		},
		{
			name: "entitledDays",
			label: "Entitled Days",
			options: {
				sort: false
			}
		},
		{
			name: "balanceDays",
			label: "Balance Days",
			options: {
				sort: false
			}
		},
		{
			name: "daysTaken",
			label: "Days Taken",
			options: {
				sort: false
			}
		}
	];

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		customFooter: () => null,
	}), []);

	useEffect(() => {
		onHandleGetList(id);
	}, [onHandleGetList, id]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_LEAVE_SUMMARY);
		};
	}, [cancelRequest]);

	return (
		<div className="app-view-employee-leave-summary-table">
			<div className="view-employee-leave-summary-table">
				<div className="view-employee-leave-summary-table__body">
					<AppTable data={data.leaveSummaryVoList || []} columns={tableColumns} options={tableOptions} />
				</div>

				<AppViewUpcomingOngoingEmployeeLeaveTable upComingData={data?.upcomingLeaveVoList || []} onGoingData={data?.ongoingLeaveVoList || []} />
			</div>
		</div>
	);
};

export default AppViewEmployeeLeaveSummaryTable;
