import React, { useMemo } from "react";

import { useSelector } from "react-redux";

import ROLES from "constants/roles";

import AppTabs from "components/app-tabs";
import AppAllClaimHistoryTable from "components/pages/human-resources/app-all-claim-history-table";
import AppAllPendingClaimTable from "components/pages/human-resources/app-all-pending-claim-table";
import AppClaimManagementEmployeeDirectoryTable from "components/pages/human-resources/app-claim-management-employee-directory-table";

import clockIcon from "assets/images/pages/human-resources/clock-icon.svg";
import activeClockIcon from "assets/images/pages/human-resources/clock-active-icon.svg";
import claimApprovalIcon from "assets/images/pages/human-resources/claim-approval-icon.svg";
import employeeDirectoryIcon from "assets/images/pages/human-resources/employee-directory-icon.svg";
import activeClaimApprovalIcon from "assets/images/pages/human-resources/claim-approval-active-icon.svg";
import employeeDirectoryActiveIcon from "assets/images/pages/human-resources/employee-directory-active-icon.svg";

const PageClaimManagement = () => {
	const profile = useSelector((state) => state.profile);

	//prettier-ignore
	const claimManagementTabs = useMemo(() => [
		{ label: "Employee Directory", icon: employeeDirectoryIcon, activeIcon: employeeDirectoryActiveIcon, name: "EMPLOYEE_DIRECTORY", component: <AppClaimManagementEmployeeDirectoryTable />, accessible: profile?.permissions?.[ROLES.CLAIM_TYPE_CONFIG]?.view },
		{ label: "Claim History", icon: clockIcon, activeIcon: activeClockIcon, name: "CLAIM_HISTORY", component: <AppAllClaimHistoryTable />, accessible: profile?.permissions?.[ROLES.CLAIM_TYPE_CONFIG]?.view },
		{ label: "Pending Claim", icon: claimApprovalIcon, activeIcon: activeClaimApprovalIcon, name: "PENDING_CLAIM", component: <AppAllPendingClaimTable />, accessible: profile?.permissions?.[ROLES.CLAIM_TYPE_CONFIG]?.view },
	], [profile?.permissions]);

	return (
		<div className="page-claim-management">
			<div className="claim-management">
				<div className="claim-management__header">
					<h1 className="claim-management__title">All Claims</h1>
				</div>

				<div className="claim-management__body">
					<AppTabs tabs={claimManagementTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageClaimManagement;
