import React, { Fragment, forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setNestedObjectValues, useFormik } from "formik";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import formatCurrencyPattern from "common/format-currency-pattern";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import INVENTORY_STOCK_ACTION from "constants/inventory-stock-action";

import AppInput from "components/app-input";
import AppButton from "components/app-button";

export const AppInventoryAdjustStockModal = (props, ref) => {
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const [isReview, setIsReview] = useState(false);
	const [visible, setVisible] = useState(false);
	const title = useMemo(() => (isReview ? "Review Changes" : "Adjust Stock"), [isReview]);

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			status: "",
			supplierName: "",
			costPrice: "",
			nextCostPrice: "",
			retailPrice: "",
			nextRetailPrice: "",
			quantity: "",
			quantityToAdd: "",
			inventoryLocationId: ""
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			nextCostPrice: yup
				.string()
				.test("nextCostPriceNotZero", "Cost price cannot be 00.00", function (value) {
					return value !== "00.00";
				})
				.required(ERRORS.REQUIRED),
			nextRetailPrice: yup
				.string()
				.test("nextRetailPriceNotZero", "Sell price cannot be 00.00", function (value) {
					return value !== "00.00";
				})
				.required(ERRORS.REQUIRED),
			supplierName: yup.string().required(ERRORS.REQUIRED),
			quantityToAdd: yup.number().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleConfirm(values);
		}
	});

	const subText = useMemo(() => (isReview ? "After clicking Confirm, the Prices and Quantity will be updated according to these changes." : `Current Quantity ${formik.values.quantity}`), [formik, isReview]);

	//prettier-ignore
	const onHandleShow = useCallback((data) => {
		formik.setValues({ ...data, quantityToAdd: 0 });

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setIsReview(false);

		setVisible(false);

		formik.resetForm();
	}, [formik]);

	const onHandleBack = useCallback(() => {
		if (!isReview) {
			onHandleDismiss();
		}

		setIsReview(false);
	}, [isReview, onHandleDismiss]);

	const onHandleNext = useCallback(async () => {
		const fieldErrors = await formik.validateForm();

		if (Object.keys(fieldErrors).length) {
			formik.setTouched(setNestedObjectValues(fieldErrors, true));
		} else {
			setIsReview(true);
		}
	}, [formik]);

	const ReviewChangesComponent = useCallback(() => {
		return (
			<div className="inventory-adjust-stock-modal__table-container">
				<table className="inventory-adjust-stock-modal__table">
					<thead>
						<tr>
							<th></th>

							<th>Before</th>

							<th>After</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td>Quantity</td>

							<td>{formik.values.quantity}</td>

							<td>{formik.values.quantity + formik.values.quantityToAdd}</td>
						</tr>

						<tr>
							<td>Cost Price (MYR)</td>

							<td>{formik.values.costPrice}</td>

							<td>{formik.values.nextCostPrice}</td>
						</tr>

						<tr>
							<td>Sell Price (MYR)</td>

							<td>{formik.values.retailPrice}</td>

							<td>{formik.values.nextRetailPrice}</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}, [formik.values]);

	//prettier-ignore
	const onHandleConfirm = useCallback(async (values) => {
		let response = null;

		try {
			let payload = {
				inventoryId: values.id,
				inventoryLocationId: values.inventoryLocationId,
				supplierName: values.supplierName,
				quantity: values.quantityToAdd,
				costPrice: values.nextCostPrice,
				retailPrice: values.nextRetailPrice,
				status: values.status,
				action: INVENTORY_STOCK_ACTION.ADJUST_STOCK
			};

			response = await api.post.inventory.updateStock(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			onHandleDismiss();

			props.onHandleGetDetails(formik.values.id);

			dispatch(promptLayoutAlertMessage({ message: "Stock count was updated successfully!" }));
		}
	}, [formik, onHandleDismiss, props, dispatch]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow
	}));

	return (
		<Modal classes={{ root: "app-inventory-adjust-stock-modal" }} open={visible}>
			<div className="inventory-adjust-stock-modal">
				<h1 className="inventory-adjust-stock-modal__title">{title}</h1>

				<div className="inventory-adjust-stock-modal__text">{subText}</div>

				<form className="inventory-adjust-stock-modal__form" onSubmit={formik.handleSubmit}>
					{!isReview && (
						<Fragment>
							<div className="inventory-adjust-stock-modal__row">
								<AppInput type="text" required disabled={restricted} name="supplierName" label="Supplier" placeholder="Enter Supplier Name" value={formik.values.supplierName} error={formik.errors.supplierName} touched={formik.touched.supplierName} onChange={formik.handleChange} />

								<AppInput type="number" required disabled={restricted} name="quantityToAdd" label="Quantity to Add" placeholder="Enter Quantity to Add" value={formik.values.quantityToAdd} error={formik.errors.quantityToAdd} touched={formik.touched.quantityToAdd} onChange={formik.handleChange} />
							</div>

							<div className="inventory-adjust-stock-modal__row">
								<AppInput type="text" required disabled={restricted} name="nextCostPrice" label="Cost Price (MYR)" placeholder="Enter Cost Price" value={formik.values.nextCostPrice} error={formik.errors.nextCostPrice} touched={formik.touched.nextCostPrice} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

								<AppInput type="text" required disabled={restricted} name="nextRetailPrice" label="Sell Price (MYR)" placeholder="Enter Sell Price" value={formik.values.nextRetailPrice} error={formik.errors.nextRetailPrice} touched={formik.touched.nextRetailPrice} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
							</div>
						</Fragment>
					)}

					{isReview && <ReviewChangesComponent />}

					<div className="inventory-adjust-stock-modal__button-container">
						<AppButton outline type="button" label="Back" onClick={onHandleBack} />

						{!isReview && <AppButton type="button" label="Next" disabled={restricted} onClick={onHandleNext} />}

						{isReview && <AppButton type="submit" label="Confirm" disabled={formik.isSubmitting || restricted} />}
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInventoryAdjustStockModal));

AppInventoryAdjustStockModal.propTypes = {
	onHandleGetDetails: PropTypes.func
};
