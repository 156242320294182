import React, { Fragment, useCallback, useMemo, useRef } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

import validateFileSize from "common/validate-file-size";

import PAGE from "constants/page";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppInputTime from "components/app-input-time";
import AppRadioInput from "components/app-radio-input";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";
import AppWorkOrderHoldOnModal from "components/pages/work-order/app-work-order-hold-on-modal";
import AppSiteDetailsTeamMembersTable from "components/pages/work-order/app-site-details-team-members-table";

import trashIcon from "assets/images/trash-icon.png";
import addIcon from "assets/images/blue-add-icon.svg";

const AppWorkOrderSiteDetails = (props) => {
	const { id } = useParams();
	const holdOnModal = useRef();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const submitLabel = useMemo(() => (isCreate ? "Next" : "Update"), [isCreate]);
	const signatureTypes = useMemo(() => ({ preparedBy: "preparedBy", completedBy: "completedBy", verifiedBys: "verifiedBys", acknowledgedBys: "acknowledgedBys" }), []);

	//prettier-ignore
	const contractTypeOptions = useMemo(() => [
		{ label: "Contracted", value: "CONTRACTED" },
		{ label: "Non-Contracted", value: "NON_CONTRACTED" }
	], []);

	const initialValues = useMemo(() => {
		let values = {
			city: "",
			email: "",
			state: "",
			status: "",
			members: "",
			teamPic: "",
			duration: "",
			postcode: "",
			mobileNo: "",
			siteName: "",
			assetName: "",
			createdBy: "",
			workSpace: "",
			contractId: "",
			workOrderId: "",
			buildingType: "",
			customerName: "",
			customerPIC: "",
			salesOrderId: "",
			addressLine1: "",
			addressLine2: "",
			type: "CONTRACTED",
			jobDescription: "",
			workInspectionId: "",
			issueDescription: "",
			workCompletionId: "",
			customerAssetName: "",
			completionTimeWindow: "",
			targetCompletionDate: "",
			targetCompletionTime: "",
			workInpectionCheckbox: "",
			endDateTime: null,
			scheduledTime: null,
			scheduledDate: null,
			preparedBy: [{ name: "", signature: null }],
			completedBy: [{ name: "", signature: null }],
			verifiedBys: [{ name: "", signature: null }],
			acknowledgedBys: [{ name: "", signature: null }]
		};

		if (props.values) {
			values = props.values;
		}

		return values;
	}, [props.values]);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			status: yup.string().required(ERRORS.REQUIRED),
			members: yup.string().required(ERRORS.REQUIRED),
			endDateTime: yup.date().required(ERRORS.REQUIRED),
			scheduledTime: yup.date().required(ERRORS.REQUIRED),
			customerName: yup.string().required(ERRORS.REQUIRED),
			salesOrderId: yup.string().required(ERRORS.REQUIRED),
			scheduledDate: yup.string().required(ERRORS.REQUIRED),
			jobDescription: yup.string().required(ERRORS.REQUIRED),
			issueDescription: yup.string().required(ERRORS.REQUIRED),
			workInspectionId: yup.string().required(ERRORS.REQUIRED),
			workCompletionId: yup.string().required(ERRORS.REQUIRED),
			completionTimeWindow: yup.string().required(ERRORS.REQUIRED),
			preparedBy: yup.array().of(
				yup.object().shape({
					name: yup.string().required(ERRORS.REQUIRED),
					signature: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize)
				})
			),
			completedBy: yup.array().of(
				yup.object().shape({
					name: yup.string().required(ERRORS.REQUIRED),
					signature: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize)
				})
			),
			verifiedBys: yup.array().of(
				yup.object().shape({
					name: yup.string().required(ERRORS.REQUIRED),
					signature: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize)
				})
			),
			acknowledgedBys: yup.array().of(
				yup.object().shape({
					name: yup.string().required(ERRORS.REQUIRED),
					signature: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize)
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleSubmit = (values) => {
		props.onHandleTabSubmit(values);
	};

	const completedByButtonVisible = useMemo(() => !formik.values.completedBy.some((o) => !o.signature) && formik.values.completedBy.length < 3, [formik.values.completedBy]);

	const onHandleOnHoldModal = useCallback(() => {
		holdOnModal.current.onHandleShow({ status: PAGE.CREATE });
	}, []);

	//prettier-ignore
	const onHandleAddCompletedBy = useCallback((type) => {
		const field = { name: "", signature: null };
		const fields = [...formik.values[type]];

		fields.push(field);

		formik.setFieldValue(type, fields);
	}, [formik]);

	//prettier-ignore
	const onHandleRemoveCompletedBy = useCallback((type, removeIndex) => {
		const nextFields = [...formik.values[type]].filter((_, i) => i !== removeIndex);

		formik.setFieldValue(type, nextFields);
	}, [formik]);

	return (
		<div className="app-work-order-site-details">
			<div className="work-order-site-details">
				<form className="work-order-site-details__form" onSubmit={formik.handleSubmit}>
					<div className="work-order-site-details__container">
						<p className="work-order-site-details__label">General Details</p>

						<div className="work-order-site-details__row">
							<AppInput disabled type="text" name="workOrderId" label="Work Order ID" placeholder="Work Order ID" value={formik.values.workOrderId} error={formik.errors.workOrderId} touched={formik.touched.workOrderId} onChange={formik.handleChange} />

							<AppSelectInput disabled name="status" label="Status" placeholder="Select..." options={[]} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppRadioInput required label="Type" options={contractTypeOptions} value={formik.values.type} error={formik.errors.type} touched={formik.touched.type} onChange={(v) => formik.setFieldValue("type", v)} />

							<AppSelectInput required type="text" name="workInspectionId" label="Work Inspection ID" placeholder="Enter Work Inspection ID" value={formik.values.workInspectionId} error={formik.errors.workInspectionId} touched={formik.touched.workInspectionId} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppSelectInput required type="text" name="salesOrderId" label="Sales Order ID" placeholder="Enter Sales Order ID" value={formik.values.salesOrderId} error={formik.errors.salesOrderId} touched={formik.touched.salesOrderId} onChange={formik.handleChange} />

							<AppSelectInput required type="text" name="workCompletionId" label="Work Completion ID" placeholder="Enter Work Completion ID" value={formik.values.workCompletionId} error={formik.errors.workCompletionId} touched={formik.touched.workCompletionId} onChange={formik.handleChange} />
						</div>

						<AppInput required type="text" name="jobDescription" label="Overall Job Description" placeholder="Overall Job Description" value={formik.values.jobDescription} error={formik.errors.jobDescription} touched={formik.touched.jobDescription} onChange={formik.handleChange} />

						<AppInput required multiline type="textarea" name="issueDescription" label="Issue Description" placeholder="Issue Description" value={formik.values.issueDescription} error={formik.errors.issueDescription} touched={formik.touched.issueDescription} onChange={formik.handleChange} />

						<p className="work-order-site-details__label">Customer Details</p>

						<div className="work-order-site-details__row work-order-site-details__row--checkbox">
							<div className="work-order-site-details__column work-order-site-details__column--checkbox">
								<AppSelectInput required name="customerName" label="Registered Name" placeholder="Select..." options={[]} value={formik.values.customerName} error={formik.errors.customerName} touched={formik.touched.customerName} onChange={formik.handleChange} />

								<AppCheckbox disabled={!formik.values.workInspectionId || !isCreate} name="workInpectionCheckbox" label="Same as Work Inspection Customer" value={formik.values.workInpectionCheckbox} touched={formik.touched.workInpectionCheckbox} onChange={formik.handleChange} />
							</div>

							<AppInput disabled={!formik.values.customerName || !isCreate} type="text" name="customerPIC" label="Customer PIC" placeholder="Contract PIC" value={formik.values.customerPIC} error={formik.errors.customerPIC} touched={formik.touched.customerPIC} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppInput disabled={!formik.values.customerPIC || !isCreate} type="text" name="contractId" label="Contract ID" placeholder="Contract ID" value={formik.values.contractId} error={formik.errors.contractId} touched={formik.touched.contractId} onChange={formik.handleChange} />

							<AppInput disabled={!formik.values.contractId || !isCreate} type="text" name="siteName" label="Site Name" placeholder="Site Name" value={formik.values.siteName} error={formik.errors.siteName} touched={formik.touched.siteName} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppInput disabled={!formik.values.siteName || !isCreate} type="text" name="assetName" label="Asset Name" placeholder="Asset Name" value={formik.values.assetName} error={formik.errors.assetName} touched={formik.touched.assetName} onChange={formik.handleChange} />

							<AppInput disabled={!formik.values.assetName || !isCreate} type="text" name="customerAssetName" label="Customer Asset Name" placeholder="Customer Asset Name" value={formik.values.customerAssetName} error={formik.errors.customerAssetName} touched={formik.touched.customerAssetName} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

							<AppInput disabled type="text" name="email" label="Email" placeholder="Email" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
						</div>

						<p className="work-order-site-details__label">Site Location</p>

						<div className="work-order-site-details__row">
							<AppInput disabled type="text" name="buildingType" label="Building Type" placeholder="Building Type" value={formik.values.buildingType} error={formik.errors.buildingType} touched={formik.touched.buildingType} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="workSpace" label="Work Space" placeholder="Work Space" value={formik.values.workSpace} error={formik.errors.workSpace} touched={formik.touched.workSpace} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppInput disabled type="text" name="addressLine1" label="Address Line 1" placeholder="Address Line 1" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="addressLine2" label="Address Line 2" placeholder="Address Line 2" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppInput disabled type="text" name="state" label="State" placeholder="State" value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="city" label="City" placeholder="City" value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppInput disabled type="text" name="postcode" label="Postcode" placeholder="Postcode" value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="work-order-site-details__container">
						<p className="work-order-site-details__label">Scheduling</p>

						<div className="work-order-site-details__row">
							<AppInputDate required name="scheduledDate" label="Scheduled Date" placeholder="DD/MM/YYYY" value={formik.values.scheduledDate} error={formik.errors.scheduledDate} touched={formik.touched.scheduledDate} onChange={formik.setFieldValue} />

							<AppInputDate required name="targetCompletionDate" label="Target Completion Date" placeholder="DD/MM/YYYY" value={formik.values.targetCompletionDate} error={formik.errors.targetCompletionDate} touched={formik.touched.targetCompletionDate} onChange={formik.setFieldValue} />
						</div>

						<div className="work-order-site-details__row">
							<AppInputTime required name="scheduledTime" label="Scheduled Time" placeholder="HH:MM" value={formik.values.scheduledTime} error={formik.errors.scheduledTime} touched={formik.touched.scheduledTime} onChange={formik.setFieldValue} />

							<AppInputTime required name="targetCompletionTime" label="Target Scheduled Time" placeholder="HH:MM" value={formik.values.targetCompletionTime} error={formik.errors.targetCompletionTime} touched={formik.touched.targetCompletionTime} onChange={formik.setFieldValue} />
						</div>

						<div className="work-order-site-details__row">
							<AppSelectInput name="teamPic" label="Team PIC" placeholder="Search by User ID or Name" options={[]} value={formik.values.teamPic} error={formik.errors.teamPic} touched={formik.touched.teamPic} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row work-order-site-details__row--divider">
							<AppSiteDetailsTeamMembersTable />
						</div>

						<p className="work-order-site-details__label">Duration Details</p>

						<div className="work-order-site-details__row">
							<AppInputDate disabled name="startDate" label="Start Date" placeholder="DD/MM/YYYY" value={formik.values.startDate} error={formik.errors.startDate} touched={formik.touched.startDate} onChange={formik.setFieldValue} />

							<AppInputDate disabled name="endDate" label="End Date" placeholder="DD/MM/YYYY" value={formik.values.endDate} error={formik.errors.endDate} touched={formik.touched.endDate} onChange={formik.setFieldValue} />
						</div>

						<div className="work-order-site-details__row">
							<AppInputTime disabled name="startTime" label="Start Time" placeholder="HH:MM" value={formik.values.startTime} error={formik.errors.startTime} touched={formik.touched.startTime} onChange={formik.setFieldValue} />

							<AppInputTime disabled name="endTime" label="End Time" placeholder="HH:MM" value={formik.values.endTime} error={formik.errors.endTime} touched={formik.touched.endTime} onChange={formik.setFieldValue} />
						</div>

						<div className="work-order-site-details__row work-order-site-details__row--divider">
							<AppInput disabled type="text" name="duration" label="Duration" placeholder="Duration" value={formik.values.duration} error={formik.errors.duration} touched={formik.touched.duration} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="work-order-site-details__container">
						<div className="work-order-site-details__row work-order-site-details__row--signature">
							<div className="work-order-site-details__column">
								{formik.values.preparedBy.map((o, i) => {
									const deleteButtonVisible = formik.values.preparedBy.length > 1;
									const error = formik.errors.preparedBy?.[i];
									const touched = formik.touched.preparedBy?.[i];

									return (
										<Fragment key={i}>
											<AppSelectInput required name={`preparedBy[${i}]name`} label="Completed By" placeholder="Enter Name" options={[]} value={o.name} error={error?.name} touched={touched} onChange={formik.handleChange} />

											<AppInputDragAndDrop name={`preparedBy[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />

											{deleteButtonVisible && <AppButton className="work-order-site-details__delete-signature-button" outline type="button" label="Remove Signature" icon={trashIcon} onClick={() => onHandleRemoveCompletedBy(signatureTypes.preparedBy, i)} />}
										</Fragment>
									);
								})}

								{completedByButtonVisible && <AppButton className="work-order-site-details__add-signature-button" outline type="button" label="Add Signature" icon={addIcon} onClick={() => onHandleAddCompletedBy(signatureTypes.completedBy)} />}
							</div>

							<div className="work-order-site-details__column">
								{formik.values.completedBy.map((o, i) => {
									const deleteButtonVisible = formik.values.completedBy.length > 1;
									const error = formik.errors.completedBy?.[i];
									const touched = formik.touched.completedBy?.[i];

									return (
										<Fragment key={i}>
											<AppSelectInput required name={`completedBy[${i}]name`} label="Completed By" placeholder="Enter Name" options={[]} value={o.name} error={error?.name} touched={touched} onChange={formik.handleChange} />

											<AppInputDragAndDrop name={`completedBy[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />

											{deleteButtonVisible && <AppButton className="work-order-site-details__delete-signature-button" outline type="button" label="Remove Signature" icon={trashIcon} onClick={() => onHandleRemoveCompletedBy(signatureTypes.completedBy, i)} />}
										</Fragment>
									);
								})}

								{completedByButtonVisible && <AppButton className="work-order-site-details__add-signature-button" outline type="button" label="Add Signature" icon={addIcon} onClick={() => onHandleAddCompletedBy(signatureTypes.completedBy)} />}
							</div>
						</div>

						<div className="work-order-site-details__row work-order-site-details__row--signature">
							<div className="work-order-site-details__column">
								{formik.values.acknowledgedBys.map((o, i) => {
									const deleteButtonVisible = formik.values.acknowledgedBys.length > 1;
									const error = formik.errors.acknowledgedBys?.[i];
									const touched = formik.touched.acknowledgedBys?.[i];

									return (
										<Fragment key={i}>
											<AppInput required type="text" name={`acknowledgedBys[${i}]name`} label="Acknowledged By" placeholder="Enter Name" value={o.name} error={error?.name} touched={touched} onChange={formik.handleChange} />

											<AppInputDragAndDrop name={`acknowledgedBys[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />

											{deleteButtonVisible && <AppButton className="work-order-site-details__delete-signature-button" outline type="button" label="Remove Signature" icon={trashIcon} onClick={() => onHandleRemoveCompletedBy(signatureTypes.acknowledgedBys, i)} />}
										</Fragment>
									);
								})}

								{completedByButtonVisible && <AppButton className="work-order-site-details__add-signature-button" outline type="button" label="Add Signature" icon={addIcon} onClick={() => onHandleAddCompletedBy(signatureTypes.acknowledgedBys)} />}
							</div>

							<div className="work-order-site-details__column">
								{formik.values.verifiedBys.map((o, i) => {
									const error = formik.errors.verifiedBys?.[i];
									const touched = formik.touched.verifiedBys?.[i];
									const deleteButtonVisible = formik.values.verifiedBys.length > 1;

									return (
										<Fragment key={i}>
											<AppInput required type="text" name={`verifiedBys[${i}]name`} label="Verified By" placeholder="Enter Name" value={o.name} error={error?.name} touched={touched} onChange={formik.handleChange} />

											<AppInputDragAndDrop name={`verifiedBys[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />

											{deleteButtonVisible && <AppButton className="work-order-site-details__delete-signature-button" outline type="button" label="Remove Signature" icon={trashIcon} onClick={() => onHandleRemoveCompletedBy(signatureTypes.verifiedBys, i)} />}
										</Fragment>
									);
								})}

								{completedByButtonVisible && <AppButton className="work-order-site-details__add-signature-button" outline type="button" label="Add Signature" icon={addIcon} onClick={() => onHandleAddCompletedBy(signatureTypes.verifiedBys)} />}
							</div>
						</div>
					</div>

					<div className="work-order-site-details__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleOnHoldModal} />

						<AppButton type="submit" label={submitLabel} />
					</div>
				</form>
			</div>

			<AppWorkOrderHoldOnModal ref={holdOnModal} />
		</div>
	);
};

export default AppWorkOrderSiteDetails;

AppWorkOrderSiteDetails.propTypes = {
	values: PropTypes.object,
	onHandleTabSubmit: PropTypes.func
};
