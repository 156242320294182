import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import { Modal } from "@mui/material";

import AppButton from "components/app-button";

export const AppWorkOrderImportFromCustomerModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	const onHandleConfirm = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-work-order-import-from-customer-modal" }} open={visible}>
			<div className="work-order-import-from-customer-modal">
				<h1 className="work-order-import-from-customer-modal__title">Are you sure?</h1>

				<p className="work-order-import-from-customer-modal__description">
					After clicking confirm, the Services Lines <br /> will be imported from {""}
					<span className="work-order-import-from-customer-modal__name">KLCC Cinema 5.</span>
				</p>

				<div className="work-order-import-from-customer-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="submit" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppWorkOrderImportFromCustomerModal));
