import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

import api from "services/api";

import ClassNames from "common/class-names";
import getTotalDays from "common/get-total-days";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppLeaveHistoryViewModal from "components/pages/human-resources/app-leave-history-view-modal";

import eyeIcon from "assets/images/eye-open-icon.png";
import exportIcon from "assets/images/export-icon.png";

const AppHrViewLeaveHistoryTable = () => {
	const profile = useSelector((state) => state.profile);
	const leaveHistoryRef = useRef();
	const [data, setData] = useState(convertPaginationTableData());
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const [selectedPending, setSelectedPending] = useState([]);
	const defaultStatus = useMemo(() => [STATUS.APPROVED, STATUS.REJECTED, STATUS.CANCELLED].join(","), []);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.LEAVE_MANAGEMENT], [profile]);
	const restricted = useMemo(() => !accessible?.view, [accessible]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", status: defaultStatus, sort: searchParams.get("sort") || "", tab: "LEAVE_HISTORY" });

	const validationSchema = yup.object({
		endDate: yup.date().test(function (value, { parent }) {
			if (!value) return true;

			const totalDays = getTotalDays(parent.startDate, value);

			return totalDays >= 0 && totalDays <= 90;
		})
	});

	const formik = useFormik({
		initialValues: { startDate: "", endDate: "", status: [STATUS.APPROVED, STATUS.REJECTED, STATUS.CANCELLED] },
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = { ...queryParams.current, size: 10 };

			memoSearchParams.current(params);

			response = await api.get.humanResource.leaveHistory(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const intructionClassName = useMemo(() => ClassNames({
		"filter-menu__instruction": true,
		"filter-menu__instruction--error": formik.errors.endDate,
	}), [formik.errors.endDate]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleViewLeaveHistory = useCallback((obj) => {
		leaveHistoryRef.current.onHandleShow(data.content[obj.rowIndex]);
	}, [data]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";

		onHandleGetList();
	}, [formik, onHandleGetList]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Approved", name: STATUS.APPROVED },
			{ label: "Rejected", name: STATUS.REJECTED },
			{ label: "Cancelled", name: STATUS.CANCELLED }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (values.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const onHandleSelectPending = useCallback((boolean, id) => {
		setSelectedPending((prev) => {
			let nextPending = [...prev];

			if (boolean) {
				nextPending.push(id);
			} else {
				nextPending = nextPending.filter((nextPendingId) => nextPendingId !== id);
			}

			return nextPending;
		});
	}, []);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data.content.map((item) => item.id);

		setSelectedPending(boolean ? ids : []);
	}, [data]);

	// prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");
		queryParams.current["start-date"] = dayjs(values.startDate).isValid() ? dayjs(values.startDate).format(DATE_TIME.YYYY_MM_DD) : "";
		queryParams.current["end-date"] = dayjs(values.endDate).isValid() ? dayjs(values.endDate).format(DATE_TIME.YYYY_MM_DD) : "";

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
		queryParams.current["start-date"] && formik.setFieldValue("startDate", dayjs(queryParams.current["start-date"]));
		queryParams.current["end-date"] && formik.setFieldValue("endDate", dayjs(queryParams.current["end-date"]));
	}, [formik]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.content.length > 0 && selectedPending.length === data.content.length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePendingID = data.content[rowIndex]?.id;
					const selected = selectedPending.includes(tablePendingID);

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">
										<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectPending(v, tablePendingID)} value={selected} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				},
			},
		},
		{
			name: "employeeName",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "days",
			label: "Days",
			options: {
				sort: false,
			},
		},
		{
			name: "leaveType",
			label: "Leave Type",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "submitDate",
			label: "Submit Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY),
			},
		},
		{
			name: "startDate",
			label: "Start Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY),
			},
		},
		{
			name: "endDate",
			label: "End Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY),
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />,
			},
		},
		{
			name: "view",
			label: "View",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (restricted) return null;
					
					return (
						<button type="button" className="table__view" onClick={() => onHandleViewLeaveHistory(tableMeta)}>
							<img src={eyeIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [data, onHandleSelectAll, onHandleSelectPending, onHandleViewLeaveHistory, restricted, selectedPending]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	const HeaderApprovalButton = useCallback((obj) => {
		if (!obj.selectedPending.length) return null;

		return (
			<div className="hr-view-leave-history-table__header-button">
				<AppButton outline type="button" label="Attachments" icon={exportIcon} />

				<AppButton type="button" label="Details" icon={exportIcon} />
			</div>
		);
	}, []);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.LEAVE_HISTORY);
		};
	}, [cancelRequest]);

	return (
		<div className="app-hr-view-leave-history-table">
			<div className="hr-view-leave-history-table">
				<div className="hr-view-leave-history-table__header">
					<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Name or Leave Type" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
						<div className="hr-view-leave-history-table-filter">
							<div className="hr-view-leave-history-table-filter__container">
								<div className="hr-view-leave-history-table-filter__inputs">
									<AppInputDate name="startDate" label="Submit Date" placeholder="Start Date" value={formik.values.startDate} onChange={formik.setFieldValue} />

									<AppInputDate name="endDate" placeholder="End Date" value={formik.values.endDate} onChange={formik.setFieldValue} />
								</div>

								<p className={intructionClassName}>Please select a date or a date range of up to 90 days</p>
							</div>

							<div className="hr-view-leave-history-table-filter__container">
								<p className="hr-view-leave-history-table-filter__label">Status</p>

								<div className="hr-view-leave-history-table-filter__checkbox-status">
									{menuFilterStatus.map((o) => {
										const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

										return (
											<div className="hr-view-leave-history-table-filter__checkbox" key={o.label}>
												<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</AppTableFilterHeader>

					<HeaderApprovalButton selectedPending={selectedPending} />
				</div>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			<AppLeaveHistoryViewModal ref={leaveHistoryRef} />
		</div>
	);
};

export default AppHrViewLeaveHistoryTable;
