import PageConfirmDeactivateMobileUser from "pages/deactivate-mobile-user/page-confirm-deactivate-mobile-user";
import PageDeactivateMobileUserSuccess from "pages/deactivate-mobile-user/page-deactivate-mobile-user-success";

import pathnames from "routes/pathnames";

const deactivateMobileUserRoutes = [
	{
		path: pathnames.deactivateMobileUser.deactivateAccount,
		element: <PageConfirmDeactivateMobileUser />,
		authenticated: false,
		role: null
	},
	{
		path: pathnames.deactivateMobileUser.deactivateSuccess,
		element: <PageDeactivateMobileUserSuccess />,
		authenticated: false,
		role: null
	}
];

export default deactivateMobileUserRoutes;
