import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useSelector } from "react-redux";

import ROLES from "constants/roles";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";

import closeIcon from "assets/images/close-icon.png";

const AppWorkInspectionConfirmDeleteAssetModal = (props, ref) => {
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_CONTRACT_PIC], [profile]); // TODO: TO CHANGE WHEN PERMISSION IS READY
	const restricted = useMemo(() => !accessible?.delete, [accessible]);
	const isContract = useMemo(() => props.isContract, [props.isContract]);
	const onHandleRemoveAsset = useMemo(() => props.onHandleRemoveAsset, [props.onHandleRemoveAsset]);
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState(null);

	const onHandleConfirm = useCallback(async () => {
		const value = isContract ? data.id : data.rowIndex;

		onHandleRemoveAsset(value);

		setVisible(false);
	}, [data, isContract, onHandleRemoveAsset]);

	const onHandleShow = useCallback((values) => {
		setData(values);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-work-inspection-confirm-delete-asset-modal" }} open={visible}>
			<div className="work-inspection-confirm-delete-asset-modal">
				<button type="button" className="work-inspection-confirm-delete-asset-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="work-inspection-confirm-delete-asset-modal__title">Are you sure?</h1>

				<p className="work-inspection-confirm-delete-asset-modal__description">
					After clicking confirm, the Asset <b>{data?.customerAssetName}</b> will be removed from this Work Inspection Report.
				</p>

				<div className="work-inspection-confirm-delete-asset-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="button" disabled={restricted} label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppWorkInspectionConfirmDeleteAssetModal));

AppWorkInspectionConfirmDeleteAssetModal.propTypes = {
	ref: PropTypes.object.isRequired
};
