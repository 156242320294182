import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

import DATE_TIME from "constants/date-time";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppPendingCorrectiveAttendanceViewModal from "components/pages/human-resources/app-pending-corrective-attendance-view-modal";
import AppPendingCorrectiveAttendanceRejectionModal from "components/pages/human-resources/app-pending-corrective-attendance-rejection-modal";

import eyeIcon from "assets/images/eye-open-icon.png";
import chevronIcon from "assets/images/chevron-right-icon.png";
import rejectIcon from "assets/images/pages/customer/reject-icon.svg";
import approveIcon from "assets/images/pages/customer/approve-icon.svg";

const dummyData = [
	{ id: 1, name: "Ali", date: new Date("2024-08-01"), previousClockIn: "09:00 AM", correctedClockIn: "09:15 AM", previousClockOut: "05:00 PM", correctedClockOut: "05:15 PM" },
	{ id: 2, name: "Sara", date: new Date("2024-08-02"), previousClockIn: "08:45 AM", correctedClockIn: "09:00 AM", previousClockOut: "04:45 PM", correctedClockOut: "05:00 PM" },
	{ id: 3, name: "John", date: new Date("2024-08-03"), previousClockIn: "08:30 AM", correctedClockIn: "08:45 AM", previousClockOut: "04:30 PM", correctedClockOut: "04:45 PM" }
];

const AppPendingCorrectiveAttendanceTable = () => {
	const rejectionRef = useRef();
	const pendingAttendanceRef = useRef();
	const [data, setData] = useState(dummyData);
	const [searchParams] = useSearchParams();
	const [selectedPending, setSelectedPending] = useState([]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", "submit-date": searchParams.get("submit-date") || "", sort: searchParams.get("sort") || "", tab: "PENDING_CORRECTIVE_ATTENDANCE" });

	const formik = useFormik({
		initialValues: { submitDate: "" },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;
	}, []);

	const onHandleSubmit = useCallback((values) => {
		if (values.startDate && dayjs(values.startDate).isValid()) {
			queryParams.current["submit-date"] = dayjs(values.date).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS);
		}
	}, []);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current["submit-date"] = "";
	}, [formik]);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("submit-date", queryParams.current["submit-date"]);
	}, [formik]);

	const onHandleSelectCorrectiveAttendance = useCallback((boolean, idNo) => {
		setSelectedPending((prev) => {
			let nextPending = [...prev];

			if (boolean) {
				nextPending.push(idNo);
			} else {
				nextPending = nextPending.filter((id) => id !== idNo);
			}

			return nextPending;
		});
	}, []);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data.map((item) => item.id);

		setSelectedPending(boolean ? ids : []);
	}, [data]);

	//prettier-ignore
	const onHandleViewCorrectiveAttendance = useCallback((obj) => {
		pendingAttendanceRef.current.onHandleShow(data[obj.rowIndex]);
	}, [data]);

	//prettier-ignore
	const onHandleRejectReason = useCallback((values, ids) => {
		const updatedData = data.map((item) => {
			if (ids.includes(item.id)) {
				return { ...item, rejectReason: values };
			}

			return item;
		});

		setData(updatedData);
	}, [data]);

	const onHandleRejectCorrectives = useCallback(() => {
		rejectionRef.current.onHandleShow(selectedPending);
	}, [selectedPending]);

	const onHandleRejectCorrective = useCallback((id) => {
		rejectionRef.current.onHandleShow(id);
	}, []);

	//prettier-ignore
	const onHandleBack = useCallback((id) => {
		const backData = data.filter((item) => id.includes(item.id));

		pendingAttendanceRef.current.onHandleShow(backData[0]);
	}, [data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.length > 0 && selectedPending.length === data.length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePendingID = data[rowIndex]?.id;
					const selected = selectedPending.includes(tablePendingID);

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">
										<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectCorrectiveAttendance(v, tablePendingID)} value={selected} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				}
			}
		},
		{
			name: "id",
			label: "Employee ID",
			options: {
				sort: false
			}
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "date",
			label: "Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			}
		},
		{
			name: "previousClockIn",
			label: "Previous Clock In",
			options: {
				sort: false
			}
		},
		{
			name: "symbol",
			options: {
				sort: false,
				customHeadRender: () => {
					return (
						<TableCell key="table-key-clock-in" className="app-table__cell app-table__cell--header">
							<AppIcon src={chevronIcon} />
						</TableCell>
					);
				},
				customBodyRender: () => <AppIcon src={chevronIcon} />
			}
		},
		{
			name: "correctedClockIn",
			label: "Corrected Clock In",
			options: {
				sort: false
			}
		},
		{
			name: "previousClockOut",
			label: "Previous Clock Out",
			options: {
				sort: false
			}
		},
		{
			name: "symbol",
			options: {
				sort: false,
				customHeadRender: () => {
					return (
						<TableCell key="table-key-clock-out" className="app-table__cell app-table__cell--header">
							<AppIcon src={chevronIcon} />
						</TableCell>
					);
				},
				customBodyRender: () => <AppIcon src={chevronIcon} />
			}
		},
		{
			name: "correctedClockOut",
			label: "Corrected Clock Out",
			options: {
				sort: false
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__view" onClick={() => onHandleViewCorrectiveAttendance(tableMeta)}>
							<img src={eyeIcon} alt="view-icon" />
						</button>
					);
				}
			}
		}
	], [data, onHandleViewCorrectiveAttendance, onHandleSelectAll, onHandleSelectCorrectiveAttendance, selectedPending]);

	const HeaderApprovalButton = useCallback((obj) => {
		if (!obj.selectedPending.length) return null;

		return (
			<div className="pending-corrective-attendance-table__header-button">
				<div className="pending-corrective-attendance-table__reject-button">
					<AppButton outline type="button" label="Reject" icon={rejectIcon} onClick={obj.onHandleRejectCorrectives} />
				</div>

				<div className="pending-corrective-attendance-table__approve-button">
					<AppButton type="button" label="Approve" icon={approveIcon} />
				</div>
			</div>
		);
	}, []);

	useEffect(() => {
		setData(dummyData);
	}, []);

	return (
		<div className="app-pending-corrective-attendance-table">
			<div className="pending-corrective-attendance-table">
				<div className="pending-corrective-attendance-table__header">
					<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Name or Employee ID" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
						<div className="pending-corrective-attendance-table-filter">
							<AppInputDate name="submitDate" label="Date" placeholder="DD/MM/YYYY" value={formik.values.submitDate} onChange={formik.setFieldValue} />
						</div>
					</AppTableFilterHeader>

					<HeaderApprovalButton selectedPending={selectedPending} onHandleRejectCorrectives={onHandleRejectCorrectives} />
				</div>

				<AppTable data={data} columns={tableColumns} />
			</div>

			<AppPendingCorrectiveAttendanceViewModal ref={pendingAttendanceRef} onHandleRejectCorrective={onHandleRejectCorrective} />

			<AppPendingCorrectiveAttendanceRejectionModal ref={rejectionRef} onConfirm={onHandleRejectReason} onHandleBack={onHandleBack} />
		</div>
	);
};

export default AppPendingCorrectiveAttendanceTable;
