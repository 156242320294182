import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import AppButton from "components/app-button";

const AppRemunerationClaimTypeDeleteModal = (props, ref) => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const [claimPackage, setClaimPackage] = useState(null);
	const [objectIndex, setObjectIndex] = useState([]);

	const onHandleShow = useCallback((claimPackageData, o, i) => {
		setClaimPackage(claimPackageData);

		setObjectIndex({ o, i });

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleConfirm = useCallback(async () => {
		let response = null;

		const params = {
			"claim-package-id": claimPackage
		};

		try {
			await api.post.humanResource.deleteClaimPackage(params);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setVisible(false);

			props.onHandleConfirm(objectIndex.o, objectIndex.i);

			dispatch(promptLayoutAlertMessage({ message: "Claim package was removed successfully!" }));
		}
	}, [claimPackage, props, objectIndex, dispatch]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-remuneration-claim-type-delete-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="confirmation-modal" aria-describedby="confirmation-modal">
			<div className="remuneration-claim-type-delete-modal">
				<p className="remuneration-claim-type-delete-modal__title">Are you sure?</p>

				<div className="remuneration-claim-type-delete-modal__body">
					<p className="remuneration-claim-type-delete-modal__description">After clicking confirm,</p>

					<p className="remuneration-claim-type-delete-modal__description">this Claim Benefit will be removed from the Remuneration Package.</p>

					<div className="remuneration-claim-type-delete-modal__button-container">
						<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />

						<AppButton type="button" label="Confirm" onClick={onHandleConfirm} />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppRemunerationClaimTypeDeleteModal));
