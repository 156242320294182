import React, { useMemo } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import AppButton from "components/app-button";

import logo from "assets/images/logo.png";
import sharedGoalsAmico from "assets/images/shared-goals-amico.png";

const PageResetPasswordSuccess = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const isWebsitePlaform = useMemo(() => searchParams.get("platform") === "cma", [searchParams]);

	const onHandleBack = () => {
		navigate(pathnames.login.login);
	};

	return (
		<div className="page-reset-password-success">
			<main className="reset-password-success">
				<div className="reset-password-success__container">
					<div className="reset-password-success__logo">
						<img src={logo} alt="logo" />
					</div>

					<h1 className="reset-password-success__title">Success! Your password has been reset.</h1>

					<p className="reset-password-success__description">Please log in using your new password to access the back-office portal.</p>

					<div className="reset-password-success__hero">
						<img src={sharedGoalsAmico} alt="hero" />
					</div>

					{isWebsitePlaform && (
						<div className="reset-password-success__button-container">
							<AppButton type="button" label="Back to Log In" onClick={onHandleBack} />
						</div>
					)}
				</div>
			</main>
		</div>
	);
};

export default PageResetPasswordSuccess;
