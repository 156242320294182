import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import { useFormik } from "formik";
import { Modal } from "@mui/material";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";

import closeIcon from "assets/images/close-icon.png";

export const AppInventoryHistoryMovementViewModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	const formik = useFormik({
		initialValues: {
			remark: ""
		}
	});

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({ remark: obj?.remark });

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-inventory-history-movement-view-modal" }} open={visible}>
			<div className="inventory-history-movement-view-modal">
				<button type="button" className="inventory-history-movement-view-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="inventory-history-movement-view-modal__title">View Remark</h1>

				<AppInput disabled type="textarea" name="remark" label="Remarks" multiline maxLength={255} value={formik.values.remark} touched={formik.touched.remark} error={formik.errors.remark} onChange={formik.handleChange} />
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInventoryHistoryMovementViewModal));
