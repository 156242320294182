import api from "services/api";

import serveRequestErrors from "common/serve-request-errors";

const getInventoryLocationListing = async (payload) => {
	let response = null;
	let errorMessage = null;

	try {
		const params = { size: 10, keyword: payload?.keyword || "" };
		const res = await api.get.inventory.optionsConfiguration(params);
		const content = res.map((item) => ({ value: item.value, label: item.label }));

		response = { ...res, content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getInventoryLocationListing;
