import React, { useCallback, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import validateFileSize from "common/validate-file-size";

import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";

import trashIcon from "assets/images/trash-icon.png";
import addIcon from "assets/images/blue-add-icon.svg";
import chevronUpIcon from "assets/images/chevron-up-icon.png";
import chevronDownIcon from "assets/images/chevron-down-grey-icon.png";

const AppWorkOrderWorkDetails = () => {
	const navigate = useNavigate();
	const [collapsedIndices, setCollapsedIndices] = useState(new Set());
	const recordTypes = useMemo(() => ({ records: "records" }), []);

	//prettier-ignore
	const defaultFinding = useMemo(() => ({
		name: "",
		workScope: "",
		description: "",
		sparePartName: "",
		record: null
	}), []);

	//prettier-ignore
	const initialValues = useMemo(() => ({
		caption: "",
		workScope: "",
		description: "",
		sparePartName: "",
		records: []
	}), []);

	const onHandleSubmit = () => {};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			records: yup.array().of(
				yup.object({
					name: yup.string().required(ERRORS.REQUIRED),
					sparePartName: yup.string().required(ERRORS.REQUIRED),
					workScope: yup.string().required(ERRORS.REQUIRED),
					record: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize)
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleBack = useCallback(() => {
		navigate(pathnames.workInspectionListing.workInspectionListing);
	}, [navigate]);

	//prettier-ignore
	const onHandleRemoveConfirmBy = useCallback((type, removeIndex) => {
		const nextFields = [...formik.values[type]].filter((_, i) => i !== removeIndex);
		
		formik.setFieldValue(type, nextFields);
	}, [formik]);

	//prettier-ignore
	const onHandleAddConfirmBy = useCallback((type) => {
		formik.setFieldValue(type, [...formik.values[type], { ...defaultFinding }]);
	}, [defaultFinding, formik]);

	const onHandleToggleCollapse = (index) => {
		setCollapsedIndices((prev) => {
			const nextCollapsedIndices = new Set(prev);

			if (nextCollapsedIndices.has(index)) {
				nextCollapsedIndices.delete(index);
			} else {
				nextCollapsedIndices.add(index);
			}
			return nextCollapsedIndices;
		});
	};

	return (
		<div className="app-work-order-work-details">
			<div className="work-order-work-details">
				<form className="work-order-work-details__form" onSubmit={formik.handleSubmit}>
					<div className="work-order-work-details__container">
						<div className="work-order-work-details__header">
							<p className="work-order-work-details__label">Work Details</p>

							<AppButton className="work-order-work-details__add-record-button" outline type="button" label="Add Work" icon={addIcon} onClick={() => onHandleAddConfirmBy(recordTypes.records)} />
						</div>

						{formik.values.records.map((o, i) => {
							const error = formik.errors.records?.[i];
							const touched = formik.touched.records?.[i];

							return (
								<div key={i} className="work-order-work-details__wrapper">
									<div className="work-order-work-details__category">
										<p className="work-order-work-details__header-label">Record {i + 1}</p>

										<div className="work-order-work-details__header-button">
											<button type="button" className="work-order-work-details__delete-record-button" onClick={() => onHandleRemoveConfirmBy(recordTypes.records, i)}>
												<img src={trashIcon} alt="trash-icon" />
											</button>

											<button type="button" className="work-order-work-details__toggle-button" onClick={() => onHandleToggleCollapse(i)}>
												<img src={collapsedIndices.has(i) ? chevronDownIcon : chevronUpIcon} alt="toggle-button" />
											</button>
										</div>
									</div>

									{collapsedIndices.has(i) && (
										<div className="work-order-work-details__inner-wrapper">
											<p className="work-order-work-details__label">Details</p>

											<div className="work-order-work-details__row">
												<AppSelectInput required type="text" name={`records[${i}].sparePartName`} label="Spare Part Name" placeholder="Enter Spare Part Name" value={o.sparePartName} error={error?.sparePartName} touched={touched?.sparePartName} onChange={formik.handleChange} />

												<AppInput required type="text" name={`records[${i}].workScope`} label="Work Scope" placeholder="Enter Work Scope" value={o.workScope} error={error?.workScope} touched={touched?.workScope} onChange={formik.handleChange} />
											</div>

											<div className="work-order-work-details__row">
												<AppInput required multiline type="textarea" name={`records[${i}].workDescription`} label="Work Description" placeholder="Enter Work Description" value={o.description} touched={touched?.description} onChange={formik.handleChange} />

												<AppInput required multiline type="textarea" name={`records[${i}].caption`} label="Caption" placeholder="Enter caption" value={o.caption} touched={touched?.caption} onChange={formik.handleChange} />
											</div>

											<p className="work-order-work-details__label">Attachments</p>

											<p className="work-order-work-details__description">Please ensure that all images are in either jpg or png format with file size not exceeding 5MB. (Max 4 images only)</p>

											<div className="work-order-work-details__attachments">
												<div className="work-order-work-details__column">
													<p className="work-order-work-details__description work-order-work-details__description--attachment">Before Service</p>

													<AppInputDragAndDrop name={`records[${i}].record`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error?.record && touched?.record} />

													<AppInputDragAndDrop name={`records[${i}].record`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error?.record && touched?.record} />
												</div>

												<div className="work-order-work-details__column">
													<p className="work-order-work-details__description work-order-work-details__description--attachment">After Service</p>

													<AppInputDragAndDrop name={`records[${i}].record`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error?.record && touched?.record} />

													<AppInputDragAndDrop name={`records[${i}].record`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error?.record && touched?.record} />
												</div>
											</div>
										</div>
									)}
								</div>
							);
						})}
					</div>

					<div className="work-order-work-details__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton type="submit" label="Create" />
					</div>
				</form>
			</div>
		</div>
	);
};

export default AppWorkOrderWorkDetails;
