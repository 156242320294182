import React, { memo } from "react";

import { useFormik } from "formik";
import PropTypes from "prop-types";

import AppButton from "components/app-button";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";

const AppInventoryHistoryIssueComponent = (props) => {
	const formik = useFormik({
		initialValues: {
			signatureAttachment: null
		},
		onSubmit: (values) => {
			props.onHandleSubmit(values);
		}
	});

	return (
		<div className="app-inventory-history-issue-component">
			<div className="inventory-history-issue-component">
				<form className="inventory-history-issue-component__form" onSubmit={formik.handleSubmit}>
					<p className="inventory-history-issue-component__text">
						After clicking confirm, the item request for {props.data?.inventoryItem} will be set to <span>Issued.</span>
					</p>

					<p className="inventory-history-issue-component__title">Signature Attachment</p>

					<AppInputDragAndDrop name="signatureAttachment" accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={formik.errors.signatureAttachment} touched={formik.touched.signatureAttachment} />

					<div className="inventory-history-issue-component__button-container">
						<AppButton outline type="button" label="Back" onClick={props.onHandleDismiss} />

						<AppButton type="submit" label="Confirm" />
					</div>
				</form>
			</div>
		</div>
	);
};

export default memo(AppInventoryHistoryIssueComponent);

AppInventoryHistoryIssueComponent.propTypes = {
	data: PropTypes.object,
	onHandleDismiss: PropTypes.func,
	onHandleSubmit: PropTypes.func
};
