import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import STATUS from "constants/status";

import AppButton from "components/app-button";

const AppMobileAppConfirmDeactivateAccountModal = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const onHandleGetDetails = useMemo(() => props.onHandleGetDetails, [props.onHandleGetDetails]);

	const formik = useFormik({
		initialValues: { id: id, userStatus: STATUS.INACTIVE },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;
		let params = { id: values.id, "user-status": values.userStatus };

		try {
			await api.post.mobile.changeUserStatus(params);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: `Mobile App User was deactivated successfully!` }));

			onHandleGetDetails(id);

			onHandleDismiss();
		}
	}, [dispatch, formik, id, onHandleDismiss, onHandleGetDetails]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-mobile-app-confirm-deactivate-account-modal" }} open={visible}>
			<div className="mobile-app-confirm-deactivate-account-modal">
				<form className="mobile-app-confirm-deactivate-account-modal__form" onSubmit={formik.handleSubmit}>
					<h1 className="mobile-app-confirm-deactivate-account-modal__title">Are you sure?</h1>

					<p className="mobile-app-confirm-deactivate-account-modal__description">
						After clicking confirm, this Mobile App Account will be <span>deactivated</span>
					</p>

					<p className="mobile-app-confirm-deactivate-account-modal__description mobile-app-confirm-deactivate-account-modal__description--final-line">The user will no longer be able to access this account.</p>

					<div className="mobile-app-confirm-deactivate-account-modal__button-container">
						<AppButton disabled={formik.isSubmitting} outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton disabled={formik.isSubmitting} type="submit" label="Confirm" />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppMobileAppConfirmDeactivateAccountModal));

AppMobileAppConfirmDeactivateAccountModal.propTypes = {
	onHandleGetDetails: PropTypes.func
};
