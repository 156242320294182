import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";

import closeIcon from "assets/images/close-icon.png";

export const AppInventoryRetireModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState({});
	const dispatch = useDispatch();

	//prettier-ignore
	const onHandleSubmit = useCallback(async () => {
		let response = null;

		const params = {
			"inventory-id": data.id
		};

		try {
			await api.post.inventory.retireInventory(params);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}
		
		if (response) {
			setVisible(false);

			props.onHandleGetList();

			dispatch(promptLayoutAlertMessage({ message: "Status was changed successfully!" }));
		}
	}, [data, dispatch, props]);

	//prettier-ignore
	const onHandleShow = useCallback((id, referenceNo) => {
		setData({ id, referenceNo });		
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-inventory-retire-modal" }} open={visible}>
			<div className="inventory-retire-modal">
				<button type="button" className="inventory-retire-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="inventory-retire-modal__title">Confirmation to Retire</h1>

				<p className="inventory-retire-modal__description">
					After clicking confirm, the status of <span className="inventory-retire-modal__inventory-id">{data.referenceNo}</span> will be changed to <span className="inventory-retire-modal__status">Retired</span>.
				</p>

				<div className="inventory-retire-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="button" label="Confirm" onClick={onHandleSubmit} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInventoryRetireModal));

AppInventoryRetireModal.propTypes = {
	ref: PropTypes.object
};
