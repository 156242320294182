import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import serveRequestErrors from "common/serve-request-errors";

const getInventoryFromListing = async (payload, id) => {
	let response = null;
	let errorMessage = null;

	const params = sanitizeObject({
		"inventory-id": id
	});

	try {
		const res = await api.get.inventory.optionsLocation(params);

		const content = res.map((item) => ({ value: item.value, label: item.label }));

		response = { ...res, content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getInventoryFromListing;
