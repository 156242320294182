import dayjs from "dayjs";

const combineDateTime = (date, time) => {
	const datePortion = dayjs(date).utcOffset(0).format('YYYY-MM-DDTHH:mm:ss.sss[Z]').split("T")[0];
	const timePortion = dayjs(time).utcOffset(0).format('YYYY-MM-DDTHH:mm:ss.sss[Z]').split("T")[1];

	const newTime = `${datePortion}T${timePortion}`;

	return newTime;
};

export default combineDateTime;
