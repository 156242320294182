import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

const AppSalesOrderInvoices = () => {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "" });

	const onHandleAddInvoices = useCallback(() => {
		navigate(pathnames.sales.salesOrderInvoicesCreateEdit);
	}, [navigate]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "number",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "invoiceId",
			label: "Invoice ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "title",
			label: "title",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "amount",
			label: "Amount",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "createdBy",
			label: "Created By",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
	], []);

	useEffect(() => {
		setData([]);
	}, []);

	return (
		<div className="app-sales-order-invoices">
			<div className="sales-order-invoices">
				<AppTableFilterHeader searchPlaceholder="Search by Invoice ID or Title" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} onHandleAdd={onHandleAddInvoices} />

				<AppTable data={data} columns={tableColumns} />
			</div>
		</div>
	);
};

export default AppSalesOrderInvoices;
