import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import { Modal } from "@mui/material";

import AppButton from "components/app-button";

export const AppWorkInspectionHoldOnModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	//prettier-ignore
	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-work-inspection-hold-on-modal" }} open={visible}>
			<div className="work-inspection-hold-on-modal">
				<h1 className="work-inspection-hold-on-modal__title">Hold On!</h1>

				<p className="work-inspection-hold-on-modal__description">Are you sure you would like to leave this page?</p>

				<p className="work-inspection-hold-on-modal__description">By leaving, the changes that you made will not be saved.</p>

				<div className="work-inspection-hold-on-modal__button-container">
					<AppButton outline type="button" label="Leave Page" onClick={props.onConfirm} />

					<AppButton type="button" label="Stay in Page" onClick={onHandleDismiss} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppWorkInspectionHoldOnModal));
