import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { formatCurrency } from "common/format-currency-pattern";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const PageSalesOrders = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", status: searchParams.get("status"), createdBy: searchParams.get("createdby") });
	const [data, setData] = useState(convertPaginationTableData());
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const defaultStatus = useMemo(() => [STATUS.DRAFT, STATUS.DRAFT_WITH_EXCEPTION, STATUS.COMPLETED, STATUS.COMPLETED_WITH_EXCEPTION, STATUS.DROP_OFF].join(","), []);

	const formik = useFormik({
		initialValues: { keyword: "", createdBy: "", status: defaultStatus.split(",") },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status ? queryParams.current.status.split(",") : []);
	}, [formik]);

	
	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.salesOrders.listings(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();
		
		queryParams.current.status = defaultStatus;

		onHandleGetList()
	}, [formik, onHandleGetList, defaultStatus]);

	//prettier-ignore
	const onHandleEditSalesOrder = useCallback((tableMeta) => {
		const id = data.content[tableMeta.rowIndex]?.id;

		navigate(pathnames.sales.salesOrderCreateEdit + id);
	}, [navigate, data]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");
		queryParams.current.createdBy = values.createdBy;
		queryParams.current.keyword = values.keyword;

		onHandleGetList();
	}, [onHandleGetList]);

	// prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Draft", name: STATUS.DRAFT },
			{ label: "Completed", name: STATUS.COMPLETED },
			{ label: "Drop Off", name: STATUS.DROP_OFF },
			{ label: "Draft with exception", name: STATUS.DRAFT_WITH_EXCEPTION },
			{ label: "Complete with Exception", name: STATUS.COMPLETED_WITH_EXCEPTION },
		];

		return data;
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false,
			},
		},
		{
			name: "referenceNo",
			label: "Sales Order ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "customerName",
			label: "Customer Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "location",
			label: "Location",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					return (
						<span className="table__address">{data.content[tableMeta.rowIndex]?.addressLine1} {data.content[tableMeta.rowIndex]?.addressLine2}</span>
					);
				},
			},
		},
		{
			name: "totalAmount",
			label: "Total (MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: "table__price" }),
                setCellProps: () => ({ className: "table__price" }),
                customBodyRender: (value, tableMeta) => {
                    return formatCurrency(value);
                },
			},
		},
		{
			name: "staffId",
			label: "Created By",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					return (
						<span className="table__address table__address--inline">{data.content[tableMeta.rowIndex]?.staffId} {data.content[tableMeta.rowIndex]?.name}</span>
					);
				},
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />	
			},
		},
		{
			name: "action",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditSalesOrder(tableMeta)}>
							<img src={chevronIcon} alt="action-icon" />
						</button>
					);
				},
			},
		},
	], [onHandleEditSalesOrder, data.content]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };
					
					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.SALES_ORDERS.LISTINGS);
		};
	}, [cancelRequest]);

	return (
		<div className="page-sales-orders">
			<div className="sales-orders">
				<h1 className="sales-orders__title">Sales Order</h1>

				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Sales Order ID or Customer Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="sales-orders-filter">
						<div className="sales-orders-filter__container">
							<div className="sales-orders-filter__row">
								<AppInput type="text" label="Created by" name="createdBy" placeholder="Enter Name" value={formik.values.createdBy} onChange={formik.handleChange} />
							</div>

							<p className="sales-orders-filter__label">Status</p>

							<div className="sales-orders-filter__grip">
								{menuFilterStatus.map((o) => {
									const isActive = (formik.values.status || []).findIndex((i) => i === o.name) > -1;

									return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
								})}
							</div>
						</div>
					</div>
				</AppTableFilterHeader>

				<div className="sales-orders__body">
					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
				</div>
			</div>
		</div>
	);
};

export default PageSalesOrders;
