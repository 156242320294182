import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import { Modal } from "@mui/material";

import AppButton from "components/app-button";

export const AppWorkOrderInventoryRemoveModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	const onHandleConfirm = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-work-order-inventory-remove-modal" }} open={visible}>
			<div className="work-order-inventory-remove-modal">
				<h1 className="work-order-inventory-remove-modal__title">Are you sure?</h1>

				<p className="work-order-inventory-remove-modal__description">
					After clicking confirm, the spare part INV-001 <br /> <span className="work-order-inventory-remove-modal__name">'PAN X-PREM AC'</span> <br /> will be removed from the Spare Part Listing.
				</p>

				<div className="work-order-inventory-remove-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="submit" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppWorkOrderInventoryRemoveModal));
