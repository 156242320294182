import api from "services/api";

import serveRequestErrors from "common/serve-request-errors";

const getPaymentCurrencyListing = async () => {
	let response = null;
	let errorMessage = null;

	try {
		const params = { size: 25, page: 0 };
		const res = await api.get.general.paymentCurrency(params);
		const content = res.content.map((o) => ({ label: `${o.name} (${o.code})`, value: o.code }));

		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getPaymentCurrencyListing;
