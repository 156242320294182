import React, { memo, useMemo } from "react";

import PropTypes from "prop-types";

import classNames from "common/class-names";

import STATUS from "constants/status";

import AppButton from "components/app-button";

const AppInventoryHistoryApproveRejectComponent = (props) => {
	const isApproved = useMemo(() => props.status === STATUS.APPROVED, [props]);
	const statusLabel = useMemo(() => (isApproved ? "Approved." : "Rejected."), [isApproved]);

	const statusLabelClassNames = useMemo(() => {
		return classNames({
			"inventory-history-approve-reject-component__text": true,
			"inventory-history-approve-reject-component__text--approved": props.status === STATUS.APPROVED,
			"inventory-history-approve-reject-component__text--rejected": props.status === STATUS.REJECTED
		});
	}, [props]);

	return (
		<div className="app-inventory-history-approve-reject-component">
			<p className="inventory-history-approve-reject-component__text">
				After clicking confirm, the request will be <span className={statusLabelClassNames}>{statusLabel}</span>
			</p>

			<div className="inventory-history-approve-reject-component__button-container">
				<AppButton outline type="button" label="Back" onClick={props.onHandleDismiss} />

				<AppButton type="button" label="Confirm" onClick={props.onHandleConfirm} />
			</div>
		</div>
	);
};

export default memo(AppInventoryHistoryApproveRejectComponent);

AppInventoryHistoryApproveRejectComponent.propTypes = {
	status: PropTypes.string,
	onHandleDismiss: PropTypes.func,
	onHandleConfirm: PropTypes.func
};
