import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import STATUS from "constants/status";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";

import closeIcon from "assets/images/close-icon.png";

const AppViewPendingLeaveModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const dispatch = useDispatch();

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			name: "",
			days: "",
			status: "",
			endDate: "",
			timeOff: "",
			startDate: "",
			leaveType: "",
			submitDate: "",
			description: "",
			balanceDays: "",
			file: []
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({
			id: obj?.id,
			name: obj?.name,
			days: obj?.days,
			status: obj?.status,
			timeOff: obj?.timeOff,
			leaveType: obj?.leaveType,
			startDate: dayjs(obj?.date),
			submitDate: dayjs(obj?.date),
			endDate: dayjs(obj?.endDate),
			description: obj?.description,
			balanceDays: obj?.balanceDays,
		});

		setVisible(true);
	}, [formik]);

	const isPending = useMemo(() => formik.values.status === STATUS.PENDING || formik.values.status === STATUS.APPROVED_BY_SBU, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	const onHandleReject = useCallback(() => {
		setVisible(false);

		props.onHandleRejectLeave(formik.values.id);
	}, [formik, props]);

	const onHandleApproval = useCallback(async () => {
		let response = null;

		const id = [formik.values.id];

		try {
			await api.post.humanResource.approveEmployeeLeave({ "leave-id": id });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Leave was approved successfully" }));

			props.onHandleGetList();
		}
	}, [formik, dispatch, props]);

	const onHandleSubmit = useCallback(async () => {
		setVisible(false);

		onHandleApproval();
	}, [onHandleApproval]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	const FooterDetailsButton = useCallback(() => {
		return (
			<div className="view-pending-leave-modal__footer-button">
				<div className="view-pending-leave-modal__reject-button">
					<AppButton outline type="button" label="Reject" onClick={onHandleReject} />
				</div>

				<div className="view-pending-leave-modal__approve-button">
					<AppButton type="submit" label="Approve" />
				</div>
			</div>
		);
	}, [onHandleReject]);

	return (
		<Modal classes={{ root: "app-view-pending-leave-modal" }} open={visible}>
			<div className="view-pending-leave-modal">
				<button type="button" className="view-pending-leave-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="view-pending-leave-modal__title">Leave Details</h1>

				<div className="view-pending-leave-modal__container">
					<form className="view-pending-leave-modal__form" onSubmit={formik.handleSubmit}>
						<div className="view-pending-leave-modal__row">
							<AppInput disabled type="text" name="name" label="Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="id" label="Employee ID" value={formik.values.id} error={formik.errors.id} touched={formik.touched.id} onChange={formik.handleChange} />
						</div>

						<div className="view-pending-leave-modal__row">
							<AppInput disabled type="text" name="status" label="Status" value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="leaveType" label="Leave Type" value={formik.values.leaveType} error={formik.errors.leaveType} touched={formik.touched.leaveType} onChange={formik.handleChange} />
						</div>

						<div className="view-pending-leave-modal__row">
							<AppInput disabled type="text" name="timeOff" label="Time Off" value={formik.values.timeOff} error={formik.errors.timeOff} touched={formik.touched.timeOff} onChange={formik.handleChange} />

							<AppInputDate disabled type="text" name="startDate" label="Start Date" value={formik.values.startDate} error={formik.errors.startDate} touched={formik.touched.startDate} onChange={formik.handleChange} />
						</div>

						<div className="view-pending-leave-modal__row">
							<AppInputDate disabled type="text" name="endDate" label="End Date" value={formik.values.endDate} error={formik.errors.endDate} touched={formik.touched.endDate} onChange={formik.handleChange} />

							<AppInputDate disabled type="text" name="submitDate" label="Submit Date" value={formik.values.submitDate} error={formik.errors.submitDate} touched={formik.touched.submitDate} onChange={formik.handleChange} />
						</div>

						<div className="view-pending-leave-modal__row">
							<AppInput disabled type="text" name="days" label="Day(s)" value={formik.values.days} error={formik.errors.days} touched={formik.touched.days} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="balanceDays" label="Balance Days" value={formik.values.balanceDays} error={formik.errors.balanceDays} touched={formik.touched.balanceDays} onChange={formik.handleChange} />
						</div>

						<AppInput disabled type="textarea" name="description" label="Description" multiline value={formik.values.description} touched={formik.touched.description} error={formik.errors.description} onChange={formik.handleChange} />

						<AppInputDragAndDropFiles disabled name="file" value={formik.values.file} onChange={formik.setFieldValue} />

						{isPending && <FooterDetailsButton />}
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppViewPendingLeaveModal));

AppViewPendingLeaveModal.propTypes = {
	onHandleRejectLeave: PropTypes.func,
	onHandleGetList: PropTypes.func
};
