import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { TableCell } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";

import AppIcon from "components/app-icon";
import AppStatus from "components/app-status";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppCorrectiveAttendanceViewModal from "components/pages/human-resources/app-corrective-attendance-view-modal";

import eyeIcon from "assets/images/eye-open-icon.png";
import chevronIcon from "assets/images/chevron-right-icon.png";

const dummyData = [
	{ id: 1, name: "Ali", date: new Date("2024-08-01"), previousClockIn: "09:00 AM", correctedClockIn: "09:15 AM", previousClockOut: "05:00 PM", correctedClockOut: "05:15 PM", status: STATUS.APPROVED },
	{ id: 2, name: "Sara", date: new Date("2024-08-02"), previousClockIn: "08:45 AM", correctedClockIn: "09:00 AM", previousClockOut: "04:45 PM", correctedClockOut: "05:00 PM", status: STATUS.REJECTED },
	{ id: 3, name: "John", date: new Date("2024-08-03"), previousClockIn: "08:30 AM", correctedClockIn: "08:45 AM", previousClockOut: "04:30 PM", correctedClockOut: "04:45 PM", status: STATUS.APPROVED }
];

const AppCorrectiveAttendanceHistoryTable = () => {
	const correctiveAttendanceRef = useRef();
	const [data, setData] = useState(dummyData);
	const [searchParams] = useSearchParams();
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", "submit-date": searchParams.get("submit-date") || "", sort: searchParams.get("sort") || "", tab: "CORRECTIVE_ATTENDANCE_HISTORY" });

	const formik = useFormik({
		initialValues: { submitDate: "" },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;
	}, []);

	const onHandleSubmit = useCallback((values) => {
		if (values.startDate && dayjs(values.startDate).isValid()) {
			queryParams.current["submit-date"] = dayjs(values.date).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS);
		}
	}, []);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current["submit-date"] = "";
	}, [formik]);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("submit-date", queryParams.current["submit-date"]);
	}, [formik]);

	//prettier-ignore
	const onHandleViewCorrectiveAttendance = useCallback((obj) => {
		correctiveAttendanceRef.current.onHandleShow(data[obj.rowIndex]);
	}, [data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "id",
			label: "Employee ID",
			options: {
				sort: false
			}
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "date",
			label: "Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			}
		},
		{
			name: "previousClockIn",
			label: "Previous Clock In",
			options: {
				sort: false
			}
		},
		{
			name: "symbol",
			options: {
				sort: false,
				customHeadRender: () => {
					return (
						<TableCell key="table-key-clock-in" className="app-table__cell app-table__cell--header">
							<AppIcon src={chevronIcon} />
						</TableCell>
					);
				},
				customBodyRender: () => <AppIcon src={chevronIcon} />
			}
		},
		{
			name: "correctedClockIn",
			label: "Corrected Clock In",
			options: {
				sort: false
			}
		},
		{
			name: "previousClockOut",
			label: "Previous Clock Out",
			options: {
				sort: false
			}
		},
		{
			name: "symbol",
			options: {
				sort: false,
				customHeadRender: () => {
					return (
						<TableCell key="table-key-clock-out" className="app-table__cell app-table__cell--header">
							<AppIcon src={chevronIcon} />
						</TableCell>
					);
				},
				customBodyRender: () => <AppIcon src={chevronIcon} />
			}
		},
		{
			name: "correctedClockOut",
			label: "Corrected Clock Out",
			options: {
				sort: false
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__view" onClick={() => onHandleViewCorrectiveAttendance(tableMeta)}>
							<img src={eyeIcon} alt="view-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleViewCorrectiveAttendance]);

	useEffect(() => {
		setData(dummyData);
	}, []);

	return (
		<div className="app-corrective-attendance-history-table">
			<div className="corrective-attendance-history-table">
				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Name or Employee ID" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="corrective-attendance-history-table-filter">
						<AppInputDate name="submitDate" label="Date" placeholder="DD/MM/YYYY" value={formik.values.submitDate} onChange={formik.setFieldValue} />
					</div>
				</AppTableFilterHeader>

				<AppTable data={data} columns={tableColumns} />
			</div>

			<AppCorrectiveAttendanceViewModal ref={correctiveAttendanceRef} />
		</div>
	);
};

export default AppCorrectiveAttendanceHistoryTable;
