import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import { formatCurrency } from "common/format-currency-pattern";

import STATUS from "constants/status";

import AppInput from "components/app-input";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const tempData = [
	{ paginationNumber: 1, id: "DO-001", customerName: "PAN X-PREM AC", salesOrder: "SO-123", total: 20, createdBy: "ADM0001 Ahmad Muhammad Ali", status: STATUS.COMPLETED },
	{ paginationNumber: 2, id: "DO-002", customerName: "PAN X-PREM AC", salesOrder: "SO-123", total: 20, createdBy: "ADM0001 Ahmad Muhammad Ali", status: STATUS.IN_PROGRESS },
	{ paginationNumber: 3, id: "DO-003", customerName: "PAN X-PREM AC", salesOrder: "SO-123", total: 20, createdBy: "ADM0001 Ahmad Muhammad Ali", status: STATUS.DRAFT }
];

const PageDeliveryOrders = () => {
	const navigate = useNavigate();
	const [data, setData] = useState(tempData);
	const [searchParams] = useSearchParams();
	const defaultCreatedBy = useMemo(() => [], []);
	const defaultStatus = useMemo(() => [STATUS.DRAFT, STATUS.COMPLETED, STATUS.IN_PROGRESS, STATUS.CANCELLED].join(","), []);
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", status: searchParams.get("status") || defaultStatus, createdBy: searchParams.get("createdBy") || defaultCreatedBy, sort: searchParams.get("sort") || "" });

	const formik = useFormik({ initialValues: { search: "", createdBy: "", status: [STATUS.DRAFT, STATUS.COMPLETED, STATUS.IN_PROGRESS, STATUS.CANCELLED] }, onSubmit: (values) => onHandleSubmit(values) });

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");
		queryParams.current.createdBy = values.createdBy;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
		formik.setFieldValue("createdBy", queryParams.current.createdBy);
	}, [formik]);

	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;
	}, []);

	//prettier-ignore
	const onHandleEditViewDeliveryOrders = useCallback((obj) => {
		navigate(pathnames.sales.deliveryOrdersViewEdit + data[obj.rowIndex].id, { state: data[obj.rowIndex].status });
	}, [data, navigate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Draft", name: STATUS.DRAFT },
			{ label: "In-Progress", name: STATUS.IN_PROGRESS },
			{ label: "Completed", name: STATUS.COMPLETED },
			{ label: "Cancelled", name: STATUS.CANCELLED }
		];

		return data;
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumber",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "id",
			label: "Delivery Order ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "customerName",
			label: "Customer Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "salesOrder",
			label: "Sales Order",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "total",
			label: "Total (MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true,
				setCellHeaderProps: () => ({ className: "table__price" }),
				setCellProps: () => ({ className: "table__price" }),
				customBodyRender: (value, tableMeta) => {
					return formatCurrency(value);
				}
			}
		},
		{
			name: "createdBy",
			label: "Created By",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "action",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditViewDeliveryOrders(tableMeta)}>
							<img src={chevronIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleEditViewDeliveryOrders]);

	useEffect(() => {
		setData(tempData);
	}, []);

	return (
		<div className="page-delivery-orders">
			<div className="delivery-orders">
				<h1 className="delivery-orders__title">Delivery Orders</h1>

				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder={"Search by Delivery Order ID or Customer Name"} searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} onHandleClearFilter={formik.resetForm} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="delivery-orders-filter">
						<div className="delivery-orders-filter__container">
							<div className="delivery-orders-filter__row">
								<AppInput type="text" label="Created by" name="createdBy" placeholder="User ID or Name" value={formik.values.createdBy} onChange={formik.handleChange} />
							</div>

							<div className="delivery-orders-filter__checkboxes">
								<p className="delivery-orders-filter__label">Status</p>

								<div className="delivery-orders-filter__checkbox-status">
									{menuFilterStatus.map((o) => {
										const isActiveStatus = formik.values.status.findIndex((i) => i === o.name) > -1;

										return (
											<div className="delivery-orders-filter__checkbox" key={o.label}>
												<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActiveStatus} />
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</AppTableFilterHeader>

				<AppTable data={data} columns={tableColumns} />
			</div>
		</div>
	);
};

export default PageDeliveryOrders;
