import React, { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ENDPOINT_PATH from "constants/end-point-path";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import closeIcon from "assets/images/close-icon.png";

const AppCustomerEditAssetAddServiceChecklistModal = (props, ref) => {
	const { id } = useParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [data, setData] = useState(convertPaginationTableData());
	const [visible, setVisible] = useState(false);
	const [selectedServicesChecklistsIds, setSelectedServicesChecklistsIds] = useState([]);
	const queryParams = useRef({ page: 0, keyword: "", sort: "" });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10, "asset-id": id });

			response = await api.get.assetMaintenance.assetExclusiveServiceChecklistSearch(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [id]);

	const onHandleSubmit = useCallback(() => {
		props.onConfirm(selectedServicesChecklistsIds);

		setVisible(false);

		setSelectedServicesChecklistsIds([]);
	}, [props, selectedServicesChecklistsIds]);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setSelectedServicesChecklistsIds([]);

		setVisible(false);
	}, []);

	// prettier-ignore
	const onHandleAddServices = useCallback((boolean, obj) => {
		let nextId = [...selectedServicesChecklistsIds];

		const index = nextId.findIndex((id) => id === obj.id);

		if (!boolean && index > -1) {
			nextId = nextId.filter((id) => id !== obj.id);
		} else {
			nextId.push(obj.id);
		}

		setSelectedServicesChecklistsIds(nextId);
	}, [selectedServicesChecklistsIds]);

	// prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	// prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "referenceNo",
			label: "Checklist ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "name",
			label: "Checklist Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "noOfServices",
			label: "No. of Services",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "add",
			label: "Add",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const obj = tableMeta.tableData[rowIndex];
					const selected = selectedServicesChecklistsIds.findIndex((id) => id === obj.id) > -1;

					return <AppCheckbox label="" onClick={(v) => onHandleAddServices(v, obj)} value={selected} />;
				}
			}
		}
	], [selectedServicesChecklistsIds, onHandleAddServices]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_EXCLUSIVE_SERVICE_CHECKLIST_SEARCH);
		};
	}, [cancelRequest]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
		onHandleGetExclusiveChecklist: onHandleGetList
	}));

	return (
		<Modal classes={{ root: "app-customer-edit-asset-add-service-checklist-modal" }} open={visible}>
			<div className="customer-edit-asset-add-service-checklist-modal">
				<button type="button" className="customer-edit-asset-add-service-checklist-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="customer-edit-asset-add-service-checklist-modal__title">Add Service Checklists</h1>

				<div className="customer-edit-asset-add-service-checklist-modal__form">
					<p className="customer-edit-asset-add-service-checklist-modal__description">Select Service Checklists from General Configuration Module</p>

					<AppTableFilterHeader searchPlaceholder="Search by Checklist ID or Checklist Name" onHandleSearch={onHandleSearch} />

					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />

					<div className="customer-edit-asset-add-service-checklist-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="button" label="Add" onClick={onHandleSubmit} />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppCustomerEditAssetAddServiceChecklistModal));
