const SIGNATURE_TYPE = {
	PREPARED_BY: "PREPARED_BY",
	COMPLETED_BY: "COMPLETED_BY",
	ACKNOWLEDGED_BY: "ACKNOWLEDGED_BY",
	VERIFIED_BY: "VERIFIED_BY",
	CANCELLED_BY: "CANCELLED_BY",
	RESCHEDULED_BY: "RESCHEDULED_BY",
	PENDING_RESCHEDULED_BY: "PENDING_RESCHEDULED_BY"
};

export default SIGNATURE_TYPE;
