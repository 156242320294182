import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import { formatCurrency } from "common/format-currency-pattern";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";

import closeIcon from "assets/images/close-icon.png";

export const AppQuotationDeleteItemModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState(null);

	const onHandleConfirm = useCallback(() => {
		props.onConfirm();

		setVisible(false);
	}, [props]);

	const onHandleShow = useCallback((values) => {
		setData(values);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-quotation-delete-item-modal" }} open={visible}>
			<div className="quotation-delete-item-modal">
				<button type="button" className="quotation-delete-item-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="quotation-delete-item-modal__title">Are you sure?</h1>

				<p className="quotation-delete-item-modal__description">
					After clicking confirm, the item ‘<b>{data?.title || "-"}</b>’ for <b>RM{formatCurrency(data?.lineItemTotalAmount || data?.lineSubItemTotalAmount)}</b> will be removed.
				</p>

				<div className="quotation-delete-item-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="button" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppQuotationDeleteItemModal));

AppQuotationDeleteItemModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
