import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";

import PropTypes from "prop-types";

import classNames from "common/class-names";

import trashIcon from "assets/images/trash-icon.png";
import importIcon from "assets/images/export-icon.png";

const AppInputDragAndDrop = (props) => {
	const inputRef = useRef();
	const dropAreaRef = useRef();
	const value = useMemo(() => props.value || "", [props.value]);
	const [displayImage, setDisplayImage] = useState(value);
	const isImage = useMemo(() => props.accept.includes("image"), [props.accept]);

	//prettier-ignore
	const inputClassName = useMemo(() =>classNames({
		"input-drag-and-drop": true,
		"input-drag-and-drop--error": props.error,
		"input-drag-and-drop--disabled": props.disabled
	}), [props]);

	//prettier-ignore
	const onHandleDragEnter = useCallback((event) => {
		if (props.disabled) return;

		dropAreaRef.current.firstChild.classList.remove("input-drag-and-drop--dragover");
		event.preventDefault();
		event.stopPropagation();
	}, [props]);

	//prettier-ignore
	const onHandleDragLeave = useCallback((event) => {
		if (props.disabled) return;

		dropAreaRef.current.firstChild.classList.remove("input-drag-and-drop--dragover");
		event.preventDefault();
		event.stopPropagation();
	}, [props]);

	//prettier-ignore
	const onHandleDragOver = useCallback((event) => {
		if (props.disabled) return;

		dropAreaRef.current.firstChild.classList.add("input-drag-and-drop--dragover");
		event.preventDefault();
		event.stopPropagation();
	}, [props]);

	//prettier-ignore
	const onHandleDrop = useCallback((event) => {
		if (props.disabled) return;

		dropAreaRef.current.firstChild.classList.remove("input-drag-and-drop--dragover");
		event.preventDefault();
		event.stopPropagation();

		const file = event.dataTransfer.files[0];

		if (file) {
			const image = URL.createObjectURL(file);

			setDisplayImage(image);
		}

		props.onChange(props.name, file);
	}, [props]);

	const onHandleDeleteFile = useCallback(() => {
		setDisplayImage("");

		props.onChange(props.name, null);
	}, [props]);

	const onHandleSelectFile = useCallback(() => {
		if (props.disabled) return;

		inputRef.current.click();
	}, [props]);

	const Placeholder = useCallback((obj) => {
		if (obj.placeholder) return obj.placeholder;

		return (
			<Fragment>
				Drag & Drop Files Here or{" "}
				<span type="button" className="input-drag-and-drop__button" onClick={obj.onHandleSelectFile}>
					Select Files
				</span>
			</Fragment>
		);
	}, []);

	//prettier-ignore
	const onHandleChange = useCallback((event) => {
		const file = event.target.files[0];

		if (file) {
			const image = URL.createObjectURL(file);

			setDisplayImage(image);
		}

		props.onChange(props.name, file);
	}, [props]);

	useEffect(() => {
		const droparea = dropAreaRef.current;

		droparea.addEventListener("dragenter", onHandleDragEnter);
		droparea.addEventListener("dragleave", onHandleDragLeave);
		droparea.addEventListener("dragover", onHandleDragOver);
		droparea.addEventListener("drop", onHandleDrop);

		return () => {
			if (droparea) {
				droparea.removeEventListener("dragenter", onHandleDragEnter);
				droparea.removeEventListener("dragenter", onHandleDragLeave);
				droparea.removeEventListener("dragover", onHandleDragOver);
				droparea.removeEventListener("drop", onHandleDrop);
			}
		};
	}, [onHandleDragEnter, onHandleDragLeave, onHandleDragOver, onHandleDrop]);

	useEffect(() => {
		setDisplayImage(value);
	}, [value]);

	return (
		<div className="app-input-drag-and-drop" ref={dropAreaRef}>
			<div className={inputClassName}>
				<input ref={inputRef} hidden name={props.name} type="file" accept={props.accept} onChange={onHandleChange} />

				{!displayImage && (
					<Fragment>
						<div className="input-drag-and-drop__icon">
							<img src={importIcon} alt="upload-icon" />
						</div>

						<p className="input-drag-and-drop__placeholder">
							<Placeholder placeholder={props.placeholder} onHandleSelectFile={onHandleSelectFile} />
						</p>
					</Fragment>
				)}

				{displayImage && isImage && (
					<Fragment>
						<div className="input-drag-and-drop__display">
							<img src={displayImage} alt={props.name} />
						</div>

						<button type="button" className="input-drag-and-drop__remove-button" onClick={onHandleDeleteFile}>
							<img src={trashIcon} alt="delete icon" />
						</button>
					</Fragment>
				)}
			</div>
		</div>
	);
};

export default AppInputDragAndDrop;

AppInputDragAndDrop.propTypes = {
	disabled: PropTypes.bool,
	error: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	accept: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired
};
