import { useCallback, useContext, useEffect, useMemo } from "react";

import { useFormik } from "formik";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import serveRequestErrors from "common/serve-request-errors";

import ENDPOINT_PATH from "constants/end-point-path";

import AppButton from "components/app-button";

import logo from "assets/images/logo.png";

const PageConfirmDeactivateMobileUser = () => {
	const props = useContext(AxiosContext);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const appTypeText = useMemo(() => (searchParams.get("platform") === "cma" ? "Client" : "Employee"), [searchParams]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const formik = useFormik({
		initialValues: { email: searchParams.get("email"), token: searchParams.get("token") },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		const mobilePlatform = searchParams.get("platform");

		let response = null;

		try {
			const payload = {
				email: values.email,
				token: values.token
			};

			const headers = {};

			switch (mobilePlatform) {
				case "cma":
					headers.baseURL = process.env.REACT_APP_CLIENT_API_URL;

					await api.post.customerMobileUser.deactivateAccount(payload, headers);
					break;
				case "ema":
					headers.baseURL = process.env.REACT_APP_EMPLOYEE_API_URL;

					await api.post.employeeMobileUser.deactivateAccount(payload, headers);
					break;
				default:
					break;
			}

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			navigate(pathnames.deactivateMobileUser.deactivateSuccess + "?platform=" + searchParams.get("platform"));
		}
	}, [searchParams, navigate, formik]);

	const onHandleCancel = useCallback(() => {
		navigate(pathnames.login.login);
	}, [navigate]);

	useEffect(() => {
		const mobilePlatform = searchParams.get("platform");

		const onHandleVerifyEmail = async () => {
			try {
				const payload = {
					token: searchParams.get("token"),
					email: searchParams.get("email")
				};

				const headers = {};

				switch (mobilePlatform) {
					case "cma":
						headers.baseURL = process.env.REACT_APP_CLIENT_API_URL;

						await api.post.customerMobileUser.verifyEmail(payload, headers);
						break;
					case "ema":
						headers.baseURL = process.env.REACT_APP_EMPLOYEE_API_URL;

						await api.post.employeeMobileUser.verifyEmail(payload, headers);
						break;
					default:
						break;
				}
			} catch (error) {
				const cancelled = serveRequestErrors(error);

				if (!cancelled) navigate(pathnames.linkExpired + "?platform=" + searchParams.get("platform") + "&action=DEACTIVATE");
			}
		};

		onHandleVerifyEmail();

		return () => {
			switch (mobilePlatform) {
				case "cma":
					cancelRequest(ENDPOINT_PATH.CUSTOMER_MOBILE_USER.VERIFY_EMAIl);
					break;
				case "ema":
					cancelRequest(ENDPOINT_PATH.EMPLOYEE_MOBILE_USER.VERIFY_EMAIl);
					break;
				default:
					break;
			}
		};
	}, [navigate, searchParams, cancelRequest]);

	return (
		<div className="page-confirm-deactivate-mobile-user">
			<main className="confirm-deactivate-mobile-user">
				<form className="confirm-deactivate-mobile-user__form" onSubmit={formik.handleSubmit}>
					<Link to={pathnames.login.login} className="confirm-deactivate-mobile-user__logo">
						<img src={logo} alt="logo" />
					</Link>

					<h1 className="confirm-deactivate-mobile-user__title">Delete Account</h1>

					<p className="confirm-deactivate-mobile-user__description">Are you sure you would like to delete your MCube {appTypeText} App Account</p>

					<div className="confirm-deactivate-mobile-user__button-container">
						<AppButton className="confirm-deactivate-mobile-user__confirm-button" type="submit" label="Confirm" disabled={formik.isSubmitting} />

						<AppButton outline type="button" label="Cancel" onClick={onHandleCancel} />
					</div>
				</form>
			</main>
		</div>
	);
};

export default PageConfirmDeactivateMobileUser;
