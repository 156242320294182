import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { Modal } from "@mui/material";

import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import STATUS from "constants/status";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppInputDate from "components/app-input-date";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";

import closeIcon from "assets/images/close-icon.png";

export const AppLeaveHistoryViewModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const downloadingFile = useRef(false);

	const initialValues = useMemo(() => {
		const values = {
			employeeName: "",
			employeeId: "",
			leaveType: "",
			timeOffType: "",
			startDate: "",
			endDate: "",
			status: "",
			reviewedBy: "",
			days: "",
			description: "",
			approved: "",
			rejectReason: "",
			file: []
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues
	});

	const onHandleDownloadFile = useCallback(async (fileObject) => {
		if (downloadingFile.current) return;

		downloadingFile.current = true;

		let response = null;

		try {
			const payload = { "doc-id": fileObject.id };

			response = await api.post.humanResource.downloadLeaveDoc(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const url = response.fileUrl;
			const a = document.createElement("a");
			document.body.appendChild(a);

			a.href = url;
			a.download = response.fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				downloadingFile.current = false;
			}, 0);
		}
	}, []);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({
			employeeName: obj?.employeeName || "",
			employeeId: obj?.employeeId || "",
			status: obj?.status || "",
			reviewedBy: obj?.reviewedBy || "",
			leaveType: obj?.leaveType || "",
			timeOffType: obj?.timeOffType || "",
			startDate: dayjs(obj?.startDate) || "",
			endDate: dayjs(obj?.endDate) || "",
			days: obj?.days || "",
			submitDate: dayjs(obj?.submitDate) || "",
			description: obj?.description || "",
			rejectReason: obj?.rejectReason || "",
			file: obj?.docs || ""
		});
		
		setVisible(true);
	}, [formik]);

	const isRejected = useMemo(() => formik.values.status === STATUS.REJECTED, [formik]);
	const appInputLabel = useMemo(() => (isRejected ? "Rejected By" : "Approved By"), [isRejected]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow
	}));

	return (
		<Modal classes={{ root: "app-leave-history-view-modal" }} open={visible}>
			<div className="leave-history-view-modal">
				<button type="button" className="leave-history-view-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="leave-history-view-modal__title">Leave History</h1>

				<form className="leave-history-view-modal__form" onSubmit={formik.handleSubmit}>
					<div className="leave-history-view-modal__row">
						<AppInput disabled type="text" name="name" label="Name" value={formik.values.employeeName} error={formik.errors.employeeName} touched={formik.touched.employeeName} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="employeeId" label="Employee ID" value={formik.values.employeeId} error={formik.errors.employeeId} touched={formik.touched.employeeId} onChange={formik.handleChange} />
					</div>

					<div className="leave-history-view-modal__row">
						<AppInput disabled type="text" name="status" label="Status" value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="reviewedBy" label={appInputLabel} value={formik.values.reviewedBy} error={formik.errors.reviewedBy} touched={formik.touched.reviewedBy} onChange={formik.handleChange} />
					</div>

					<div className="leave-history-view-modal__row">
						<AppInput disabled type="text" name="leaveType" label="Leave Type" value={formik.values.leaveType} error={formik.errors.leaveType} touched={formik.touched.leaveType} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="timeOffType" label="Time Off" value={formik.values.timeOffType} error={formik.errors.timeOffType} touched={formik.touched.timeOffType} onChange={formik.handleChange} />
					</div>

					<div className="leave-history-view-modal__row">
						<AppInputDate disabled type="text" name="startDate" label="Start Date" value={formik.values.startDate} error={formik.errors.startDate} touched={formik.touched.startDate} onChange={formik.handleChange} />

						<AppInputDate disabled type="text" name="endDate" label="End Date" value={formik.values.endDate} error={formik.errors.endDate} touched={formik.touched.endDate} onChange={formik.handleChange} />
					</div>

					<div className="leave-history-view-modal__row">
						<AppInput disabled type="text" name="days" label="Days" value={formik.values.days} error={formik.errors.days} touched={formik.touched.days} onChange={formik.handleChange} />

						<AppInputDate disabled type="text" name="submitDate" label="Submit Date" value={formik.values.submitDate} error={formik.errors.submitDate} touched={formik.touched.submitDate} onChange={formik.handleChange} />
					</div>

					<AppInput disabled type="text" label="Description" name="description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />

					<AppInputDragAndDropFiles disabled name="file" value={formik.values.file} onHandleDownloadFile={onHandleDownloadFile} onChange={formik.setFieldValue} />

					{formik.values.approved === STATUS.REJECTED && <AppInput type="textarea" label="Reject Reason" name="rejectReason" value={formik.values.rejectReason} error={formik.errors.rejectReason} touched={formik.touched.rejectReason} onChange={formik.handleChange} />}
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppLeaveHistoryViewModal));
