import api from "services/api";

import serveRequestErrors from "common/serve-request-errors";

const getSparePartCategoryListing = async (payload) => {
	let response = null;
	let errorMessage = null;

	try {
		const params = { size: 10, page: payload?.page || 0, status: "IN_USE", value: payload?.keyword || "" };

		const res = await api.get.sparePartCategory.sparePartCategories(params);

		const content = res.content.map((item) => ({ value: item.id, label: item.name }));

		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getSparePartCategoryListing;
