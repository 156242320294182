import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import * as XLSX from "xlsx";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import api from "services/api";

import validateFileSize from "common/validate-file-size";
import serveRequestErrors from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import MAX_FILE_SIZES from "constants/max-file-size";

import AppButton from "components/app-button";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";
import AppCustomerAssetListTable from "components/pages/customer/app-customer-asset-list-table";
import AppCustomerAssetListFailed from "components/pages/customer/app-customer-asset-list-failed-modal";
import AppCustomerAssetListSuccess from "components/pages/customer/app-customer-asset-list-success-modal";

import downloadIcon from "assets/images/download-icon.png";
import chevronUpIcon from "assets/images/pages/customer/chevron-up.svg";
import chevronDownIcon from "assets/images/pages/customer/chevron-down.svg";

const AppCustomerAssetList = (props, ref) => {
	const { id } = useParams();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_CONTRACT_SITE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const assetListRefSuccess = useRef();
	const assetListRefFailed = useRef();
	const [isMinimized, setIsMinimized] = useState(false);
	const [data, setData] = useState([]);
	const tableData = useMemo(() => (isCreate ? data : props.assetList), [data, isCreate, props.assetList]);

	const initialValues = useMemo(() => {
		let values = {
			files: []
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			file: yup.array().of(yup.mixed().test("fileSize", ERRORS.FILE_SIZE.replace("{size}", "10"), (value) => validateFileSize(value, MAX_FILE_SIZES.MB_10)))
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const toggleMinimize = () => {
		setIsMinimized(!isMinimized);
	};

	const onHandleAssetList = useCallback((addedAssetsLength) => {
		assetListRefSuccess.current.onHandleShow(addedAssetsLength);
	}, []);

	const onHandleAssetListFailed = useCallback((file, errorRow) => {
		assetListRefFailed.current.onHandleShow(file, errorRow);
	}, []);

	// prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		const formData = new FormData();

		values.files.forEach((o) => {
			formData.append("file", o);
		});

		props.onSubmit({ files: formData });
	}, [props]);

	// prettier-ignore
	const onHandleLoadData = useCallback((fileList) => {
		const nextFiles = [];
		const nextAssets = [];
		let error = false;
		let errorRow = 0;

		const hasEmptyCell = (row) => Object.values(row).some((value) => value === undefined || value === "");

		fileList.every((file, i) => {
			const wb = XLSX.read(file, { type: "buffer" });
			const assetsSheet = wb.Sheets.sheet1;
			const assetsJsonList = XLSX.utils.sheet_to_json(assetsSheet);

			error = assetsJsonList.some((row, i) => {
				errorRow = i + 1;

				return hasEmptyCell(row);
			});

			if (error) {
				onHandleAssetListFailed(file, errorRow);

				return false;
			}

			const assets = assetsJsonList.map((o) => ({
				fileIndex: formik.values.files.length + i,
				customerAssetName: o["Customer Asset Name"],
				assetName: o["Asset Name"],
				assetType: o["Asset Type"],
				frequency: o["Frequency"],
				workSpace: o["Work Space"],
				description: o["Description"],
				remarks: o["Remarks"]
			}));

			nextFiles.push(file);
			nextAssets.push(...assets);

			return true;
		});

		formik.setFieldValue("files", [...formik.values.files, ...nextFiles]);

		setData((prev) => [...prev, ...nextAssets]);

		onHandleAssetList(nextAssets.length);
	}, [formik, onHandleAssetList, onHandleAssetListFailed]);

	// prettier-ignore
	const onHandleRemoveData = useCallback((nextValues) => {
		const removedFile = formik.values.files.filter((file) => {
			return !nextValues.some((value) => file.name === value.name);
		});
		const removedFileIndex = formik.values.files.indexOf(removedFile[0]);
		let nextData = [...data];

		nextData = nextData.filter((o) => o.fileIndex !== removedFileIndex);

		nextData = nextData.map((o) => {
			if (o.fileIndex > removedFileIndex) {
				o.fileIndex -= 1;
			}

			return o;
		});

		setData(nextData);
	}, [data, formik.values.files]);

	// prettier-ignore
	const onHandleChange = useCallback((field, nextValues) => {
		if (!isCreate) onHandleSubmit({ files: nextValues });

		if (formik.values.files.length > nextValues.length) {
			onHandleRemoveData(nextValues);

			formik.setFieldValue(field, nextValues);
		} else {
			const filesAddedLength = nextValues.length - formik.values.files.length;
			let filesAdded = [];

			if (!filesAddedLength) return;

			filesAdded = [...nextValues].slice(formik.values.files.length, nextValues.length);

			const duplicateFile = filesAdded.filter((value) => formik.values.files.some((file) => file.name === value.name));

			if (duplicateFile) return;

			onHandleLoadData(filesAdded);
		}
	}, [formik, isCreate, onHandleLoadData, onHandleRemoveData, onHandleSubmit]);

	const onHandleDownloadTemplate = useCallback(async () => {
		let response = null;
		let fileName = "";

		try {
			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("attachment; filename=")?.[1]?.split('"')?.[1];

				if (fileName) return data;

				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));

					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			response = await api.get.general.template("ASSET", { transformResponse });
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}, 1000);
		}
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleSubmit: formik.handleSubmit
	}));

	return (
		<div className="app-customer-asset-list">
			<div className="customer-asset-list">
				<form className="customer-asset-list__form" onSubmit={formik.handleSubmit}>
					<div className="customer-asset-list__container">
						<div className="customer-asset-list__row">
							<p className="customer-asset-list__title">How do I upload Assets?</p>

							<button type="button" className="customer-asset-list__chevron" onClick={toggleMinimize}>
								<img src={isMinimized ? chevronDownIcon : chevronUpIcon} alt="chevron-icon" />{" "}
							</button>
						</div>

						{!isMinimized && (
							<div className="customer-asset-list__container customer-asset-list__container--minimized">
								<p className="customer-asset-list__label">Step 1</p>

								<p className="customer-asset-list__instructions">To upload assets, download the ‘Bulk Upload Asset Template’ and input the asset information. Please do not edit the existing values as it may interfere with the asset information.</p>

								<p className="customer-asset-list__button">
									<AppButton outline type="button" disabled={restricted} label="Download Template" icon={downloadIcon} onClick={onHandleDownloadTemplate} />
								</p>

								<p className="customer-asset-list__label">Step 2</p>

								<p className="customer-asset-list__instructions">After filling out the file, upload it here in xls or xlsx format to onboard the assets into this contract.</p>

								<AppInputDragAndDropFiles name="files" disabled={restricted} accept=".xlsx, .xls" length={5} onChange={onHandleChange} error={formik.errors.files} value={formik.values.files} />
							</div>
						)}
					</div>

					<div className="customer-asset-list__container">
						<AppCustomerAssetListTable data={tableData} disabled={isCreate || restricted} onHandleGetAssetList={props.onHandleGetAssetList} />
					</div>
				</form>
			</div>

			<AppCustomerAssetListSuccess ref={assetListRefSuccess} />

			<AppCustomerAssetListFailed ref={assetListRefFailed} />
		</div>
	);
};

export default memo(forwardRef(AppCustomerAssetList));
