import React, { useMemo } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import AppButton from "components/app-button";

import logo from "assets/images/logo.png";
import linkedExpired from "assets/images/pages/forgot-password/link-expired.png";

const PageLinkExpired = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const action = useMemo(() => searchParams.get("action"), [searchParams]);
	const isWebsitePlatform = useMemo(() => searchParams.get("platform") === "cma" || searchParams.get("platform") === "ema", [searchParams]);

	const actionText = useMemo(() => {
		let text = "";

		switch (action) {
			case "DEACTIVATE":
				text = "deactivate mobile account";
				break;
			case "RESET_PASSWORD":
				text = "access the back-office portal";
				break;
			default:
				break;
		}

		return text;
	}, [action]);

	const onHandleBack = () => {
		navigate(pathnames.login.login);
	};

	return (
		<div className="page-link-expired">
			<main className="link-expired">
				<div className="link-expired__container">
					<div className="link-expired__logo">
						<img src={logo} alt="logo" />
					</div>

					<h1 className="link-expired__title">Link Expired</h1>

					<p className="link-expired__description">Please request a new temporary link to {actionText}.</p>

					<div className="link-expired__hero">
						<img src={linkedExpired} alt="hero" />
					</div>

					{isWebsitePlatform && (
						<div className="link-expired__button-container">
							<AppButton type="button" label="Back to Log In" onClick={onHandleBack} />
						</div>
					)}
				</div>
			</main>
		</div>
	);
};

export default PageLinkExpired;
