import React, { useMemo } from "react";

import { useLocation } from "react-router-dom";

import pathnames from "routes/pathnames";

import useBreadcrumb from "hooks/use-breadcrumb";

import STATUS from "constants/status";

import AppTabs from "components/app-tabs";
import AppStatus from "components/app-status";
import AppAttendanceSummaryCalendar from "components/pages/human-resources/app-attendance-summary-calendar";
import AppScheduledAttendanceEmployeeCalendar from "components/pages/human-resources/app-scheduled-attendance-employee-calendar";
import AppCorrectiveAttendanceHistoryEmployeeTable from "components/pages/human-resources/app-corrective-attendance-history-employee-table";
import AppPendingCorrectiveAttendanceEmployeeTable from "components/pages/human-resources/app-pending-corrective-attendance-employee-table";

import clockIcon from "assets/images/pages/human-resources/clock-icon.svg";
import leaveIcon from "assets/images/pages/human-resources/leave-icon.svg";
import hourglassIcon from "assets/images/pages/human-resources/hourglass-icon.svg";
import clockActiveIcon from "assets/images/pages/human-resources/clock-active-icon.svg";
import leaveActiveIcon from "assets/images/pages/human-resources/leave-active-icon.svg";
import hourglassActiveIcon from "assets/images/pages/human-resources/hourglass-active-icon.svg";
import pencilSignatureIcon from "assets/images/pages/human-resources/pencil-signature-icon.svg";
import pencilSignatureActiveIcon from "assets/images/pages/human-resources/pencil-signature-active-icon.svg";

const tempData = { name: "NO DATA", employeeId: "000", todayStatus: STATUS.ON_LEAVE };

const PageViewAttendance = () => {
	const location = useLocation();
	const dataRef = useMemo(() => (location?.state ? location?.state : tempData), [location]);

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Attendance Management", path: pathnames.humanResources.attendanceManagement },
			{ label: `${dataRef?.employeeId} ${dataRef?.name} Attendance`, path: pathnames.humanResources.viewAttendance }
		];

		return data;
	}, [dataRef]);

	useBreadcrumb({ breadCrumb });

	//prettier-ignore
	const attendanceTabs = useMemo(() => [
		{ label: "Attendance Summary", icon: leaveIcon, activeIcon: leaveActiveIcon, name: "ATTENDANCE_SUMMARY", component: <AppAttendanceSummaryCalendar />, accessible: true },
		{ label: "Scheduled Attendance", icon: pencilSignatureIcon, activeIcon: pencilSignatureActiveIcon, name: "SCHEDULED_ATTENDANCE", component: <AppScheduledAttendanceEmployeeCalendar />, accessible: true },
		{ label: "Corrective Attendance History", icon: clockIcon, activeIcon: clockActiveIcon, name: "CORRECTIVE_ATTENDANCE_HISTORY", component: <AppCorrectiveAttendanceHistoryEmployeeTable />, accessible: true },
		{ label: "Pending Corrective Attendance", icon: hourglassIcon, activeIcon: hourglassActiveIcon, name: "PENDING_CLOCK_OUT", component: <AppPendingCorrectiveAttendanceEmployeeTable />, accessible: true }
	], []);

	return (
		<div className="page-view-attendance">
			<div className="view-attendance">
				<div className="view-attendance__header">
					<h1 className="view-attendance__title">{dataRef.name}</h1>

					<AppStatus status={dataRef.todayStatus} />
				</div>

				<div className="view-attendance__body">
					<p className="view-attendance__id">{dataRef.employeeId}</p>

					<AppTabs tabs={attendanceTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageViewAttendance;
