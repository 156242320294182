import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import { useFormik } from "formik";
import { Modal } from "@mui/material";
import { useSelector } from "react-redux";

import ROLES from "constants/roles";

import AppButton from "components/app-button";

const AppAllPendingClaimPaidModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CLAIM_TYPE_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const formik = useFormik({ initialValues: { claimType: "", id: "", receiptAmount: "" } });

	//prettier-ignore
	const onHandleShow = useCallback((values) => {
		formik.setValues(...values)

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	const onHandlePaid = useCallback(() => {
		setVisible(false);

		props.onHandleClaimPaid(formik.values.id);
	}, [formik, props]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-all-pending-claim-paid-modal" }} open={visible}>
			<div className="all-pending-claim-paid-modal">
				<h1 className="all-pending-claim-paid-modal__title">Are you sure?</h1>

				<p className="all-pending-claim-paid-modal__description">
					After clicking confirm, {formik.values?.claimType} for RM {formik.values?.receiptAmount} will be set as <span>Paid</span>.
				</p>

				<div className="all-pending-claim-paid-modal__footer-button">
					<div className="all-pending-claim-paid-modal__cancel-button">
						<AppButton type="button" outline label="Cancel" onClick={onHandleDismiss} />
					</div>

					<div className="all-pending-claim-paid-modal__confirm-button">
						<AppButton outline type="button" disabled={restricted} label="Confirm" onClick={onHandlePaid} />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppAllPendingClaimPaidModal));
