import React, { Fragment, forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FieldArray, FormikProvider, setNestedObjectValues, useFormik } from "formik";

import api from "services/api";
import getSparePartListing from "services/get-spare-part-listing";
import getWorkInspectionReportAssetListing from "services/get-work-inspection-report-asset-listing";

import useBeforeUnload from "hooks/use-before-unload";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";
import AppWorkInspectionHoldOnModal from "components/pages/work-inspection-listing/app-work-inspection-hold-on-modal";
import AppWorkInspectionConfirmDeleteFindingModal from "components/pages/work-inspection-listing/app-work-inspection-confirm-delete-finding-modal";

import trashIcon from "assets/images/trash-icon.png";
import addIcon from "assets/images/blue-add-icon.svg";
import chevronUpIcon from "assets/images/chevron-up-icon.png";
import chevronDownIcon from "assets/images/chevron-down-grey-icon.png";

const AppFindingDetails = (props, ref) => {
	useBeforeUnload();
	const { id } = useParams();
	const dispatch = useDispatch();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const [collapsedIndices, setCollapsedIndices] = useState(new Set());
	const confirmDeleteFindingRef = useRef();
	const holdOnModalRef = useRef();

	const initialValues = useMemo(() => {
		const values = {
			assetId: "",
			assetFindingIds: [],
			findings: []
		};

		return values;
	}, []);

	const onHandleSubmit = () => {};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			assetId: yup.string().required(ERRORS.REQUIRED),
			findings: yup.array().of(
				yup.object({
					problem: yup.string().required(ERRORS.REQUIRED),
					configSparePart: yup.object({
						id: yup.string().required(ERRORS.REQUIRED)
					}),
					proposedAction: yup.string().required(ERRORS.REQUIRED),
					wirFindingAttachment: yup.array().of(yup.mixed().nullable())
				})
			)
		}),
		onSubmit: onHandleSubmit
	});

	// prettier-ignore
	const onHandleGetAssetFindingIds = useCallback(async (value) => {
		let response = null;

		try {
			response = await api.get.workInspectionReport.assetFindings({ "asset-id": value });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setCollapsedIndices((prev) => {
				const newCollapsedIndices = new Set(prev);

				response.map((o, i) => newCollapsedIndices.add(i));

				return newCollapsedIndices;
			});

			formik.setFieldValue("assetFindingIds", response);
			formik.setFieldValue("findings", response.map(() => ({})));
		}
	}, [formik]);

	//prettier-ignore
	const onHandleSelectAsset = useCallback((event) => {
		const value = event.target.value;

		formik.setFieldValue("assetId", value);

		onHandleGetAssetFindingIds(value);
	}, [formik, onHandleGetAssetFindingIds]);

	const onHandleCheckFormikDirty = useCallback(() => {
		return formik.dirty;
	}, [formik]);

	const onHandleLeavePageModal = useCallback(() => {
		holdOnModalRef.current.onHandleShow();
	}, []);

	//prettier-ignore
	const onConfirmDeleteFinding = useCallback(async (type, data) => {
		if (isCreate || !data.finding.id) {
			const nextFields = [...formik.values[type]].filter((_, i) => i !== data.index);
	
			formik.setFieldValue(type, nextFields);
		}

		if (!isCreate && data.finding.id) {
			let response = null;

			try {
				await api.post.workInspectionReport.deleteAssetFinding({ "finding-id": data.finding.id });
				
				response = true;
			} catch (error) {
				serveLayoutRequestErrors(error);
			}
	
			if (response) {
				const nextFields = [...formik.values[type]].filter((_, i) => i !== data.index);
	
				formik.setFieldValue(type, nextFields);
			}
		}
	}, [isCreate, formik]);

	//prettier-ignore
	const onHandleNewFinding = useCallback(async (renderProps, type) => {
		if (formik.values[type].length === 10) return;

		const fieldErrors = await renderProps.form.validateForm();
		const fields = structuredClone(formik.values[type]);

		if (fieldErrors.assetId || fieldErrors.findings) {
			renderProps.form.setTouched(setNestedObjectValues(fieldErrors, true));

			return;
		}

		if (fields.length === 0) {
			formik.setFieldValue(type, [{ newField: true, configSparePart: { id: "" }, problem: "", proposedAction: "", findingDescription: "", wirFindingAttachment: [] }]);
		} else {
			const field = { newField: true, configSparePart: { id: "" },  problem: "", proposedAction: "", findingDescription: "", wirFindingAttachment: [] };

			fields.push(field);

			formik.setFieldValue(type, fields);
		}

		setCollapsedIndices(new Set());
	}, [formik]);

	// prettier-ignore
	const onHandleViewFinding = useCallback(async (index) => {
		const findingsCopy = structuredClone(formik.values.findings);
		const finding = findingsCopy[index];

		if (Object.keys(finding).length) return;

		const assetFindingIdsCopy = structuredClone(formik.values.assetFindingIds);
		const findingId = assetFindingIdsCopy[index];

		let response = null;

		try {
			response = await api.get.workInspectionReport.viewFinding({ "finding-id": findingId });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			findingsCopy[index] = response;
			findingsCopy[index].wirFindingAttachment = response.wirFindingAttachment ? response.wirFindingAttachment : [];
			findingsCopy[index].wirFindingAttachmentFiles = response.wirFindingAttachment ? response.wirFindingAttachment : [];

			formik.setFieldValue("findings", findingsCopy);
		}
	}, [formik]);

	// prettier-ignore
	const onHandleUploadFiles = useCallback(async (files, findingId) => {
		let response = null;

		try {
			const formData = new FormData();

			formData.append("wirAssetFindingId", findingId);

			files.forEach((o) => {
				formData.append("files", o);
			})

			await api.post.workInspectionReport.uploadAssetFindingAttachment(formData);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Files were added successfully!" }));
		}
	}, [dispatch]);

	//prettier-ignore
	const onHandleAddUpdate = useCallback(async (index) => {
		const fieldErrors = await formik.setFieldTouched(`findings[${index}]`);

		if (Object.keys(fieldErrors).length) return;

		const findingCopy = structuredClone(formik.values.findings[index]);

		const payload = {
			workInspectionAssetId: formik.values.assetId,
			configSparePartId: findingCopy.configSparePart.id,
			problem: findingCopy.problem,
			proposedAction: findingCopy.proposedAction,
			findingDescription: findingCopy.findingDescription
		};

		let response = null;

		if (!findingCopy.id) {
			try {
				response = await api.post.workInspectionReport.createAssetFinding(payload);
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				const filesList = response.wirFindingAttachment || findingCopy.wirFindingAttachmentFiles;

				onHandleUploadFiles(filesList, response.id);

				onHandleViewFinding(index);

				dispatch(promptLayoutAlertMessage({ message: "Finding was created successfully!" }));
			}
		} else {
			try {
				payload.id = findingCopy.id;

				response = await api.post.workInspectionReport.updateAssetFinding(payload);
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				const files = findingCopy.wirFindingAttachmentFiles

				onHandleUploadFiles(files, response.id);

				onHandleViewFinding(index);

				dispatch(promptLayoutAlertMessage({ message: "Finding was updated successfully!" }));
			}
		}
	}, [dispatch, formik, onHandleUploadFiles, onHandleViewFinding]);

	//prettier-ignore
	const onHandleSetFile = useCallback((name, value, findingIndex, imageIndex) => {
		const imageUrl = value ? URL.createObjectURL(value) : null;

		formik.setFieldValue(`findings[${findingIndex}].wirFindingAttachmentFiles[${imageIndex}]`, value);
		formik.setFieldValue(name, imageUrl);
	}, [formik]);

	// prettier-ignore
	const toggleCollapse = useCallback((index) => {
		setCollapsedIndices((prev) => {
			const newCollapsedIndices = new Set(prev);

			if (newCollapsedIndices.has(index)) {
				newCollapsedIndices.delete(index);

				onHandleViewFinding(index);
			} else {
				newCollapsedIndices.add(index);
			}

			return newCollapsedIndices;
		});
	}, [onHandleViewFinding]);

	//prettier-ignore
	const Findings = useCallback((obj) => {
		return obj.findings.map((o, i) => {
			const error = obj.findingsErrors?.[i];
			const touched = obj.findingsTouched?.[i];

			return (
				<div key={i} className={`finding-details__wrapper ${obj.collapsedIndices.has(i) ? "finding-details__wrapper--collapsed" : "finding-details__wrapper--new-record"}`}>
					<div className="finding-details__category">
						<p className="finding-details__header-label">Finding {i + 1}</p>

						<div className="finding-details__header-button">
							{(o?.id || o?.newField) && <AppButton className="finding-details__delete-record-button" outline type="button" label="" icon={trashIcon} onClick={() => confirmDeleteFindingRef.current.onHandleShow({ index: i, finding: o })} />}

							<AppButton className="finding-details__toggle-button" outline type="button" label="" icon={obj.collapsedIndices.has(i) ? chevronDownIcon : chevronUpIcon} onClick={() => obj.toggleCollapse(i)} />
						</div>
					</div>

					{!obj.collapsedIndices.has(i) && (
						<div className="finding-details__wrapper">
							<div className="finding-details__finding-header">
								<p className="finding-details__label">Details</p>

								<div className="finding-details__button">
									<AppButton type="button" label="Update" onClick={() => obj.onHandleAddUpdate(i)} />
								</div>
							</div>

							<div className="finding-details__row">
								<AppSelectInput required name={`findings[${i}].configSparePart.id`} label="Spare Part Name" placeholder="Select..." loadOptions={getSparePartListing} value={o?.configSparePart?.id} error={error?.configSparePart?.id} touched={touched} onChange={obj.formik.handleChange} />

								<AppInput required type="text" name={`findings[${i}].problem`} label="Problem" placeholder="Enter Problem" value={o?.problem} error={error?.problem} touched={touched} onChange={obj.formik.handleChange} />
							</div>

							<div className="finding-details__row">
								<AppInput required type="text" name={`findings[${i}].proposedAction`} label="Proposed Action" placeholder="Enter Proposed Action" value={o?.proposedAction} error={error?.proposedAction} touched={touched} onChange={obj.formik.handleChange} />

								<AppInput multiline type="textarea" name={`findings[${i}].findingDescription`} label="Finding Description" placeholder="Enter Finding Description" value={o?.findingDescription} error={error?.findingDescription} touched={touched} onChange={obj.formik.handleChange} />
							</div>

							<p className="finding-details__label">Attachments</p>

							<p className="finding-details__description">Please ensure that all images are in either jpg or png format with file size not exceeding 5MB. (Max 4 images only)</p>

							<div className="finding-details__row">
								<AppInputDragAndDrop name={`findings[${i}].wirFindingAttachment[0].filePath`} accept="image/png, image/jpeg, image/jpg" value={o?.wirFindingAttachment?.[0]?.filePath} onChange={(name, value) => obj.onHandleSetFile(name, value, i, 0)} />

								<AppInputDragAndDrop name={`findings[${i}].wirFindingAttachment[1.filePath]`} accept="image/png, image/jpeg, image/jpg" value={o?.wirFindingAttachment?.[1]?.filePath} onChange={(name, value) => obj.onHandleSetFile(name, value, i, 1)} />
							</div>

							<div className="finding-details__row">
								<AppInputDragAndDrop name={`findings[${i}].wirFindingAttachment[2].filePath`} accept="image/png, image/jpeg, image/jpg" value={o?.wirFindingAttachment?.[2]?.filePath} onChange={(name, value) => obj.onHandleSetFile(name, value, i, 2)} />

								<AppInputDragAndDrop name={`findings[${i}].wirFindingAttachment[3].filePath`} accept="image/png, image/jpeg, image/jpg" value={o?.wirFindingAttachment?.[3]?.filePath} onChange={(name, value) => obj.onHandleSetFile(name, value, i, 3)} />
							</div>
						</div>
					)}
				</div>
			);
		});
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleLeavePageModal: onHandleLeavePageModal,
		onHandleCheckFormikDirty: onHandleCheckFormikDirty
	}));

	return (
		<FormikProvider value={formik}>
			<div className="app-finding-details">
				<div className="finding-details">
					<div className="finding-details__container">
						<p className="finding-details__label">Select Asset</p>

						<p className="finding-details__description">Please select an asset to view the finding details:</p>

						<form className="finding-details__form" onSubmit={formik.handleSubmit}>
							<FieldArray
								name="findings"
								render={(f) => {
									const values = f.form.values?.findings;
									const errors = f.form.errors?.findings;
									const touched = f.form.touched?.findings;

									return (
										<Fragment>
											<div className="finding-details__header">
												<AppSelectInput required pagination name="assetId" label="Asset" placeholder="Select..." loadOptions={() => getWorkInspectionReportAssetListing({ id: id })} value={formik.values.assetId} error={formik.errors.assetId} touched={formik.touched.assetId} onChange={(e) => onHandleSelectAsset(e)} />

												<AppButton className="finding-details__add-record-button" outline type="button" label="Add Finding" icon={addIcon} onClick={() => onHandleNewFinding(f, "findings")} />
											</div>

											<Findings formik={formik} findings={values} findingsErrors={errors} findingsTouched={touched} collapsedIndices={collapsedIndices} toggleCollapse={toggleCollapse} onHandleAddUpdate={onHandleAddUpdate} onHandleSetFile={onHandleSetFile} />
										</Fragment>
									);
								}}
							/>
						</form>
					</div>
				</div>

				<AppWorkInspectionConfirmDeleteFindingModal ref={confirmDeleteFindingRef} onConfirm={onConfirmDeleteFinding} />

				<AppWorkInspectionHoldOnModal ref={holdOnModalRef} onConfirm={props.onHandleLeaveFindingDetails} />
			</div>
		</FormikProvider>
	);
};

export default memo(forwardRef(AppFindingDetails));
