import React, { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ENDPOINT_PATH from "constants/end-point-path";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";

import closeIcon from "assets/images/close-icon.png";
import searchIcon from "assets/images/search-icon.png";

const AppAddExistingAssetsModal = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState(convertPaginationTableData());
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const siteId = useMemo(() => props.siteId, [props.siteId]);
	const onHandleGetDetails = useMemo(() => props.onHandleGetDetails, [props.onHandleGetDetails]);
	const queryParams = useRef({ page: 0, keyword: "", sort: "" });

	const formik = useFormik({
		initialValues: { keyword: "", assets: [] },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		const nextWIRAssets = values.assets.map((o) => {
			if (!o.customerContractAssetId) {
				return {
					customerContractAssetId: o.id,
					customerAssetName: o.customerAssetName,
					assetName: o.assetName,
					workspace: o.workspace,
					assetType: o.assetType,
					deleted: false
				};
			}

			return o;
		});

		try {
			if (isCreate) {
				props.onHandleUpdateWIRAssets(nextWIRAssets);
			}

			if (!isCreate) {
				let payload = {
					workInspectionId: id,
					customerContractSiteId: siteId,
					assets: nextWIRAssets
				};

				await api.post.workInspectionReport.assets(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Assets were added successfully!" }));

				onHandleGetDetails(id);
			}

			setVisible(false);
		}
	}, [isCreate, props, id, siteId, formik, dispatch, onHandleGetDetails]);

	//prettier-ignore
	const onHandleSelectAsset = useCallback((boolean, id) => {
		let nextWIRAssets = [...formik.values.assets];

		if (boolean) {
			let item = data.content.find((o) => o.id === id);

			nextWIRAssets = [...nextWIRAssets, item];
		} else {
			nextWIRAssets = nextWIRAssets.filter((o) => o.customerContractAssetId ? o.customerContractAssetId !== id : o.id !== id);
		}

		formik.setFieldValue("assets", nextWIRAssets);
	}, [formik, data]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = { ...queryParams.current, "customer-contract-site-id": siteId, size: 10 };

			response = await api.get.customerSiteMaintenance.assets(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [siteId]);

	//prettier-ignore
	const onHandleShow = useCallback((assets) => {
		formik.setFieldValue("assets", assets);

		onHandleGetList();

		setVisible(true);
	}, [formik, onHandleGetList]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "assetName",
			label: "Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "customerAssetName",
			label: "Customer Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "assetType",
			label: "AssetType",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "workspace",
			label: "Work Space",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "add",
			label: "Add",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tableAssetsId = data.content[rowIndex].id;
					const selected = formik.values.assets?.findIndex((o) => (o.customerContractAssetId ? o.customerContractAssetId === tableAssetsId : o.id === tableAssetsId)) > -1;

					return <AppCheckbox label="" onClick={(v) => onHandleSelectAsset(v, tableAssetsId)} value={selected} />;
				}
			}
		}
	], [data.content, formik.values.assets, onHandleSelectAsset]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data.page, data.totalElements, onHandleGetList]);

	useEffect(() => {
		if (siteId) onHandleGetList();
	}, [onHandleGetList, siteId]);

	useEffect(() => {
		return () => {
			if (siteId) cancelRequest(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.ASSETS);
		};
	}, [cancelRequest, siteId]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow
	}));

	return (
		<Modal classes={{ root: "app-add-existing-assets-modal" }} open={visible}>
			<div className="add-existing-assets-modal">
				<button type="button" className="add-existing-assets-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<div className="add-existing-assets-modal__title">Add Assets</div>

				<div className="add-existing-assets-modal__description">Select Assets from the Site</div>

				<form className="add-existing-assets-modal__form" onSubmit={formik.handleSubmit}>
					<div className="add-existing-assets-modal__header">
						<div className="add-existing-assets-modal__search-input">
							<AppInput type="text" name="keyword" placeholder="Search by Asset Name or Customer Asset Name" endIcon={searchIcon} iconPosition="end" defaultValue={queryParams.current.keyword} onChange={onHandleDebounceSearch} onBlur={() => {}} />
						</div>
					</div>

					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />

					<div className="add-existing-assets-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Add" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppAddExistingAssetsModal));

AppAddExistingAssetsModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
