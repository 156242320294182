import React, { useEffect, useMemo, useState } from "react";

import AppTable from "components/app-table/app-table";

const AppWorkOrder = () => {
	const [data, setData] = useState([]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
        {
            name: "paginationNumbers",
            label: "#",
            options: {
                sort: true,
                sortThirdClickReset: true
            }
        },
        {
            name: "assetName",
            label: "Asset Name",
            options: {
                sort: true,
                sortThirdClickReset: true
            }
        },
        {
            name: "customerAssetName",
            label: "Customer Asset Name",
            options: {
                sort: true,
                sortThirdClickReset: true
            }
        },
        {
            name: "workOrderId",
            label: "Work Order ID",
            options: {
                sort: true,
                sortThirdClickReset: true
            }
        },
        {
            name: "type",
            label: "Type",
            options: {
                sort: true,
                sortThirdClickReset: true
            }
        }
    ], []);

	useEffect(() => {
		setData([]);
	}, []);

	return (
		<div className="app-work-order">
			<div className="work-order">
				<div className="work-order__container">
					<h1 className="work-order__label">Work Orders</h1>

					<div className="work-order__body">
						<AppTable data={data} columns={tableColumns} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppWorkOrder;
