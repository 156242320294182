import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import classNames from "common/class-names";
import formatTime from "common/format-time";
import getTotalDays from "common/get-total-days";
import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

const AppOpenEnquiriesTable = (props) => {
	const navigate = useNavigate();
	const profile = useSelector((state) => state.profile);
	const isMounted = useRef(true);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_MAINTENANCE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const defaultStatus = useMemo(() => [STATUS.OPEN, STATUS.IN_PROGRESS].join(","), []);
	// prettier-ignore
	const status = useMemo(() => {
		const currentStatuses = searchParams.get("status")?.split(",");

		const relevantStatus = currentStatuses?.every(e => defaultStatus.split(",").includes(e));

		if (!relevantStatus) {
			return defaultStatus;
		} else {
			return searchParams.get("status");
		}
	}, [defaultStatus, searchParams]);
	const startDate = useMemo(() => {
		let tab = searchParams.get("tab");

		if (tab !== STATUS.OPEN) {
			return "";
		} else {
			return searchParams.get("start-date");
		}
	}, [searchParams]);
	const endDate = useMemo(() => {
		let tab = searchParams.get("tab");

		if (tab !== STATUS.OPEN) {
			return "";
		} else {
			return searchParams.get("end-date");
		}
	}, [searchParams]);
	// prettier-ignore
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", "start-date": startDate, "end-date": endDate, status: status, sort: searchParams.get("sort") || "", tab: "OPEN", });
	const [data, setData] = useState(convertPaginationTableData());

	const validationSchema = yup.object({
		endDate: yup.date().test(function (value, { parent }) {
			if (!value) return true;

			const totalDays = getTotalDays(parent.startDate, value);

			return totalDays >= 0 && totalDays <= 90;
		})
	});

	const formik = useFormik({ initialValues: { startDate: "", endDate: "", status: [STATUS.OPEN, STATUS.IN_PROGRESS] }, validationSchema: validationSchema, onSubmit: (values) => onHandleSubmit(values) });

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	/* prettier-ignore */
	const intructionClassName = useMemo(() => classNames({
		"open-enquiries-table-filter__instruction": true,
		"open-enquiries-table-filter__instruction--error": formik.errors.endDate,
	}), [formik.errors.endDate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Open", name: STATUS.OPEN },
			{ label: "In-progress", name: STATUS.IN_PROGRESS }
		];

		return data;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
		queryParams.current["start-date"] && formik.setFieldValue("startDate", dayjs(queryParams.current["start-date"]));
		queryParams.current["end-date"] && formik.setFieldValue("endDate", dayjs(queryParams.current["end-date"]));
	}, [formik]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.customerEnquiry.openEnquiries(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	// prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	// prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");

		if (values.startDate && dayjs(values.startDate).isValid()) {
			queryParams.current["start-date"] = dayjs(values.startDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS);
		}

		if (values.endDate && dayjs(values.endDate).isValid()) {
			queryParams.current["end-date"] = dayjs(values.endDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS);
		}

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;
		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";

		onHandleGetList();
	}, [defaultStatus, formik, onHandleGetList]);

	// prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];
		
		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	// prettier-ignore
	const onHandleEditOpenEnquiry = useCallback((tableMeta) => {
		const id = data.content[tableMeta.rowIndex]?.id;

		navigate(pathnames.customer.enquiriesCreateEdit + id);
	}, [data, navigate]);

	const ResponseTime = useCallback((obj) => {
		const responseTimeClass = classNames({
			"table__response-time": true,
			"table__response-time--red": obj.value > obj.configurationData?.firstResponseGoalTime,
			"table__response-time--green": obj.value < obj.configurationData?.firstResponseGoalTime
		});

		return <div className={responseTimeClass}>{formatTime(obj.value, "minutes", "HH [Hours] mm [Minutes]")}</div>;
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "referenceNo",
			label: "Enquiry ID",
			options: {
				sort: false
			}
		},
		{
			name: "customerPicContact.picName",
			label: "Customer Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "subject",
			label: "Subject",
			options: {
				sort: false
			}
		},
		{
			name: "createdDate",
			label: "Date & Time",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY_HH_MM_A)
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "firstTimeResponseDuration",
			label: "First Response Time",
			options: {
				sort: false,
				customBodyRender: (value) => <ResponseTime value={value} configurationData={props.configurationData} />
			}
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (restricted) return;

					return (
						<button type="button" className="table__action" onClick={() => onHandleEditOpenEnquiry(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleEditOpenEnquiry, props.configurationData, restricted]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess:".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		},
	}), [data, onHandleGetList]);

	useEffect(() => {
		if (isMounted.current) {
			isMounted.current = false;

			let tab = searchParams.get("tab");
			let status = searchParams.get("status");
			let startDate = dayjs(searchParams.get("start-date"));
			let endDate = dayjs(searchParams.get("end-date"));

			const nextFormValues = {};

			if (status) {
				if (tab !== STATUS.OPEN) {
					const currentStatuses = searchParams.get("status")?.split(",");

					const relevantStatus = currentStatuses?.every((e) => defaultStatus.split(",").includes(e));

					if (!relevantStatus) {
						status = defaultStatus.split(",");
					} else {
						status = status.split(",");
					}
				} else {
					status = defaultStatus.split(",");
				}

				nextFormValues.status = status;
			}

			if (startDate && dayjs(startDate).isValid()) {
				if (tab !== STATUS.OPEN) {
					nextFormValues.startDate = "";
				} else {
					nextFormValues.startDate = startDate;
				}
			}

			if (endDate && dayjs(endDate).isValid()) {
				if (tab !== STATUS.OPEN) {
					nextFormValues.endDate = "";
				} else {
					nextFormValues.endDate = endDate;
				}
			}

			if (Object.keys(nextFormValues).length) {
				memoSetFormValues(nextFormValues);
			}
		}
	}, [defaultStatus, isMounted, memoSetFormValues, searchParams]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.CUSTOMER_ENQUIRY.OPEN_ENQUIRIES);
		};
	}, [cancelRequest]);

	return (
		<div className="app-open-enquiries-table">
			<div className="open-enquiries-table">
				<AppTableFilterHeader restricted={restricted} formik={{ ...formik }} searchPlaceholder="Search by Enquiry ID or Customer Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="open-enquiries-table-filter">
						<div className="open-enquiries-table-filter__container">
							<div className="open-enquiries-table-filter__inputs">
								<AppInputDate name="startDate" label="Created Date" value={formik.values.startDate} onChange={formik.setFieldValue} placeholder="Select start date" />

								<AppInputDate name="endDate" value={formik.values.endDate} onChange={formik.setFieldValue} placeholder="Select end date" />
							</div>

							<p className={intructionClassName}>Please select a date or a date range of up to 90 days</p>
						</div>

						<div className="open-enquiries-table-filter__container">
							<p className="open-enquiries-table-filter__label">Status</p>

							<div className="open-enquiries-table-filter__checkbox-status">
								{menuFilterStatus.map((o) => {
									const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

									return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
								})}
							</div>
						</div>
					</div>
				</AppTableFilterHeader>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>
		</div>
	);
};

export default AppOpenEnquiriesTable;
