import React, { forwardRef, memo, useCallback, useMemo } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";
import getFrequencyListing from "services/get-frequency-listing";
import getWorkSpaceListing from "services/get-workspace-listing";
import getAssetTypeListing from "services/get-asset-type-listing";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppSelectInput from "components/app-select-input";

const AppCustomerEditAssetDetails = (props, ref) => {
	const profile = useSelector((state) => state.profile);
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_CONTRACT], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const assetDetails = useMemo(() => props.assetDetails, [props.assetDetails]);

	const initialValues = useMemo(() => {
		let values = {
			search: "",
			id: "",
			referenceNo: "",
			customerContractSiteId: "",
			createdDate: "",
			customerAssetName: "",
			description: "",
			assetName: "",
			assetTypeId: "",
			remarks: "",
			frequency: "",
			lastService: "",
			workspaceId: "",
			serviceRemarks: "",
			firstService: "",
			completionTimeWindow: ""
		};

		if (assetDetails) {
			values = {
				...assetDetails,
				referenceNo: assetDetails.referenceNo || "",
				remarks: assetDetails.remark,
				assetTypeId: assetDetails.assetType.id,
				workspaceId: assetDetails.workspace.id,
				createdDate: dayjs(assetDetails.createdDate),
				lastService: dayjs(assetDetails.lastService),
				firstService: dayjs(assetDetails.firstService),
				completionTimeWindow: assetDetails.completionTimeWindow || ""
			};
		}

		return values;
	}, [assetDetails]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: yup.object({
			customerContractSiteId: yup.string().required(ERRORS.REQUIRED),
			customerAssetName: yup.string().required(ERRORS.REQUIRED),
			description: yup.string().required(ERRORS.REQUIRED),
			assetName: yup.string().required(ERRORS.REQUIRED),
			assetTypeId: yup.string().required(ERRORS.REQUIRED),
			remarks: yup.string().required(ERRORS.REQUIRED),
			frequency: yup.string().required(ERRORS.REQUIRED),
			lastService: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			workspaceId: yup.string().required(ERRORS.REQUIRED),
			firstService: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			completionTimeWindow: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const breadCrumb = useMemo(() => {
		return [
			{ label: "Customer Listing ", path: pathnames.customer.customers },
			{ label: "Edit Site ", path: pathnames.customer.customerCreateEditSite + formik.values.customerContractSiteId },
			{ label: "Edit Asset", path: pathnames.customer.customerEditAsset + id }
		];
	}, [id, formik.values.customerContractSiteId]);

	useBreadcrumb({ breadCrumb });

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				id: id,
				customerContractSiteId: values.customerContractSiteId,
				customerAssetName: values.customerAssetName,
				description: values.description,
				assetName: values.assetName,
				assetTypeId: values.assetTypeId,
				remarks: values.remarks,
				frequency: values.frequency,
				lastService: values.lastService,
				workspaceId: values.workspaceId,
				firstService: values.firstService,
				completionTimeWindow: values.completionTimeWindow
			};

			await api.post.assetMaintenance.updateAsset({ "asset-id": id, ...payload });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Asset was updated successfully!" }));

			props.onHandleGetDetails(id);
		}
	}, [dispatch, formik, id, props]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.customer.customerCreateEditSite + formik.values.customerContractSiteId);
	}, [navigate, formik]);

	return (
		<div className="app-customer-edit-asset-details">
			<div className="customer-edit-asset-details">
				<form className="customer-edit-asset-details__form" onSubmit={formik.handleSubmit}>
					<div className="customer-edit-asset-details__container">
						<p className="customer-edit-asset-details__label">Asset Details</p>

						<div className="customer-edit-asset-details__row">
							<AppInput disabled type="text" name="referenceNo" label="Asset ID" placeholder="Asset ID" value={formik.values.referenceNo} error={formik.errors.referenceNo} touched={formik.touched.referenceNo} onChange={formik.handleChange} />

							<AppInputDate disabled name="createdDate" label="Date Added" value={formik.values.createdDate} />
						</div>

						<div className="customer-edit-asset-details__row">
							<div className="customer-edit-asset-details__column">
								<AppInput required type="text" name="customerAssetName" disabled={restricted} label="Customer Asset Name" placeholder="Customer Asset Name" value={formik.values.customerAssetName} error={formik.errors.customerAssetName} touched={formik.touched.customerAssetName} onChange={formik.handleChange} />

								<AppInput required type="text" name="assetName" disabled={restricted} label="Asset Name" placeholder="Enter Asset Name" value={formik.values.assetName} error={formik.errors.assetName} touched={formik.touched.assetName} onChange={formik.handleChange} />
							</div>

							<AppInput multiline type="textarea" disabled={restricted} label="Description" maxLength={500} name="description" placeholder="Enter Description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />
						</div>

						<div className="customer-edit-asset-details__row">
							<div className="customer-edit-asset-details__column">
								<AppSelectInput required pagination name="assetTypeId" disabled={restricted} label="Asset Type" placeholder="Select..." loadOptions={getAssetTypeListing} value={formik.values.assetTypeId} error={formik.errors.assetTypeId} touched={formik.touched.assetTypeId} onChange={formik.handleChange} />

								<AppSelectInput required name="frequency" disabled={restricted} label="Frequency" placeholder="Select..." loadOptions={getFrequencyListing} value={formik.values.frequency} error={formik.errors.frequency} touched={formik.touched.frequency} onChange={formik.handleChange} />
							</div>

							<AppInput multiline type="textarea" disabled={restricted} label="Remarks" maxLength={500} name="remarks" placeholder="Enter Remarks" value={formik.values.remarks} error={formik.errors.remarks} touched={formik.touched.remarks} onChange={formik.handleChange} />
						</div>

						<div className="customer-edit-asset-details__row customer-edit-asset-details__row--divider">
							<AppInputDate required name="lastService" disabled={restricted} label="Last Service" value={formik.values.lastService} error={formik.errors.lastService} touched={formik.touched.lastService} onChange={formik.setFieldValue} placeholder="DD/MM/YYYY" />

							<AppSelectInput required pagination name="workspaceId" disabled={restricted} label="Workplace" placeholder="Select..." loadOptions={getWorkSpaceListing} value={formik.values.workspaceId} error={formik.errors.workspaceId} touched={formik.touched.workspaceId} onChange={formik.handleChange} />
						</div>

						<p className="customer-edit-asset-details__label">Work Order Configuration</p>

						<div className="customer-edit-asset-details__row">
							<AppInputDate required name="firstService" disabled={restricted} label="First Service" value={formik.values.firstService} error={formik.errors.firstService} touched={formik.touched.firstService} onChange={formik.setFieldValue} placeholder="DD/MM/YYYY" />

							<AppInput required type="number" name="completionTimeWindow" disabled={restricted} label="Completion Time Window" placeholder="Input a No. of days e.g: 5" value={formik.values.completionTimeWindow} error={formik.errors.completionTimeWindow} touched={formik.touched.completionTimeWindow} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="customer-edit-asset-details__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton type="submit" label="Update" disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default memo(forwardRef(AppCustomerEditAssetDetails));
