import React, { useCallback, useMemo, useRef, useState } from "react";

import Menu from "@mui/material/Menu";
import { useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";

import useBeforeUnload from "hooks/use-before-unload";

import PAGE from "constants/page";

import AppTabs from "components/app-tabs";
import AppButton from "components/app-button";
import AppWorkOrderServices from "components/pages/work-order/app-work-order-services";
import AppWorkOrderInventory from "components/pages/work-order/app-work-order-inventory";
import AppWorkOrderSiteDetails from "components/pages/work-order/app-work-order-site-details";
import AppWorkOrderWorkDetails from "components/pages/work-order/app-work-order-work-details";

import linkIcon from "assets/images/link-icon.svg";
import exportIcon from "assets/images/export-icon.png";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";
import servicesIcon from "assets/images/pages/work-order/services-icon.svg";
import inventoryIcon from "assets/images/pages/work-order/inventory-icon.svg";
import siteDetailsIcon from "assets/images/pages/work-order/site-details-icon.svg";
import workDetailsIcon from "assets/images/pages/work-order/work-details-icon.svg";
import clientAppLinkIcon from "assets/images/pages/work-order/client-app-link-icon.svg";
import servicesActiveIcon from "assets/images/pages/work-order/services-active-icon.svg";
import inventoryActiveIcon from "assets/images/pages/work-order/inventory-active-icon.svg";
import siteDetailsActiveIcon from "assets/images/pages/work-order/site-details-active-icon.svg";
import workDetailsActiveIcon from "assets/images/pages/work-order/work-details-active-icon.svg";

const PageWorkOrderCreateEdit = () => {
	useBeforeUnload();
	const { id } = useParams();
	const tabRef = useRef();
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [states, setStates] = useState({ siteDetails: null, services: null, Inventory: null, workDetails: null });
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Create Work Order" : "Edit Work Order"), [isCreate]);

	//prettier-ignore
	const tabs = useMemo(() => {
		const onHandleUpdateSiteDetails = (values) => {
			setStates((prev) => ({ ...prev, siteDetails: values }));
	
			if (values) tabRef.current.onHandleSelectTab(data[1]);
		};

		const onHandleUpdateServices = (values) => {
			setStates((prev) => ({ ...prev, services: values }));
	
			if (values && !isCreate) tabRef.current.onHandleSelectTab(data[1]);
		};

		const onHandleUpdateInventory = (values) => {
			setStates((prev) => ({ ...prev, inventory: values }));
	
			if (values && !isCreate) tabRef.current.onHandleSelectTab(data[1]);
		};

		const onHandleUpdateWorkDetails = (values) => {
			setStates((prev) => ({ ...prev, workDetails: values }));
	
			if (values && !isCreate) tabRef.current.onHandleSelectTab(data[1]);
		};

		const data = [
			{ label: "Site Details", name: "SITE_DETAILS", icon: siteDetailsIcon, activeIcon: siteDetailsActiveIcon, component: <AppWorkOrderSiteDetails values={states.siteDetails} onHandleTabSubmit={onHandleUpdateSiteDetails} />, accessible: true },
			{ label: "Services", name: "SERVICES", icon: servicesIcon, activeIcon: servicesActiveIcon, component: <AppWorkOrderServices values={states.services} onHandleTabSubmit={onHandleUpdateServices} />, accessible: true },
			{ label: "Inventory", name: "INVENTORY", icon: inventoryIcon, activeIcon: inventoryActiveIcon, component: <AppWorkOrderInventory values={states.inventory} onHandleTabSubmit={onHandleUpdateInventory} />, accessible: true },
			{ label: "Work Details", name: "WORK_DETAILS", icon: workDetailsIcon, activeIcon: workDetailsActiveIcon, component: <AppWorkOrderWorkDetails values={states.workDetails} onHandleTabSubmit={onHandleUpdateWorkDetails} />, accessible: true },
		];
		
		return data;
	}, [states.siteDetails, states.services, states.inventory, states.workDetails, isCreate]);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	//prettier-ignore
	const HeaderLinkButton = useCallback((obj) => {
		if (!obj.isCreate)
			return (
				<div className="work-order-create-edit__header-button">
					<AppButton className="work-order-create-edit__action-button" type="button" label="Generate Link" icon={chevronIcon}  onClick={onToggleFilterMenu} />

					<AppButton type="button" label="Export as PDF" icon={exportIcon} />
				</div>
			);
	}, [onToggleFilterMenu]);

	return (
		<div className="page-work-order-create-edit">
			<div className="work-order-create-edit">
				<div className="work-order-create-edit__header">
					<h1 className="work-order-create-edit__title">{title}</h1>

					<HeaderLinkButton isCreate={isCreate} />
				</div>

				<div className="work-order-create-edit__body">
					{!isCreate && (
						<p className="work-order-create-edit__last-update">
							<span>Last Updated By</span> Ahmad Muhammad Ali, 03/11/2023, 10:00 am
						</p>
					)}

					<AppTabs ref={tabRef} tabs={tabs} />
				</div>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "work-order-create-edit-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<div className="menu">
					<MenuItem className="menu__item"><img src={clientAppLinkIcon} alt="client-app-link-icon" />Client App Link</MenuItem>

					<MenuItem className="menu__item"><img src={linkIcon} alt="link-icon" />Browse Link</MenuItem>
				</div>
			</Menu>
		</div>
	);
};

export default PageWorkOrderCreateEdit;
