import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";

import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppCustomerEditAssetDeleteModal from "components/pages/customer/app-customer-edit-asset-delete-modal";
import AppCustomerEditAssetReorderModal from "components/pages/customer/app-customer-edit-asset-reorder-modal";
import AppCustomerEditAssetAddServiceModal from "components/pages/customer/app-customer-edit-asset-add-service-modal";
import AppCustomerEditAssetCreateEditServiceModal from "components/pages/customer/app-customer-edit-asset-create-edit-service-modal";
import AppCustomerEditAssetAddServiceChecklistModal from "components/pages/customer/app-customer-edit-asset-add-service-checklist-modal";

import editIcon from "assets/images/edit-icon.png";
import trashIcon from "assets/images/trash-icon.png";
import addIcon from "assets/images/blue-add-icon.svg";
import chevronUpIcon from "assets/images/chevron-up-icon.png";
import reorderingIcon from "assets/images/reordering-icon.svg";
import chevronDownGreyIcon from "assets/images/chevron-down-grey-icon.png";
import verticalBreadCrumbsIcon from "assets/images/vertical-breadcrumbs-icon.png";

const AppCustomerEditAssetServices = (props, ref) => {
	const profile = useSelector((state) => state.profile);
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const selectedItem = useRef();
	const addServicesModal = useRef();
	const createEditServiceModalRef = useRef();
	const addServiceChecklistModalRef = useRef();
	const reorderServiceLinesModalRef = useRef();
	const deleteServiceListingModalRef = useRef();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [serviceLineData, setServiceLineData] = useState(convertPaginationTableData());
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedRowItem, setSelectedRowItem] = useState(null);
	const [inventoryTableAnchor, setInventoryTableAnchor] = useState(null);
	const memoSearchParams = useRef(setSearchParams);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", sort: searchParams.get("sort") || "", status: searchParams.get("status"), tab: "ASSET_SERVICES" });
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_CONTRACT], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.assetMaintenance.assetServices({ "asset-id": id, ...params });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setSelectedRowItem(null);

			setServiceLineData(obj);
		}
	}, [id]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.customer.customerCreateEditSite + props.customerContractSiteId);
	}, [navigate, props.customerContractSiteId]);

	//prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedItem.current = { ...serviceLineData.content[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		setInventoryTableAnchor(event.currentTarget);
	}, [serviceLineData]);

	const onHandleAddChecklist = () => {
		addServiceChecklistModalRef.current.onHandleShow();
	};

	const onHandleReorderServiceLines = useCallback(() => {
		reorderServiceLinesModalRef.current.onHandleShow();
	}, []);

	const onHandleAddServices = () => {
		addServicesModal.current.onHandleShow();
	};

	const onHandleCreateService = useCallback(() => {
		createEditServiceModalRef.current.onHandleShow(null, serviceLineData);
	}, [serviceLineData]);

	const onHandleShowEditService = useCallback(() => {
		setInventoryTableAnchor(null);

		createEditServiceModalRef.current.onHandleShow(selectedItem.current);
	}, []);

	const onHandleCloseInventoryTableMenu = useCallback(() => {
		selectedItem.current = null;

		setInventoryTableAnchor(null);
	}, []);

	const onHandleConfirmDeleteServiceListing = useCallback(() => {
		const selectedData = selectedItem.current;

		setInventoryTableAnchor(null);

		deleteServiceListingModalRef.current.onHandleShow(selectedData);
	}, []);

	const onHandleDeleteServiceListing = useCallback(async () => {
		let response = null;

		try {
			const payload = { "asset-id": id, "service-id": selectedItem.current.id, "status-update": STATUS.RETIRED };

			await api.post.assetMaintenance.updateAssetServiceStatus(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Service was updated successfully!" }));

			onHandleGetList();
		}
	}, [dispatch, id, onHandleGetList]);

	// prettier-ignore
	const onHandleAddServiceChecklist = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { "asset-id": id, serviceChecklistIds: { serviceChecklistIds: values } };

			await api.post.assetMaintenance.addAssetServiceChecklist(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Service was added successfully!" }));

			onHandleGetList();

			addServiceChecklistModalRef.current.onHandleGetExclusiveChecklist();
		}
	}, [dispatch, id, onHandleGetList]);

	// prettier-ignore
	const onHandleAddService = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { "asset-id": id, assetServiceIds: { assetServiceIds: values } };

			await api.post.assetMaintenance.addAssetServices(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Service was added successfully!" }));

			onHandleGetList();

			addServicesModal.current.onHandleGetExclusiveServiceList();
		}
	}, [dispatch, id, onHandleGetList]);

	// prettier-ignore
	const onHandleReorderItem = useCallback(async (values) => {
		let response = null;
		const checkLists = [...values].map((o) => ({ id: o.id, seq: o.seq }));
		const payload = { "asset-id": id, checkLists: { checkLists } };

		try {
			await api.post.assetMaintenance.assetServicesReorder(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Service Checklist was reordered successfully!" }));

			onHandleGetList();
		}
	}, [id, dispatch, onHandleGetList]);

	// prettier-ignore
	const onHandleCellSelect = useCallback((cell, cellData) => {
		if (cell?.type) return;

		const item = serviceLineData.content[cellData.rowIndex];

		if (item) setSelectedRowItem(item);
	}, [serviceLineData]);

	// prettier-ignore
	const onHandleSwitchService = useCallback((value) => {
		const currentRowNumber = selectedRowItem?.paginationNumbers;
		const selectedItemList = serviceLineData.content.filter((o) => o.paginationNumbers === currentRowNumber + value);

		if (selectedItemList.length) setSelectedRowItem(selectedItemList[0]);
	}, [selectedRowItem, serviceLineData.content]);

	// prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "serviceAction",
			label: "Service Action",
			options: {
				sort: false
			}
		},
		{
			name: "serviceType",
			label: "Service Type",
			options: {
				sort: false
			}
		},
		{
			name: "input.label",
			label: "Input",
			options: {
				sort: false
			}
		},
		{
			name: "edit",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (restricted) return;

					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={verticalBreadCrumbsIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onToggleTableAction, restricted]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		enableNestedDataAccess: ".",
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		count: serviceLineData.totalElements,
		page: serviceLineData.page,
		serverSide: true,
		onCellClick: onHandleCellSelect,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [serviceLineData, onHandleCellSelect, onHandleGetList]);

	// prettier-ignore
	const SelectedItemDetails = useCallback((obj) => {
		if (obj.selectedRowItem?.subtasks) {
			const reorderedSubtasks = obj.selectedRowItem.subtasks.sort((a, b) => a.seq - b.seq);

			obj.selectedRowItem.subtasks = reorderedSubtasks;
		}

		return (
			<div className="customer-edit-asset-services__item-table">
				<div className="item-table">
					<div className="item-table__content">
						<div className="item-table__item">
							<p className="item-table__label">Service Action</p>

							<p className="item-table__value">{obj.selectedRowItem?.serviceAction || "Select an Item from the table to see the detailed view here"}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Service Input</p>

							<p className="item-table__value">{obj.selectedRowItem?.input?.label}</p>
						</div>

						<div className="item-table__item item-table__item--subtasks">
							<p className="item-table__label">#</p>

							<p className="item-table__label item-table__label--subtasks">Sub-Tasks</p>

							<p className="item-table__label item-table__label--subtasks">Subtask Input</p>
						</div>

						{obj.selectedRowItem?.subtasks?.map((o) => {
							return (
								<div key={o.id} className="item-table__item item-table__item--subtasks">
									<p className="item-table__label">{o.seq}</p>

									<p className="item-table__label item-table__label--subtasks">{o.subtaskAction}</p>

									<p className="item-table__label item-table__label--subtasks">{o.input?.label}</p>
								</div>
							);
						})}
					</div>

					<div className="item-table__switch-service">
						<AppButton label="" type="button" icon={chevronUpIcon} onClick={() => onHandleSwitchService(-1)} />

						<AppButton label="" type="button" icon={chevronDownGreyIcon} onClick={() => onHandleSwitchService(1)} />
					</div>
				</div>
			</div>
		);
	}, [onHandleSwitchService]);

	useEffect(() => {
		onHandleGetList();

		return () => {
			if (id) {
				cancelRequest(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_SERVICES);
			}
		};
	}, [id, cancelRequest, onHandleGetList]);

	return (
		<div className="app-customer-edit-asset-services">
			<div className="customer-edit-asset-services">
				<div className="customer-edit-asset-services__container">
					<div className="customer-edit-asset-services__row customer-edit-asset-services__row--divider">
						<div className="customer-edit-asset-services__column">
							<p className="customer-edit-asset-services__label customer-edit-asset-services__label--services">
								Add Service Checklists <span className="customer-edit-asset-services__required">*</span>
							</p>

							<AppButton outline type="button" disabled={restricted} label="Add Existing Checklist(s)" onClick={onHandleAddChecklist} />
						</div>

						<div className="customer-edit-asset-services__column">
							<p className="customer-edit-asset-services__label customer-edit-asset-services__label--services">
								Add Services <span className="customer-edit-asset-services__required">*</span>
							</p>

							<AppButton outline type="button" disabled={restricted} label="Add Existing Service(s)" onClick={onHandleAddServices} />

							<p className="customer-edit-asset-services__text">Or</p>

							<AppButton outline type="button" disabled={restricted} label="Add New Services" icon={addIcon} onClick={onHandleCreateService} />
						</div>
					</div>

					<p className="customer-edit-asset-services__label">Service Listing</p>

					<div className="customer-edit-asset-services__table-column customer-edit-asset-services__table-column--divider">
						<div className="customer-edit-asset-services__header">
							<AppTableFilterHeader searchPlaceholder="Search by Service ID or Service Name" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} />

							<div className="customer-edit-asset-services__header-button">
								<AppButton outline type="button" disabled={restricted} label="Reorder" icon={reorderingIcon} onClick={onHandleReorderServiceLines} />
							</div>
						</div>

						<AppTable data={serviceLineData.content} columns={tableColumns} options={tableOptions} />

						<SelectedItemDetails selectedRowItem={selectedRowItem} />
					</div>
				</div>

				<div className="customer-edit-asset-services__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />
				</div>
			</div>

			<Menu classes={{ root: "customer-edit-asset-services-table-menu" }} anchorEl={inventoryTableAnchor} open={!!inventoryTableAnchor} onClose={onHandleCloseInventoryTableMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleShowEditService}>
					<img src={editIcon} alt="services-edit" />
					Edit
				</MenuItem>

				<MenuItem onClick={onHandleConfirmDeleteServiceListing}>
					<img className="customer-edit-asset-services-table-menu__close-icon" src={trashIcon} alt="services-close" />
					Delete
				</MenuItem>
			</Menu>

			<AppCustomerEditAssetAddServiceModal ref={addServicesModal} onConfirm={onHandleAddService} />

			<AppCustomerEditAssetAddServiceChecklistModal ref={addServiceChecklistModalRef} onConfirm={onHandleAddServiceChecklist} />

			<AppCustomerEditAssetCreateEditServiceModal ref={createEditServiceModalRef} onHandleGetList={onHandleGetList} />

			<AppCustomerEditAssetDeleteModal ref={deleteServiceListingModalRef} onConfirm={onHandleDeleteServiceListing} />

			<AppCustomerEditAssetReorderModal ref={reorderServiceLinesModalRef} onConfirm={onHandleReorderItem} />
		</div>
	);
};

export default AppCustomerEditAssetServices;
