import React, { useEffect, useMemo, useState } from "react";

import STATUS from "constants/status";

import AppStatus from "components/app-status";
import AppCalendarSchedule from "components/app-calendar-schedule/app-calendar-schedule";

const dummyData = [
	{ date: "2024-09-01", clockIn: "11.00 a.m.", clockOut: "8.30 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ date: "2024-08-02", clockIn: "12.00 p.m.", clockOut: "9.00 p.m.", timeWorked: "08 Hours and 10 Minutes", status: "" },
	{ date: "2024-10-02", clockIn: "13.00 p.m.", clockOut: "10.00 p.m.", timeWorked: "08 Hours and 11 Minutes", status: "" },
	{ date: "2024-07-02", clockIn: "14.00 p.m.", clockOut: "11.00 p.m.", timeWorked: "08 Hours and 21 Minutes", status: "" },
	{ date: "2024-08-09", clockIn: "", clockOut: "", timeWorked: "", status: STATUS.ON_LEAVE }
];

const AppAttendanceSummaryCalendar = () => {
	const [data, setData] = useState(dummyData);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "clockIn",
			label: "Clock-In",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;

					if (data[rowIndex]?.status === STATUS.ON_LEAVE) return;

					return value;
				}
			}
		},
		{
			name: "clockOut",
			label: "Clock-Out",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;

					if (data[rowIndex]?.status === STATUS.ON_LEAVE) return <AppStatus status={data[rowIndex]?.status} />;

					return value;
				}
			}
		},
		{
			name: "timeWorked",
			label: "Time Worked",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;

					if (data[rowIndex]?.status === STATUS.ON_LEAVE) return;

					return value;
				}
			}
		}
	], [data]);

	useEffect(() => {
		setData(dummyData);
	}, []);

	return (
		<div className="app-attendance-summary-calendar">
			<div className="attendance-summary-calendar">
				<AppCalendarSchedule data={data} columns={tableColumns} />
			</div>
		</div>
	);
};

export default AppAttendanceSummaryCalendar;
