import React, { useCallback, useMemo, useRef, useState } from "react";

import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppSiteDetailsCreateEditTeamMembersModal from "components/pages/work-order/app-site-details-create-edit-team-members-modal";

import deleteIcon from "assets/images/trash-icon.png";
import addBlueIcon from "assets/images/add-blue-icon.png";

const tempData = [{ paginationNumbers: 1, id: "MC-001", name: "Henry" }];

const AppSiteDetailsTeamMembersTable = () => {
	const teamMemberRef = useRef();
	const [data, setData] = useState(tempData);

	const onHandleAddTeamMember = useCallback(() => {
		teamMemberRef.current.onHandleShow();
	}, []);

	//prettier-ignore
	const onHandleGetList = useCallback((values) => {
		const nextItems = [...data, ...values];

		setData(nextItems);
	}, [data]);

	//prettier-ignore
	const onHandleDeleteTeamMember = useCallback((obj) => {
		let nextItems = [...data];
		nextItems = nextItems.filter((_, i) => i !== obj.rowIndex);
		
		setData(nextItems);
	}, [data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "id",
			label: "Employee ID",
			options: {
				sort: false
			}
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "remove",
			label: "Remove",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__delete" onClick={() => onHandleDeleteTeamMember(tableMeta)}>
							<img src={deleteIcon} alt="delete-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleDeleteTeamMember]);

	const emptyState = useMemo(() => {
		if (data.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text">
							No Team Members Added.
							<span className="table__link" onClick={onHandleAddTeamMember}>
								Add Team Member?
							</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [data.length, onHandleAddTeamMember, tableColumns.length]);

	return (
		<div className="app-site-details-team-members-table">
			<div className="site-details-team-members-table">
				<div className="site-details-team-members-table__header">
					<p className="site-details-team-members-table__label">Team Members</p>

					<div className="site-details-team-members-table__add-button">
						<AppButton outline type="button" label="Add Team Member" icon={addBlueIcon} onClick={onHandleAddTeamMember} />
					</div>
				</div>

				<AppTable data={data} columns={tableColumns} components={emptyState} />
			</div>

			<AppSiteDetailsCreateEditTeamMembersModal ref={teamMemberRef} onConfirm={onHandleGetList} />
		</div>
	);
};

export default AppSiteDetailsTeamMembersTable;
