import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { Modal } from "@mui/material";
import { useSelector } from "react-redux";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

const AppWorkOrderCreateEditInventoryModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.POSITION_LEVEL_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			quantity: "",
			inventoryItem: "",
			quantityNeeded: ""
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			inventoryItem: yup.string().required(ERRORS.REQUIRED),
			quantityNeeded: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isCreate = useMemo(() => !formik.values.id, [formik.values.id]);
	const title = useMemo(() => (isCreate ? "Add Inventory" : "Edit Inventory"), [isCreate]);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({ inventoryItem: obj?.inventoryItem || "", quantityNeeded: obj?.quantityNeeded || "", location: obj?.location || "", quantity: obj?.quantity || "" });

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	const onHandleSubmit = useCallback(async () => {}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-work-order-create-edit-inventory-modal" }} open={visible}>
			<div className="work-order-create-edit-inventory-modal">
				<h1 className="work-order-create-edit-inventory-modal__title">{title}</h1>

				<form className="work-order-create-edit-inventory-modal__form" onSubmit={formik.handleSubmit}>
					<AppSelectInput required searchable={false} disabled={restricted} name="inventoryItem" label="Inventory Item" placeholder="Search for Inventory Item" options={[]} value={formik.values.inventoryItem} error={formik.errors.inventoryItem} touched={formik.touched.inventoryItem} onChange={formik.handleChange} />

					<AppInput disabled type="text" name="location" label="Location" placeholder="" value={formik.values.location} touched={formik.touched.location} onChange={formik.handleChange} />

					<AppInput disabled type="number" name="quantity" label="Available Quantity" placeholder="" value={formik.values.quantity} touched={formik.touched.quantity} onChange={formik.handleChange} />

					<AppInput required type="number" disabled={restricted} name="quantityNeeded" label="Quantity Needed" placeholder="Enter Quantity Needed" value={formik.values.quantityNeeded} error={formik.errors.quantityNeeded} touched={formik.touched.quantityNeeded} onChange={formik.handleChange} />

					<div className="work-order-create-edit-inventory-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Add" disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppWorkOrderCreateEditInventoryModal));
