import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import useBreadcrumb from "hooks/use-breadcrumb";

import formatCurrencyPattern, { formatCurrency } from "common/format-currency-pattern";

import STATUS from "constants/status";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppTableFooter from "components/app-table/app-table-footer";
import AppDeliveryOrdersClosedModal from "components/pages/sales/app-delivery-orders-closed-modal";

import exportIcon from "assets/images/export-icon.png";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const tempData = [
	{
		paginationNumber: 1,
		title: "ABC",
		description: "As per ducting layout with compressed air blade with a minimum of 180 PSI of air pressure and 185 CFM of air velocity to break up debris, dust, clump, mold, and contamination inside the duct. *No brush or any mechanical method will be used to reduce the chance of CROSS-CONTAMINATION. *The Air-Knife Nozzle is able to traverse vertically to ensure that vertical risers will be properly cleaned as well.  *The system have an effectiveness range of a minimum of 100 ft, therefore only a minimum access doors will be required.",
		id: "ABC",
		serviceType: "ABC",
		quantity: "1000",
		price: "1000",
		unit: "1000",
		discount: "1",
		amount: "10000"
	}
];

const PageDeliveryOrdersViewEdit = () => {
	const { id } = useParams();
	const deliveryOrderClosedRef = useRef();
	const location = useLocation();
	const statusDeliveryOrder = useMemo(() => location.state, [location]);
	const [selectedOrder, setSelectedOrder] = useState([]);
	const [data, setData] = useState(tempData);
	const navigate = useNavigate();
	const [menuAnchor, setMenuAnchor] = useState(null);
	const isEdit = useMemo(() => statusDeliveryOrder === STATUS.DRAFT, [statusDeliveryOrder]);
	const isClosed = useMemo(() => statusDeliveryOrder === STATUS.COMPLETED || statusDeliveryOrder === STATUS.CANCELLED, [statusDeliveryOrder]);
	const title = useMemo(() => (isEdit ? "Edit Delivery Order" : "View Delivery Order"), [isEdit]);
	const [selectedRowItem, setSelectedRowItem] = useState(null);

	const breadCrumb = useMemo(() => {
		const data = [{ label: "Sales", path: pathnames.sales.quotations }, { label: "Delivery Orders", path: pathnames.sales.deliveryOrders }];

		if (isEdit) {
			data.push({ label: "Edit Delivery Order " + id, path: pathnames.sales.deliveryOrdersViewEdit + id });
		} else {
			data.push({ label: "View Delivery Order" + id, path: pathnames.sales.deliveryOrdersViewEdit + id });
		}

		return data;
	}, [isEdit, id]);

	useBreadcrumb({ breadCrumb });

	const initialValues = useMemo(() => {
		const values = {
			salesOrderId: "",
			deliveryOrderId: "",
			status: statusDeliveryOrder,
			customerName: "",
			picName: "",
			email: "",
			terms: "",
			quantity: "",
			mobileNo: "",
			prefixNo: "",
			addressLine1: "",
			addressLine2: "",
			state: "",
			city: "",
			postcode: "",
			deliveryOrderTitle: "",
			internalNote: "",
			remarks: "",
			subtotal: "",
			tax: "",
			taxPrice: "",
			discount: "",
			total: "",
			items: []
		};

		return values;
	}, [statusDeliveryOrder]);

	const onHandleSubmit = () => {};

	const onHandleSetComplete = () => {
		deliveryOrderClosedRef.current.onHandleShow("D0-1", STATUS.COMPLETED);

		setMenuAnchor(null);
	};

	const onHandleSetCancelled = () => {
		deliveryOrderClosedRef.current.onHandleShow("D0-1", STATUS.CANCELLED);

		setMenuAnchor(null);
	};

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: onHandleSubmit
	});

	const onToggleMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.sales.quotations);
	}, [navigate]);

	//prettier-ignore
	const onHandleCellSelect = useCallback((cell, cellData) => {
		if (cell?.type) return;

		const item = data[cellData.rowIndex];

		if (item) setSelectedRowItem(item);
	}, [data]);

	const onHandleSelectOrder = useCallback((boolean, idNo) => {
		setSelectedOrder((prev) => {
			let nextOrder = [...prev];

			if (boolean) {
				nextOrder.push(idNo);
			} else {
				nextOrder = nextOrder.filter((id) => id !== idNo);
			}

			return nextOrder;
		});
	}, []);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data.map((item) => item.id);

		setSelectedOrder(boolean ? ids : []);
	}, [data]);

	const tableColumns = useMemo(() => {
		const commonColumns = [
			{
				name: "title",
				label: "Title",
				options: {
					sort: true,
					sortThirdClickReset: true
				}
			},
			{
				name: "id",
				label: "Service ID",
				options: {
					sort: false
				}
			},
			{
				name: "quantity",
				label: "Quantity",
				options: {
					sort: true,
					sortThirdClickReset: true
				}
			},
			{
				name: "unit",
				label: "Unit",
				options: {
					sort: false
				}
			},
			{
				name: "price",
				label: "Price Per Unit (MYR)",
				options: {
					sort: true,
					sortThirdClickReset: true,
					setCellHeaderProps: () => ({ className: "table__price" }),
					setCellProps: () => ({ className: "table__price" }),
					customBodyRender: (value, tableMeta) => {
						return formatCurrency(value);
					}
				}
			},
			{
				name: "amount",
				label: "Amount (MYR)",
				options: {
					sort: true,
					sortThirdClickReset: true,
					setCellHeaderProps: () => ({ className: "table__price" }),
					setCellProps: () => ({ className: "table__price" }),
					customBodyRender: (value, tableMeta) => {
						return formatCurrency(value);
					}
				}
			}
		];

		if (!isEdit) {
			return [
				{
					name: "paginationNumber",
					label: "#",
					options: {
						sort: false
					}
				},
				...commonColumns
			];
		} else {
			return [
				{
					name: "checkbox",
					options: {
						sort: false,
						customHeadRender: () => {
							const selectedAll = data.length > 0 && selectedOrder.length === data.length;

							return (
								<TableCell key="table-key" className="app-table__cell app-table__cell--header">
									<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
								</TableCell>
							);
						},
						customBodyRender: (value, tableMeta) => {
							const rowIndex = tableMeta.rowIndex;
							const tablePendingID = data[rowIndex]?.id;
							const selected = selectedOrder.includes(tablePendingID);

							return (
								<Table>
									<TableBody>
										<TableRow>
											<TableCell className="app-table__cell">
												<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectOrder(v, tablePendingID)} value={selected} />
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							);
						}
					}
				},
				...commonColumns
			];
		}
	}, [data, isEdit, onHandleSelectAll, onHandleSelectOrder, selectedOrder]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		onCellClick: onHandleCellSelect,
		customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
			return <AppTableFooter count={count} page={page} rowsPerPage={rowsPerPage} changeRowsPerPage={changeRowsPerPage} changePage={changePage} textLabels={textLabels} />;
		}
	}), [onHandleCellSelect]);

	const SelectedItemDetails = useCallback((obj) => {
		if (!obj.selectedRowItem) return null;

		const firstItem = obj.selectedRowItem.number === 1;
		const lastItem = obj.items.length === obj.selectedRowItem.number;

		const onHandleNextItem = () => {
			setSelectedRowItem(obj.items[obj.selectedRowItem.number]);
		};

		const onHandlePrevItem = () => {
			setSelectedRowItem(obj.items[obj.selectedRowItem.number - 2]);
		};

		return (
			<div className="delivery-orders-view-edit__item-table">
				<div className="item-table">
					<div className="item-table__content">
						<div className="item-table__item">
							<p className="item-table__label">Title</p>

							<p className="item-table__value item-table__value--underline">{obj.selectedRowItem.title}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Service ID</p>

							<p className="item-table__value">{obj.selectedRowItem.id}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Service Type</p>

							<p className="item-table__value">{obj.selectedRowItem.serviceType}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Description</p>

							<p className="item-table__value">{obj.selectedRowItem.description}</p>
						</div>
					</div>

					<div className="item-table__pagination">
						<button type="button" className="item-table__button item-table__button--prev" disabled={firstItem} onClick={onHandlePrevItem}>
							<img src={chevronIcon} alt="pagination-prev" />
						</button>

						<p className="item-table__page">
							item of {obj.selectedRowItem.number} of {obj.items.length}
						</p>

						<button type="button" className="item-table__button item-table__button--next" disabled={lastItem} onClick={onHandleNextItem}>
							<img src={chevronIcon} alt="pagination-next" />
						</button>
					</div>
				</div>
			</div>
		);
	}, []);

	useEffect(() => {
		setData(tempData);
	}, []);

	return (
		<div className="page-delivery-orders-view-edit">
			<div className="delivery-orders-view-edit">
				<div className="delivery-orders-view-edit__header">
					<h1 className="delivery-orders-view-edit__title">{title}</h1>

					<div className="delivery-orders-view-edit__header delivery-orders-view-edit__header--column">
						<div className="delivery-orders-view-edit__header-buttons">
							{!isEdit && !isClosed && <AppButton outline className="delivery-orders-view-edit__action-button" type="button" label="Action" icon={chevronIcon} onClick={onToggleMenu} />}

							<AppButton outline type="button" label="Export as PDF" icon={exportIcon} onClick={() => {}} />
						</div>

						<p className="delivery-orders-view-edit__last-update">
							<span>Last Updated By</span> Ahmad Muhammad Ali, 03/11/2023, 10:00 am
						</p>
					</div>
				</div>

				<form className="delivery-orders-view-edit__form" onSubmit={formik.handleSubmit}>
					<div className="delivery-orders-view-edit__container">
						<div className="delivery-orders-view-edit__wrapper delivery-orders-view-edit__wrapper--divider">
							<p className="delivery-orders-view-edit__label">General Details</p>

							<div className="delivery-orders-view-edit__row">
								<AppInput disabled type="text" name="deliveryOrderId" label="Delivery Order ID" placeholder="Delivery Order ID" value={formik.values.deliveryOrderId} error={formik.errors.deliveryOrderId} touched={formik.touched.deliveryOrderId} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="status" label="Status" placeholder="Status" value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
							</div>

							<div className="delivery-orders-view-edit__row">
								<AppInput disabled type="text" name="salesOrderId" label="Sales Order ID" placeholder="Sales Order ID" value={formik.values.salesOrderId} error={formik.errors.salesOrderId} touched={formik.touched.salesOrderId} onChange={formik.handleChange} />
							</div>
						</div>

						<div className="delivery-orders-view-edit__wrapper">
							<p className="delivery-orders-view-edit__label">Customer Details</p>

							<div className="delivery-orders-view-edit__row">
								<AppInput disabled type="text" name="customerName" label="Customer Name" placeholder="Please input customer name" value={formik.values.customerName} error={formik.errors.customerName} touched={formik.touched.customerName} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="picName" label="PIC Name" placeholder="Please input PIC name" value={formik.values.picName} error={formik.errors.picName} touched={formik.touched.picName} onChange={formik.handleChange} />
							</div>

							<div className="delivery-orders-view-edit__row">
								<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

								<AppInput disabled type="text" name="email" label="Email" placeholder="Please input a No. of days" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
							</div>

							<div className="delivery-orders-view-edit__row">
								<AppInput disabled type="text" name="addressLine1" label="Address Line 1" placeholder="Please input address line 1" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="addressLine2" label="Address Line 2" placeholder="Please input address line 2" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />
							</div>

							<div className="delivery-orders-view-edit__row">
								<AppSelectInput disabled name="state" label="State" placeholder="Select..." options={[]} value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

								<AppSelectInput disabled name="city" label="City" placeholder="Select..." options={[]} value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={formik.handleChange} />
							</div>

							<div className="delivery-orders-view-edit__row">
								<AppSelectInput disabled name="postcode" label="Postcode" placeholder="Select..." options={[]} value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
							</div>
						</div>
					</div>

					<div className="delivery-orders-view-edit__container">
						<div className="delivery-orders-view-edit__wrapper">
							<AppInput required disabled={!isEdit} type="text" name="deliveryOrderTitle" label="Delivery Order Title" value={formik.values.deliveryOrderTitle} error={formik.errors.deliveryOrderTitle} touched={formik.touched.deliveryOrderTitle} onChange={formik.handleChange} />

							<p className="delivery-orders-view-edit__label">Item Lines {isEdit && <span>*</span>}</p>

							{isEdit && <p className="delivery-orders-view-edit__description">Please select minimum 1 item to create the Delivery Order.</p>}

							<AppTable data={data} columns={tableColumns} options={tableOptions} />

							<SelectedItemDetails selectedRowItem={selectedRowItem} items={data} />

							<div className="delivery-orders-view-edit__row">
								<AppInput disabled={!isEdit} multiline type="textarea" name="internalNote" label="Internal Note" placeholder="Enter Internal here" value={formik.values.internalNote} error={formik.errors.internalNote} touched={formik.touched.internalNote} onChange={formik.handleChange} />

								<div className="delivery-orders-view-edit__column">
									<AppInput disabled type="text" name="subtotal" label="Subtotal (MYR)" placeholder="For example: 100.00" value={formik.values.subtotal} error={formik.errors.subtotal} touched={formik.touched.subtotal} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

									<div className="delivery-orders-view-edit__column-row">
										<AppInput disabled type="number" maxLength={1} name="tax" label="Tax (%)" placeholder="Please input tax percent" value={formik.values.tax} error={formik.errors.tax} touched={formik.touched.tax} onChange={formik.handleChange} />

										<AppInput disabled type="text" name="taxPrice" value={formik.values.taxPrice} error={formik.errors.taxPrice} touched={formik.touched.taxPrice} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
									</div>
								</div>
							</div>

							<div className="delivery-orders-view-edit__row">
								<AppInput disabled multiline type="textarea" name="remarks" label="Customer Note" placeholder="Enter Note here" value={formik.values.remarks} error={formik.errors.remarks} touched={formik.touched.remarks} onChange={formik.handleChange} />

								<div className="delivery-orders-view-edit__column">
									<AppInput disabled type="text" name="discount" label="Discount (MYR)" placeholder="For example: 100.00" value={formik.values.discount} error={formik.errors.discount} touched={formik.touched.discount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

									<AppInput disabled type="text" name="total" label="Grand Total (MYR)" placeholder="For example: 100.00" value={formik.values.total} error={formik.errors.total} touched={formik.touched.total} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
								</div>
							</div>
						</div>
					</div>

					{isEdit && (
						<div className="delivery-orders-view-edit__button-container">
							<AppButton outline className="delivery-orders-view-edit__cancel-button" type="button" label="Cancel" onClick={onHandleBack} />

							{!isClosed && <AppButton outline type="button" label="Update Draft" onClick={() => {}} />}

							<AppButton type="submit" label="Create Delivery Order" disabled={formik.isSubmitting} />
						</div>
					)}
				</form>
			</div>

			<Menu classes={{ root: "delivery-orders-view-edit-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<div className="menu">
					<button className="menu__label" onClick={onHandleSetComplete}>
						Set As Completed
					</button>

					<button className="menu__label" onClick={onHandleSetCancelled}>
						Set As Cancelled
					</button>
				</div>
			</Menu>

			<AppDeliveryOrdersClosedModal ref={deliveryOrderClosedRef} />
		</div>
	);
};

export default PageDeliveryOrdersViewEdit;
