import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

const getWorkInspectionReportAssetListing = async (payload) => {
	let response = null;

	let errorMessage = null;

	try {
		const params = { "work-inspection-report-id": payload.id };

		const res = await api.get.workInspectionReport.assetListing(params);

		const content = res.map(({ customerAssetName, id, ...o }) => ({ label: customerAssetName, value: id, ...o }));

		response = { ...res, content };
	} catch (error) {
		serveLayoutRequestErrors(error, (message) => {
			errorMessage = message;
		});
	}

	if (response) return response;

	throw errorMessage;
};

export default getWorkInspectionReportAssetListing;
