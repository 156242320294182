import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import Menu from "@mui/material/Menu";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import { useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";

import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import DATE_TIME from "constants/date-time";
import HOLIDAY_TYPE from "constants/holiday-type";
import ENDPOINT_PATH from "constants/end-point-path";

import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppHrViewHolidaysDeleteModal from "components/pages/human-resources/app-hr-view-holidays-delete-modal";
import AppHolidaysCreateEditModal from "components/pages/human-resources/app-hr-view-holidays-create-edit-modal";

import editIcon from "assets/images/edit-icon.png";
import trashIcon from "assets/images/trash-icon.png";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";

const PageHrViewHolidays = () => {
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.LEAVE_MANAGEMENT], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const holidayRef = useRef();
	const deleteRef = useRef();
	const selectedItem = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const [holidayAnchor, setHolidayAnchor] = useState(null);
	const memoSearchParams = useRef(setSearchParams);
	const [data, setData] = useState(convertPaginationTableData());
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", sort: searchParams.get("sort") || "" });

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Leave Management", path: pathnames.humanResources.leaveManagement },
			{ label: "Configure Holidays", path: pathnames.humanResources.hrViewHolidays }
		];

		return data;
	}, []);

	useBreadcrumb({ breadCrumb });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = { ...queryParams.current, size: 10 };

			memoSearchParams.current(params);

			response = await api.get.humanResource.holidays(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}
		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleCloseHolidayTableMenu = useCallback(() => {
		selectedItem.current = null;

		setHolidayAnchor(null);
	}, []);

	//prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data.content[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		setHolidayAnchor(event.currentTarget);
	}, [data]);

	const onHandleEditHoliday = useCallback(() => {
		holidayRef.current.onHandleShow(selectedItem.current);

		setHolidayAnchor(null);
	}, []);

	const onHandleAddHoliday = useCallback(() => {
		holidayRef.current.onHandleShow();
	}, []);

	const onHandleDeleteHoliday = useCallback(() => {
		deleteRef.current.onHandleShow(selectedItem.current);

		setHolidayAnchor(null);
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "holidayDate",
			label: "Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					if (data.content[tableMeta.rowIndex].holidayType === HOLIDAY_TYPE.ONE_TIME) {
						return dayjs(value).format(DATE_TIME.DD_MM_YYYY);
					}

					return dayjs(value).format(DATE_TIME.DD_MM);
				}
			}
		},
		{
			name: "description",
			label: "Description",
			options: {
				sort: false
			}
		},
		{
			name: "edit",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (restricted) return;

					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={moreIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [data.content, onToggleTableAction, restricted]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.HOLIDAYS);
		};
	}, [cancelRequest]);

	return (
		<div className="page-hr-view-holidays">
			<div className="hr-view-holidays">
				<h1 className="hr-view-holidays__title">Holidays</h1>

				<AppTableFilterHeader restricted={restricted} searchPlaceholder="Search by Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} onHandleAdd={onHandleAddHoliday} />

				<div className="hr-view-holidays__body">
					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
				</div>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "hr-view-holidays-table-menu" }} anchorEl={holidayAnchor} open={!!holidayAnchor} onClose={onHandleCloseHolidayTableMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }} >
				<MenuItem onClick={onHandleEditHoliday}><img src={editIcon} alt="hr-view-holidays-edit" />Edit</MenuItem>

				<MenuItem onClick={onHandleDeleteHoliday}><img src={trashIcon} alt="hr-view-holidays-delete" />Delete</MenuItem>
			</Menu>

			<AppHolidaysCreateEditModal ref={holidayRef} onHandleGetList={onHandleGetList} />

			<AppHrViewHolidaysDeleteModal ref={deleteRef} onHandleGetList={onHandleGetList} />
		</div>
	);
};

export default PageHrViewHolidays;
