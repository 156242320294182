import { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import COMMON from "common";
import PropTypes from "prop-types";
import MuiDataTable from "mui-datatables";

import classNames from "common/class-names";
import getOrdinalNumber from "common/get-ordinal-number";

import AppButton from "components/app-button";
import AppCalendarScheduleFooter from "components/app-calendar-schedule/app-calendar-schedule-footer";

import editIcon from "assets/images/pages/human-resources/edit-icon.svg";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const AppCalendarSchedule = (props, ref) => {
	const formik = useMemo(() => props.formik, [props.formik]);
	const components = useMemo(() => props.components, [props.components]);
	const [monthTitle, setMonthTitle] = useState(new Date().getMonth());
	const [yearTitle, setYearTitle] = useState(new Date().getFullYear());
	const [editData, setEditData] = useState(false);
	const monthName = COMMON.MONTH_LIST[monthTitle];
	const monthlyItems = props.data.filter((item) => {
		const itemDate = new Date(item.date);

		return itemDate.getFullYear() === yearTitle && itemDate.getMonth() === monthTitle;
	});

	const handleNextPage = () => {
		const nextMonth = monthTitle + 1;

		updateMonthAndYear(nextMonth);
	};

	const handlePrevPage = () => {
		const prevMonth = monthTitle - 1;

		updateMonthAndYear(prevMonth);
	};

	const updateMonthAndYear = (month) => {
		if (month > 11) {
			setMonthTitle(month - 12);

			setYearTitle(yearTitle + 1);
		} else if (month < 0) {
			setMonthTitle(month + 12);

			setYearTitle(yearTitle - 1);
		} else {
			setMonthTitle(month);
		}
	};

	const generateMonthDates = useCallback(() => {
		const dates = [];
		const firstDayOfMonth = new Date(yearTitle, monthTitle, 1);
		const lastDayOfMonth = new Date(yearTitle, monthTitle + 1, 0);

		for (let date = firstDayOfMonth; date <= lastDayOfMonth; date.setDate(date.getDate() + 1)) {
			dates.push({
				date: date.toLocaleString("sv", { year: "numeric", month: "2-digit", day: "2-digit" })
			});
		}

		return dates;
	}, [monthTitle, yearTitle]);

	const data = useMemo(() => {
		const allDates = generateMonthDates();

		return allDates.map((dateObj) => {
			const dataForDate = props.data.find((item) => item.date === dateObj.date);

			return dataForDate ? { ...dateObj, ...dataForDate } : dateObj;
		});
	}, [generateMonthDates, props.data]);

	//prettier-ignore
	const editableDate = useCallback((month, year) => {		
		props?.editableDate(month, year);
	}, [props]);

	//prettier-ignore
	const onHandleFormik = useCallback((obj) => {
		const { name, value } = obj;

		formik.setFieldValue(name, value);
	}, [formik]);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({ startTime: obj?.startTime, endTime: obj?.endTime });

		setEditData(!editData);
	}, [editData, formik]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "date",
			label: "Date",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const pageIndex = tableMeta.rowIndex % generateMonthDates().length;
					const firstDayOfMonth = new Date(yearTitle, monthTitle, 1);
					const currentDate = new Date(firstDayOfMonth);

					currentDate.setDate(currentDate.getDate() + pageIndex);

					const dayOfWeek = currentDate.toLocaleString("en-GB", { weekday: "long" });
					const suffix = getOrdinalNumber(currentDate.getDate());

					if (currentDate.toDateString() === new Date().toDateString()) {
						return suffix + ", " + dayOfWeek + " (Today)";
					} else {
						return suffix + ", " + dayOfWeek;
					}
				}
			}
		}
	], [monthTitle, yearTitle, generateMonthDates]);

	const columns = useMemo(() => [...tableColumns, ...props.columns], [props.columns, tableColumns]);

	//prettier-ignore
	const options = useMemo(() => ({
		...props.options,
		print: false,
		filter: false,
		search: false,
		download: false,
		viewColumns: false,
		fixedHeader: false,
		rowsPerPage: generateMonthDates().length,
		responsive: "standard",
		selectableRowsHideCheckboxes: true,
		setRowProps: (row) => ({
			className: classNames({
				"calendar-row": true,
				"calendar-row--weekend": row[0]?.includes("Saturday") || row[0]?.includes("Sunday")
			})
		}),
		customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => (props.options?.customFooter ? props.options.customFooter(count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) : <AppCalendarScheduleFooter rowsPerPage={rowsPerPage} data={monthlyItems} />)
	}), [props.options, generateMonthDates, monthlyItems]);

	//prettier-ignore
	const HeaderEditableButton = useCallback((obj) => {
		if (!obj.editData) return <AppButton className="calendar-schedule__edit-button" type="button" label="Edit" icon={editIcon} onClick={() => obj.editableDate(obj.monthTitle, obj.yearTitle)} />;

		return (
			<div className="calendar-schedule__edit-buttons">
				<AppButton outline type="button" label="Cancel" onClick={obj.editableDate} />

				<AppButton type="button" label="Confirm" onClick={formik?.handleSubmit} />
			</div>
		);
	}, [formik?.handleSubmit]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleFormik: onHandleFormik
	}));

	return (
		<div className="app-calendar-schedule">
			<div className="calendar-schedule">
				<div className="calendar-schedule__button-container">
					{!!props.editableDate && <HeaderEditableButton editData={editData} editableDate={editableDate} monthTitle={monthTitle} yearTitle={yearTitle} />}

					{!editData && (
						<div className="calendar-schedule__chevron">
							<AppButton outline label="" type="button" className="calendar-schedule__left-chevron" icon={chevronIcon} onClick={handlePrevPage} />

							<AppButton outline label="" type="button" icon={chevronIcon} onClick={handleNextPage} />
						</div>
					)}
				</div>

				<h1 className="calendar-schedule__title">
					<span>{monthName}</span> {yearTitle}
				</h1>
			</div>

			<div className="calendar-schedule__table">
				<MuiDataTable columns={columns} data={data} options={options} components={components} />
			</div>
		</div>
	);
};

export default memo(forwardRef(AppCalendarSchedule));

AppCalendarSchedule.propTypes = {
	formik: PropTypes.object,
	options: PropTypes.object,
	components: PropTypes.object,
	data: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	editableDate: PropTypes.func
};
