import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import AppButton from "components/app-button";

const AppRemunerationLeaveTypeDeleteModal = (props, ref) => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const [leavePackage, setLeavePackage] = useState(null);
	const [objectIndex, setObjectIndex] = useState([]);

	const onHandleShow = useCallback((leavePackageData, o, i) => {
		setLeavePackage(leavePackageData);

		setObjectIndex({ o, i });

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleConfirm = useCallback(async () => {
		let response = null;

		const params = {
			"leave-package-id": leavePackage
		};

		try {
			await api.post.humanResource.deleteLeavePackage(params);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setVisible(false);

			props.onHandleConfirm(objectIndex.o, objectIndex.i);

			dispatch(promptLayoutAlertMessage({ message: "Leave package was removed successfully!" }));
		}
	}, [dispatch, leavePackage, objectIndex, props]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-remuneration-leave-type-delete-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="confirmation-modal" aria-describedby="confirmation-modal">
			<div className="remuneration-leave-type-delete-modall">
				<p className="remuneration-leave-type-delete-modall__title">Are you sure?</p>

				<div className="remuneration-leave-type-delete-modall__body">
					<p className="remuneration-leave-type-delete-modall__description">After clicking confirm,</p>

					<p className="remuneration-leave-type-delete-modall__description">this Leave Benefit will be removed from the Remuneration Package.</p>

					<div className="remuneration-leave-type-delete-modall__button-container">
						<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />

						<AppButton type="button" label="Confirm" onClick={onHandleConfirm} />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppRemunerationLeaveTypeDeleteModal));
