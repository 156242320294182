import React, { memo, useCallback, useMemo } from "react";

import { FormControl, FormHelperText } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

import classNames from "common/class-names";

import { ReactComponent as calendar } from "assets/images/components/app-input-date/calendar-icon.svg";

const AppInputDateTime = (props) => {
	const className = useMemo(() => {
		return classNames({
			"app-input-date-time": true,
			"app-input-date-time--disabled": props.disabled,
			...(props.className && {
				[props.className]: true
			})
		});
	}, [props.className, props.disabled]);

	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);

	//prettier-ignore
	const onHandleChange = useCallback((value) => {
        props.onChange(props.name, value);
    }, [props]);

	return (
		<div className={className}>
			<FormControl error={isErrorField}>
				{props.label && (
					<label className="app-input-date-time__label" htmlFor={props.name}>
						{props.label}
						{props.required && <span className="app-input-date-time__required">*</span>}
					</label>
				)}

				<DateTimePicker
					value={props.value || null}
					onChange={onHandleChange}
					minDate={props.minDate}
					maxDate={props.maxDate}
					disabled={props.disabled}
					slotProps={{ textField: { placeholder: props.placeholder || "", name: props.name, error: isErrorField } }}
					slots={{ openPickerIcon: calendar }}
					viewRenderers={{
						hours: renderTimeViewClock,
						minutes: renderTimeViewClock,
						seconds: renderTimeViewClock
					}}
				/>

				{isErrorField ? <FormHelperText>{props.error}</FormHelperText> : null}
			</FormControl>
		</div>
	);
};

export default memo(AppInputDateTime);
