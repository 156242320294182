import React, { useCallback, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import useBreadcrumb from "hooks/use-breadcrumb";

import formatCurrencyPattern, { formatCurrency } from "common/format-currency-pattern";

import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppInvoiceVoidModal from "components/pages/sales/app-invoice-void-modal";

import exportIcon from "assets/images/export-icon.png";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const PageInvoiceCreateEdit = () => {
	const navigate = useNavigate();
	const invoiceVoidModalRef = useRef();
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [selectedRowItem, setSelectedRowItem] = useState(null);

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Sales", path: pathnames.sales.quotations },
			{ label: "Invoices", path: pathnames.sales.invoices },
			{ label: "Edit Invoice", path: pathnames.sales.invoiceCreateEdit }
		];

		return data;
	}, []);

	useBreadcrumb({ breadCrumb });

	const initialValues = useMemo(() => {
		const values = {
			items: [],
			tax: "",
			city: "",
			type: "",
			email: "",
			title: "",
			state: "",
			total: "",
			status: "",
			remarks: "",
			picName: "",
			quantity: "",
			mobileNo: "",
			prefixNo: "",
			postcode: "",
			subtotal: "",
			createdBy: "",
			invoiceId: "",
			serviceId: "",
			description: "",
			customerName: "",
			addressLine1: "",
			addressLine2: "",
			internalNote: "",
			salesOrderId: "",
			invoiceTitle: ""
		};

		return values;
	}, []);

	const onHandleSubmit = () => {};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			invoiceTitle: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: onHandleSubmit
	});

	const onHandleBack = useCallback(() => {
		navigate(pathnames.sales.invoices);
	}, [navigate]);

	const onHandleSetPaid = () => {
		setMenuAnchor(null);
	};

	const onHandleSetVoid = () => {
		setMenuAnchor(false);

		invoiceVoidModalRef.current.onHandleShow();
	};

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "number",
			label: "#",
			options: {
				sort: false,
				sortThirdClickReset: true,
			},
		},
		{
			name: "deliveryOrderId",
			label: "Delivery Order ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "title",
			label: "title",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "amount",
			label: "Amount (MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true,
				setCellHeaderProps: () => ({ className: "table__price" }),
				setCellProps: () => ({ className: "table__price" }),
				customBodyRender: (value, tableMeta) => {
					return formatCurrency(value);
				},
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "createdBy",
			label: "Created By",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
	], []);

	const SelectedItemDetails = useCallback((obj) => {
		if (!obj.selectedRowItem) return null;

		const firstItem = obj.selectedRowItem.number === 1;
		const lastItem = obj.items.length === obj.selectedRowItem.number;

		const onHandleNextItem = () => {
			setSelectedRowItem(obj.items[obj.selectedRowItem.number]);
		};

		const onHandlePrevItem = () => {
			setSelectedRowItem(obj.items[obj.selectedRowItem.number - 2]);
		};

		return (
			<div className="invoice-create-edit__item-table">
				<div className="item-table">
					<div className="item-table__content">
						<div className="item-table__item">
							<p className="item-table__label">Title</p>

							<p className="item-table__value item-table__value--underline">{obj.selectedRowItem.title}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Service ID</p>

							<p className="item-table__value">{obj.selectedRowItem.serviceId}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Type</p>

							<p className="item-table__value">{obj.selectedRowItem.type}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Description</p>

							<p className="item-table__value">{obj.selectedRowItem.description}</p>
						</div>
					</div>

					<div className="item-table__pagination">
						<button type="button" className="item-table__button item-table__button--prev" disabled={firstItem} onClick={onHandlePrevItem}>
							<img src={chevronIcon} alt="pagination-prev" />
						</button>

						<p className="item-table__page">
							item of {obj.selectedRowItem.number} of {obj.items.length}
						</p>

						<button type="button" className="item-table__button item-table__button--next" disabled={lastItem} onClick={onHandleNextItem}>
							<img src={chevronIcon} alt="pagination-next" />
						</button>
					</div>
				</div>
			</div>
		);
	}, []);

	return (
		<div className="page-invoice-create-edit">
			<div className="invoice-create-edit">
				<form className="invoice-create-edit__form" onSubmit={formik.handleSubmit}>
					<div className="invoice-create-edit__header">
						<h1 className="invoice-create-edit__title">Edit Invoice</h1>

						<div className="invoice-create-edit__header invoice-create-edit__header--column">
							<div className="invoice-create-edit__header-buttons">
								<AppButton outline className="invoice-create-edit__action-button" type="button" label="Actions" icon={chevronIcon} onClick={onToggleFilterMenu} />

								<AppButton outline type="button" label="Export as PDF" icon={exportIcon} onClick={() => {}} />
							</div>

							<p className="invoice-create-edit__last-update">
								<span>Last Updated By</span> Ahmad Muhammad Ali, 03/11/2023, 10:00 am
							</p>
						</div>
					</div>

					<div className="invoice-create-edit__container">
						<div className="invoice-create-edit__wrapper invoice-create-edit__wrapper--divider">
							<p className="invoice-create-edit__label">General Details</p>

							<div className="invoice-create-edit__row invoice-create-edit__row--general-details">
								{/* prettier-ignore */}
								<AppInput disabled type="text" name="invoiceId" label="Invoice ID" placeholder="Invoice ID" value={formik.values.invoiceId} error={formik.errors.invoiceId} touched={formik.touched.invoiceId} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppSelectInput disabled name="status" label="Status" placeholder="Select..." options={[]} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppInput disabled type="text" name="salesOrderId" label="Sales Order ID" placeholder="Sales Order ID" value={formik.values.salesOrderId} error={formik.errors.salesOrderId} touched={formik.touched.salesOrderId} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppInput disabled type="text" name="createdBy" label="Created By" placeholder="Created By" value={formik.values.createdBy} error={formik.errors.createdBy} touched={formik.touched.createdBy} onChange={formik.handleChange} />
							</div>
						</div>

						<div className="invoice-create-edit__wrapper">
							<p className="invoice-create-edit__label">Customer Details</p>

							<div className="invoice-create-edit__row">
								{/* prettier-ignore */}
								<AppInput disabled type="text" name="customerName" label="Customer Name" placeholder="Please input customer name" value={formik.values.customerName} error={formik.errors.customerName} touched={formik.touched.customerName} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppInput disabled type="text" name="picName" label="PIC Name" placeholder="Please input PIC name" value={formik.values.picName} error={formik.errors.picName} touched={formik.touched.picName} onChange={formik.handleChange} />
							</div>

							<div className="invoice-create-edit__row">
								{/* prettier-ignore */}
								<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

								{/* prettier-ignore */}
								<AppInput disabled type="text" name="email" label="Email" placeholder="Please input email" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
							</div>

							<div className="invoice-create-edit__row">
								{/* prettier-ignore */}
								<AppInput disabled type="text" name="addressLine1" label="Address Line 1" placeholder="Please input address line 1" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppInput disabled type="text" name="addressLine2" label="Address Line 2" placeholder="Please input address line 2" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />
							</div>

							<div className="invoice-create-edit__row">
								{/* prettier-ignore */}
								<AppSelectInput disabled name="state" label="State" placeholder="Select..." options={[]} value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppSelectInput disabled name="city" label="City" placeholder="Select..." options={[]} value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={formik.handleChange} />
							</div>

							<div className="invoice-create-edit__row">
								{/* prettier-ignore */}
								<AppSelectInput disabled name="postcode" label="Postcode" placeholder="Select..." options={[]} value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
							</div>
						</div>
					</div>

					<div className="invoice-create-edit__container">
						<div className="invoice-create-edit__wrapper">
							{/* prettier-ignore */}
							<AppInput required type="text" name="invoiceTitle" label="Invoice Title" placeholder="Please input invoice title" value={formik.values.invoiceTitle} error={formik.errors.invoiceTitle} touched={formik.touched.invoiceTitle} onChange={formik.handleChange} />

							<p className="invoice-create-edit__label">Item Lines</p>

							<AppTable data={formik.values.items} columns={tableColumns} />

							<SelectedItemDetails selectedRowItem={selectedRowItem} items={formik.values.items} />

							<div className="invoice-create-edit__row">
								{/* prettier-ignore */}
								<AppInput multiline type="textarea" name="internalNote" label="Internal Note" placeholder="Type Note here" value={formik.values.internalNote} error={formik.errors.internalNote} touched={formik.touched.internalNote} onChange={formik.handleChange} />

								<div className="invoice-create-edit__column invoice-create-edit__column--divider">
									{/* prettier-ignore */}
									<AppInput disabled type="text" name="subtotal" label="Subtotal (MYR)" placeholder="For example: 100.00" value={formik.values.subtotal} error={formik.errors.subtotal} touched={formik.touched.subtotal} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

									{/* prettier-ignore */}
									<AppInput disabled type="number" maxLength={1} name="tax" label="Tax (%)" placeholder="Please input tax percent" value={formik.values.tax} error={formik.errors.tax} touched={formik.touched.tax} onChange={formik.handleChange} />
								</div>
							</div>

							<div className="invoice-create-edit__row">
								{/* prettier-ignore */}
								<AppInput disabled multiline type="textarea" name="remarks" label="Customer Note" placeholder="Type Note here" value={formik.values.remarks} error={formik.errors.remarks} touched={formik.touched.remarks} onChange={formik.handleChange} />

								<div className="invoice-create-edit__column invoice-create-edit__column--divider">
									{/* prettier-ignore */}
									<AppInput disabled type="text" name="discount" label="Discount (MYR)" value={formik.values.discount} error={formik.errors.discount} touched={formik.touched.discount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

									{/* prettier-ignore */}
									<AppInput disabled type="text" name="grandTotal" label="Grand Total (MYR)" value={formik.values.grandTotal} error={formik.errors.grandTotal} touched={formik.touched.grandTotal} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
								</div>
							</div>
						</div>
					</div>

					<div className="invoice-create-edit__button-container">
						<AppButton outline className="invoice-create-edit__cancel-button" type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton outline type="button" label="Update Draft" onClick={() => {}} />

						<AppButton type="submit" label="Create" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>

			<Menu classes={{ root: "invoice-create-edit-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleSetPaid}>Set As Paid</MenuItem>

				<MenuItem onClick={onHandleSetVoid}>Set As Void</MenuItem>
			</Menu>

			<AppInvoiceVoidModal ref={invoiceVoidModalRef} onConfirm={onHandleSetVoid} />
		</div>
	);
};

export default PageInvoiceCreateEdit;
