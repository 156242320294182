import React, { memo, useMemo } from "react";

import PropTypes from "prop-types";

import classNames from "common/class-names";
import capitalizeCharacter from "common/capitalize-character";

import STATUS from "constants/status";

const AppStatus = (props) => {
	const green = useMemo(() => {
		switch (props.status) {
			case STATUS.IN_USE:
			case STATUS.ACTIVE:
			case STATUS.PERMANENT:
			case STATUS.WORKING:
			case STATUS.APPROVED:
			case STATUS.ACKNOWLEDGED:
			case STATUS.VERIFIED:
			case STATUS.COMPLETED:
			case STATUS.OPEN:
			case STATUS.APPROVED_BY_HR:
			case STATUS.WON:
			case STATUS.WON_WITH_EXCEPTION:
			case STATUS.COMPLETED_WITH_EXCEPTION:
				return true;
			default:
				return false;
		}
	}, [props.status]);

	const yellow = useMemo(() => {
		switch (props.status) {
			case STATUS.INTERN:
			case STATUS.PROBATION:
			case STATUS.PENDING:
			case STATUS.ON_HOLD:
			case STATUS.APPROVED_BY_MANAGER:
			case STATUS.DRAFT_WITH_EXCEPTION:
				return true;
			default:
				return false;
		}
	}, [props.status]);

	const red = useMemo(() => {
		switch (props.status) {
			case STATUS.TERMINATED:
			case STATUS.CANCELLED:
			case STATUS.REJECTED:
			case STATUS.OVERDUE:
			case STATUS.CLOSED:
			case STATUS.LOST:
			case STATUS.DROP_OFF:
				return true;
			default:
				return false;
		}
	}, [props.status]);

	const grey = useMemo(() => {
		switch (props.status) {
			case STATUS.RETIRED:
			case STATUS.INACTIVE:
				return true;
			default:
				return false;
		}
	}, [props.status]);

	const orange = useMemo(() => {
		switch (props.status) {
			case STATUS.UNACCOUNTED_ABSENCE:
			case STATUS.IN_PROGRESS:
			case STATUS.SCHEDULED:
			case STATUS.ASSIGNED:
			case STATUS.DRAFT:
				return true;
			default:
				return false;
		}
	}, [props.status]);

	const blue = useMemo(() => {
		switch (props.status) {
			case STATUS.ON_LEAVE:
			case STATUS.ISSUED:
			case STATUS.RESOLVED:
			case STATUS.PAID:
				return true;
			default:
				return false;
		}
	}, [props.status]);

	const deepBlue = useMemo(() => {
		switch (props.status) {
			case STATUS.RECEIVED:
				return true;
			default:
				return false;
		}
	}, [props.status]);

	const className = useMemo(() => {
		return classNames({ status: true, "status--green": green, "status--grey": grey, "status--red": red, "status--yellow": yellow, "status--orange": orange, "status--blue": blue, "status--deep-blue": deepBlue });
	}, [green, grey, red, yellow, orange, blue, deepBlue]);

	return (
		<div className="app-status">
			<div className={className}>
				<p className="status__text">{capitalizeCharacter(props.status.split("_").join(" "))}</p>
			</div>
		</div>
	);
};

export default memo(AppStatus);

AppStatus.propTypes = {
	status: PropTypes.string.isRequired
};
