import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ENDPOINT_PATH from "constants/end-point-path";

import AppTable from "components/app-table/app-table";

const AppClaimSummaryTable = () => {
	const { id } = useParams();
	const [data, setData] = useState([]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;

	const onHandleGetList = useCallback(async (uniqueId) => {
		let response = null;

		const params = sanitizeObject({
			"employee-id": uniqueId
		});

		try {
			response = await api.get.humanResource.employeeClaimSummary(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setData(response);
		}
	}, []);

	const tableColumns = [
		{
			name: "claimType",
			label: "Claim Type",
			options: {
				sort: false
			}
		},
		{
			name: "entitledAmount",
			label: "Entitled Amount",
			options: {
				sort: false
			}
		},
		{
			name: "balanceAmount",
			label: "Balance Amount (MYR)",
			options: {
				sort: false
			}
		}
	];

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		customFooter: () => null
	}), []);

	useEffect(() => {
		onHandleGetList(id);
	}, [onHandleGetList, id]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_CLAIM_SUMMARY);
		};
	}, [cancelRequest]);

	return (
		<div className="app-claim-summary-table">
			<div className="claim-summary-table">
				<div className="claim-summary-table__body">
					<AppTable data={data} columns={tableColumns} options={tableOptions} />
				</div>
			</div>
		</div>
	);
};

export default AppClaimSummaryTable;
