import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { Modal } from "@mui/material";

import STATUS from "constants/status";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppInputDate from "components/app-input-date";

import closeIcon from "assets/images/close-icon.png";
import chevronIcon from "assets/images/chevron-right-icon.png";

const AppCorrectiveAttendanceViewEmployeeModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	const initialValues = useMemo(() => {
		const values = {
			name: "",
			id: "",
			status: "",
			reviewedBy: "",
			date: "",
			submitDate: "",
			previousClockIn: "",
			correctedClockIn: "",
			previousClockOut: "",
			correctedClockOut: "",
			remarks: "",
			rejectReason: ""
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues
	});

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
        formik.setValues({
            name: obj?.name || "",
            id: obj?.id || "",
            status: obj?.status || STATUS.APPROVED,
            reviewedBy: obj?.reviewedBy || "",
            date: dayjs(obj?.date) || "",
            submitDate: dayjs(obj?.date) || "",
            previousClockIn: obj?.previousClockIn || "",
            correctedClockIn: obj?.correctedClockIn || "",
            previousClockOut: obj?.previousClockOut || "",
            correctedClockOut: obj?.correctedClockOut || "",
            remarks: obj?.description || "N/A",
            rejectReason: obj?.description || "N/A"
        });

        setVisible(true);
    }, [formik]);

	const isRejected = useMemo(() => formik.values.status === STATUS.REJECTED, [formik]);
	const appInputLabel = useMemo(() => (isRejected ? "Rejected By" : "Approved By"), [isRejected]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-corrective-attendance-view-employee-modal" }} open={visible}>
			<div className="corrective-attendance-view-employee-modal">
				<button type="button" className="corrective-attendance-view-employee-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="corrective-attendance-view-employee-modal__title">Corrective Attendance Details</h1>

				<div className="corrective-attendance-view-employee-modal__container">
					<div className="corrective-attendance-view-employee-modal__row">
						<AppInput disabled type="text" name="name" label="Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="id" label="Employee ID" value={formik.values.id} error={formik.errors.id} touched={formik.touched.id} onChange={formik.handleChange} />
					</div>

					<div className="corrective-attendance-view-employee-modal__row">
						<AppInput disabled type="text" name="status" label="Status" value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="reviewedBy" label={appInputLabel} value={formik.values.reviewedBy} error={formik.errors.reviewedBy} touched={formik.touched.reviewedBy} onChange={formik.handleChange} />
					</div>

					<div className="corrective-attendance-view-employee-modal__row">
						<AppInputDate disabled type="text" name="date" label="Date" value={formik.values.date} error={formik.errors.date} touched={formik.touched.date} onChange={formik.handleChange} />

						<AppInputDate disabled type="text" name="submitDate" label="Submit Date" value={formik.values.submitDate} error={formik.errors.submitDate} touched={formik.touched.submitDate} onChange={formik.handleChange} />
					</div>

					<div className="corrective-attendance-view-employee-modal__row">
						<AppInput disabled type="text" name="previousClockIn" label="Previous Clock In" value={formik.values.previousClockIn} error={formik.errors.previousClockIn} touched={formik.touched.previousClockIn} onChange={formik.handleChange} />

						<AppIcon className="corrective-attendance-view-employee-modal__chevron" src={chevronIcon} />

						<AppInput disabled type="text" name="correctedClockIn" label="Corrected Clock In" value={formik.values.correctedClockIn} error={formik.errors.correctedClockIn} touched={formik.touched.correctedClockIn} onChange={formik.handleChange} />
					</div>

					<div className="corrective-attendance-view-employee-modal__row">
						<AppInput disabled type="text" name="previousClockOut" label="Previous Clock Out" value={formik.values.previousClockOut} error={formik.errors.previousClockOut} touched={formik.touched.previousClockOut} onChange={formik.handleChange} />

						<AppIcon className="corrective-attendance-view-employee-modal__chevron" src={chevronIcon} />

						<AppInput disabled type="text" name="correctedClockOut" label="Corrected Clock Out" value={formik.values.correctedClockOut} error={formik.errors.correctedClockOut} touched={formik.touched.correctedClockOut} onChange={formik.handleChange} />
					</div>

					<AppInput disabled type="textarea" name="remarks" label="Remarks" multiline value={formik.values.remarks} touched={formik.touched.remarks} error={formik.errors.remarks} onChange={formik.handleChange} />

					{isRejected && <AppInput disabled type="textarea" name="rejectReason" label="Reject Reason" multiline value={formik.values.rejectReason} error={formik.errors.rejectReason} touched={formik.touched.rejectReason} onChange={formik.handleChange} />}
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppCorrectiveAttendanceViewEmployeeModal));
