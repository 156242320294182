import React, { Fragment, memo, useCallback, useContext, useEffect, useMemo, useRef } from "react";

import * as yup from "yup";
import Switch from "@mui/material/Switch";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";
import getClaimTypeListing from "services/get-claim-type-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import formatCurrencyPattern from "common/format-currency-pattern";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ERRORS from "constants/errors";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppRemunerationClaimTypeDeleteModal from "components/pages/human-resources/app-remuneration-claim-type-delete-modal";

import addIcon from "assets/images/blue-add-icon.svg";
import removeIcon from "assets/images/trash-icon.png";

const PageClaimPackageCreateEdit = () => {
	let { id } = useParams();
	const deleteRef = useRef();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const extraClaimBenefit = useMemo(() => ({ id: "", hrConfigClaimTypeId: "", quantityPerMonth: "", limitPerClaimAmount: "", limitPerMonthAmount: "", needsAttachment: true, limitToCurrentYear: true }), []);
	const initialValues = useMemo(() => ({ name: "", description: "", employedFor: "", extraClaimBenefits: isCreate ? [extraClaimBenefit] : [] }), [extraClaimBenefit, isCreate]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;

	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			employedFor: yup.string().required(ERRORS.REQUIRED),
			extraClaimBenefits: yup.array().of(
				yup.object({
					hrConfigClaimTypeId: yup.string().required(ERRORS.REQUIRED),
					quantityPerMonth: yup.string().required(ERRORS.REQUIRED),
					limitPerClaimAmount: yup.string().required(ERRORS.REQUIRED),
					limitPerMonthAmount: yup.string().required(ERRORS.REQUIRED)
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isDisabled = useMemo(() => formik.isSubmitting, [formik.isSubmitting]);
	const title = useMemo(() => (isCreate ? "Add Claim Package" : "Edit Claim Package"), [isCreate]);
	const submitLabel = useMemo(() => (isCreate ? "Add" : "Update"), [isCreate]);

	const onHandleClaimBenefitsPayload = (claimBenefits) => {
		return claimBenefits.map((o) => ({
			id: o.id,
			hrConfigClaimTypeId: o.hrConfigClaimTypeId,
			needsAttachment: o.needsAttachment,
			quantityPerMonth: o.quantityPerMonth,
			limitToCurrentYear: o.limitToCurrentYear,
			limitPerClaimAmount: o.limitPerClaimAmount,
			limitPerMonthAmount: o.limitPerMonthAmount
		}));
	};

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.humanResource.claimPackage(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				name: response.name,
				description: response.description,
				employedFor: response.eligibility.employedYears,
				extraClaimBenefits: response.claimBenefits?.length ? response.claimBenefits : [extraClaimBenefit]
			});
		}
	}, [extraClaimBenefit, memoSetFormValues]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				name: values.name,
				description: values.description,
				eligibility: {
					employedYears: parseInt(values.employedFor)
				},
				claimBenefits: onHandleClaimBenefitsPayload(values.extraClaimBenefits)
			};

			if (isCreate) {
				await api.post.humanResource.createClaimPackage(payload);
			}

			if (!isCreate) {
				payload.id = id;
				await api.post.humanResource.updateClaimPackage(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Claim Package was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Claim Package was updated successfully!" }));
			}

			navigate(pathnames.humanResources.remunerationPackages + "?tab=CLAIM_PACKAGES");
		}
	}, [navigate, dispatch, formik, id, isCreate]);

	//prettier-ignore
	const onHandleSetNeedsAttachment = useCallback((index, currentValue) => {
		formik.setFieldValue(`extraClaimBenefits.${index}.needsAttachment`, !currentValue);
	}, [formik]);

	//prettier-ignore
	const onHandleSetLimitToCurrentYear = useCallback((index, currentValue) => {
		formik.setFieldValue(`extraClaimBenefits.${index}.limitToCurrentYear`, !currentValue);
	}, [formik]);

	//prettier-ignore
	const onHandleAddExtraBenefit = useCallback((o) => {
		o.push(extraClaimBenefit);
	}, [extraClaimBenefit]);

	//prettier-ignore
	const onHandleRemoveExtraBenefit = useCallback((object, index) => {
		const benefit = formik.values.extraClaimBenefits[index];

		if (benefit.id) {
			deleteRef.current.onHandleShow(benefit.id, object, index);
		} else {
			object.remove(index);
		}
	}, [formik.values.extraClaimBenefits]);

	//prettier-ignore
	const onHandleConfirm = useCallback((o, index) => {
		if (formik.values.extraClaimBenefits?.length > 1) {
			o.remove(index);
		}
	}, [formik.values.extraClaimBenefits]);

	const onHandleCancel = useCallback(() => {
		navigate(pathnames.humanResources.remunerationPackages + "?tab=CLAIM_PACKAGES");
	}, [navigate]);

	useEffect(() => {
		if (!isCreate) {
			onHandleGetDetails(id);
		}
	}, [isCreate, id, onHandleGetDetails]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.CLAIM_PACKAGE);
		};
	}, [cancelRequest]);

	return (
		<FormikProvider value={formik}>
			<div className="page-claim-package-create-edit">
				<div className="claim-package-create-edit">
					<h1 className="claim-package-create-edit__title">{title}</h1>

					<form className="claim-package-create-edit__form" onSubmit={formik.handleSubmit}>
						<div className="claim-package-create-edit__container">
							<div className="claim-package-create-edit__box">
								<div className="claim-package-create-edit__wrapper">
									<div className="claim-package-create-edit__box-body">
										<AppInput required type="text" name="name" disabled={isDisabled} label="Claim Package Name" placeholder="Enter Claim Package Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

										<AppInput multiline maxLength={255} type="text" name="description" disabled={isDisabled} label="Description" placeholder="Enter Description" value={formik.values.description} touched={formik.touched.description} onChange={formik.handleChange} />

										<AppInput required type="number" name="employedFor" disabled={isDisabled} label="Employed for (Years)" placeholder="Enter the No. of years" value={formik.values.employedFor} error={formik.errors.employedFor} touched={formik.touched.employedFor} onChange={formik.handleChange} />
									</div>
								</div>
							</div>
						</div>

						<FieldArray
							name="extraClaimBenefits"
							render={(o) => {
								const errors = formik.errors;
								const touched = formik.touched;
								const extraClaimBenefits = formik.values.extraClaimBenefits;

								return (
									<div className="claim-package-create-edit__container">
										<div className="claim-package-create-edit__box">
											<div className="claim-package-create-edit__header">
												<p className="claim-package-create-edit__input-title">Claim Benefits</p>

												<AppButton type="button" label="Add Claim" icon={addIcon} onClick={() => onHandleAddExtraBenefit(o)} />
											</div>

											{!!extraClaimBenefits.length && (
												<Fragment>
													<div className="claim-package-create-edit__divider" />

													<div className="claim-package-create-edit__extra-benefit">
														<div className="claim-package-create-edit__box-body">
															{extraClaimBenefits.map((benefit, i) => (
																<div key={i} className="claim-package-create-edit__input">
																	{extraClaimBenefits.length > 1 && (
																		<div className="claim-package-create-edit__input-section">
																			<button type="button" className="claim-package-create-edit__remove-button" disabled={isDisabled || extraClaimBenefits?.length <= 1} onClick={() => onHandleRemoveExtraBenefit(o, i)}>
																				<img src={removeIcon} alt="remove-extra-benefit" />
																			</button>
																		</div>
																	)}

																	<AppSelectInput type="text" label="Claim Types" name={`extraClaimBenefits.${i}.hrConfigClaimTypeId`} disabled={isDisabled} placeholder="Please Select" value={benefit.hrConfigClaimTypeId} error={errors.extraClaimBenefits?.[i]?.hrConfigClaimTypeId} touched={touched.extraClaimBenefits?.[i]?.hrConfigClaimTypeId} loadOptions={getClaimTypeListing} onChange={formik.handleChange} />

																	<AppInput maxLength={3} type="number" name={`extraClaimBenefits.${i}.quantityPerMonth`} disabled={isDisabled} label="Quantity Per Month" placeholder="Enter No. of times Employee can claim per month" value={benefit.quantityPerMonth} error={errors.extraClaimBenefits?.[i]?.quantityPerMonth} touched={touched.extraClaimBenefits?.[i]?.quantityPerMonth} onChange={formik.handleChange} />

																	<AppInput type="text" name={`extraClaimBenefits.${i}.limitPerClaimAmount`} disabled={isDisabled} label="Limit Per Claim" placeholder="RM0.00" value={benefit.limitPerClaimAmount} error={errors.extraClaimBenefits?.[i]?.limitPerClaimAmount} touched={touched.extraClaimBenefits?.[i]?.limitPerClaimAmount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

																	<AppInput type="text" name={`extraClaimBenefits.${i}.limitPerMonthAmount`} disabled={isDisabled} label="Limit Per Month" placeholder="RM0.00" value={benefit.limitPerMonthAmount} error={errors.extraClaimBenefits?.[i]?.limitPerMonthAmount} touched={touched.extraClaimBenefits?.[i]?.limitPerMonthAmount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

																	<div className="claim-package-create-edit__input-switch">
																		<p className="claim-package-create-edit__switch-label">Attachment Required</p>

																		<Switch checked={benefit.needsAttachment} disabled={isDisabled} onChange={() => onHandleSetNeedsAttachment(i, benefit.needsAttachment)} />

																		<p className="claim-package-create-edit__switch-label">Limit to Current Year</p>

																		<Switch checked={benefit.limitToCurrentYear} disabled={isDisabled} onChange={() => onHandleSetLimitToCurrentYear(i, benefit.limitToCurrentYear)} />
																	</div>
																</div>
															))}
														</div>
													</div>
												</Fragment>
											)}
										</div>
									</div>
								);
							}}
						/>

						<div className="claim-package-create-edit__button-container">
							<AppButton outline type="button" label="Cancel" onClick={onHandleCancel} />

							<AppButton type="submit" disabled={isDisabled} label={submitLabel} />
						</div>
					</form>
				</div>

				<AppRemunerationClaimTypeDeleteModal ref={deleteRef} onHandleConfirm={onHandleConfirm} />
			</div>
		</FormikProvider>
	);
};

export default memo(PageClaimPackageCreateEdit);
