import React, { useMemo } from "react";

import { useFormik } from "formik";

import pathnames from "routes/pathnames";

import useBreadcrumb from "hooks/use-breadcrumb";

import STATUS from "constants/status";

import AppTabs from "components/app-tabs";
import AppStatus from "components/app-status";
import AppViewEmployeeLeaveHistoryTable from "components/pages/human-resources/app-view-employee-leave-history-table";
import AppViewEmployeeLeaveSummaryTable from "components/pages/human-resources/app-view-employee-leave-summary-table";
import AppViewEmployeePendingLeaveTable from "components/pages/human-resources/app-view-employee-pending-leave-table";

import clockIcon from "assets/images/clock-icon.svg";
import activeClockIcon from "assets/images/clock-icon blue.svg";
import hourglassIcon from "assets/images/pages/human-resources/hourglass-icon.svg";
import leaveApproveIcon from "assets/images/pages/human-resources/leave-approval-icon.svg";
import activeHourglassIcon from "assets/images/pages/human-resources/hourglass-active-icon.svg";
import activeLeaveApproveIcon from "assets/images/pages/human-resources/leave-approval-active-icon.svg";

const dummyyData = { name: "NO DATA", employeeId: "000", todayStatus: STATUS.ON_LEAVE };

const PageViewEmployeeLeave = () => {
	const formik = useFormik({
		initialValues: { items: dummyyData }
	});

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Leave Management", path: pathnames.humanResources.leaveManagement },
			{ label: `${formik.values.items.employeeId} ${formik.values.items.name} Leave`, path: pathnames.humanResources.hrViewHolidays }
		];

		return data;
	}, [formik.values.items]);

	useBreadcrumb({ breadCrumb });

	//prettier-ignore
	const viewEmployeeLeaveTabs = useMemo(() => [
		{ label: "Leave Summary", icon: hourglassIcon, activeIcon: activeHourglassIcon, name: "LEAVE_SUMMARY", component: <AppViewEmployeeLeaveSummaryTable />, accessible: true },
		{ label: "Leave History", icon: clockIcon, activeIcon: activeClockIcon, name: "LEAVE_HISTORY", component: <AppViewEmployeeLeaveHistoryTable />, accessible: true },
		{ label: "Pending Leave", icon: leaveApproveIcon, activeIcon: activeLeaveApproveIcon, name: "PENDING_LEAVE", component: <AppViewEmployeePendingLeaveTable />, accessible: true },
	], []);

	return (
		<div className="page-view-employee-leave">
			<div className="view-employee-leave">
				<div className="view-employee-leave__header">
					<h1 className="view-employee-leave__title">{formik.values.items.name}</h1>

					<AppStatus status={formik.values.items.todayStatus} />
				</div>

				<p className="view-employee-leave__id">{formik.values.items.employeeId}</p>

				<div className="view-employee-leave__body">
					<AppTabs tabs={viewEmployeeLeaveTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageViewEmployeeLeave;
