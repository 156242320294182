import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { TableCell } from "@mui/material";

import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";

import AppIcon from "components/app-icon";
import AppStatus from "components/app-status";
import AppTable from "components/app-table/app-table";
import AppCorrectiveAttendanceViewEmployeeModal from "components/pages/human-resources/app-corrective-attendance-view-employee-modal";

import eyeIcon from "assets/images/eye-open-icon.png";
import chevronIcon from "assets/images/chevron-right-icon.png";

const dummyData = [
	{ date: new Date("2024-08-01"), previousClockIn: "09:00 AM", correctedClockIn: "09:15 AM", previousClockOut: "05:00 PM", correctedClockOut: "05:15 PM", reviewedBy: "Him", status: STATUS.APPROVED },
	{ date: new Date("2024-08-02"), previousClockIn: "08:45 AM", correctedClockIn: "09:00 AM", previousClockOut: "04:45 PM", correctedClockOut: "05:00 PM", reviewedBy: "Her", status: STATUS.REJECTED },
	{ date: new Date("2024-08-03"), previousClockIn: "08:30 AM", correctedClockIn: "08:45 AM", previousClockOut: "04:30 PM", correctedClockOut: "04:45 PM", reviewedBy: "You", status: STATUS.APPROVED }
];

const AppCorrectiveAttendanceHistoryEmployeeTable = () => {
	const correctiveAttendanceRef = useRef();
	const [data, setData] = useState(dummyData);

	//prettier-ignore
	const onHandleViewCorrectiveAttendance = useCallback((obj) => {
		correctiveAttendanceRef.current.onHandleShow(data[obj.rowIndex]);
	}, [data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "date",
			label: "Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			}
		},
		{
			name: "previousClockIn",
			label: "Previous Clock In",
			options: {
				sort: false
			}
		},
		{
			name: "symbol",
			options: {
				sort: false,
				customHeadRender: () => {
					return (
						<TableCell key="table-key-clock-in" className="app-table__cell app-table__cell--header">
							<AppIcon src={chevronIcon} />
						</TableCell>
					);
				},
				customBodyRender: () => <AppIcon src={chevronIcon} />
			}
		},
		{
			name: "correctedClockIn",
			label: "Corrected Clock In",
			options: {
				sort: false
			}
		},
		{
			name: "previousClockOut",
			label: "Previous Clock Out",
			options: {
				sort: false
			}
		},
		{
			name: "symbol",
			options: {
				sort: false,
				customHeadRender: () => {
					return (
						<TableCell key="table-key-clock-out" className="app-table__cell app-table__cell--header">
							<AppIcon src={chevronIcon} />
						</TableCell>
					);
				},
				customBodyRender: () => <AppIcon src={chevronIcon} />
			}
		},
		{
			name: "correctedClockOut",
			label: "Corrected Clock Out",
			options: {
				sort: false
			}
		},
		{
			name: "reviewedBy",
			label: "Reviewed By",
			options: {
				sort: false
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__view" onClick={() => onHandleViewCorrectiveAttendance(tableMeta)}>
							<img src={eyeIcon} alt="view-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleViewCorrectiveAttendance]);

	useEffect(() => {
		setData(dummyData);
	}, []);

	return (
		<div className="app-corrective-attendance-history-employee-table">
			<div className="corrective-attendance-history-employee-table">
				<AppTable data={data} columns={tableColumns} />
			</div>

			<AppCorrectiveAttendanceViewEmployeeModal ref={correctiveAttendanceRef} />
		</div>
	);
};

export default AppCorrectiveAttendanceHistoryEmployeeTable;
