import React, { useCallback, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";

import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";

import editIcon from "assets/images/edit-icon.png";
import qrBlueIcon from "assets/images/qr-blue-icon.svg";
import qrWhiteIcon from "assets/images/qr-white-icon.svg";

const AppCustomerAssetListTable = (props) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_CONTRACT_SITE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const [searchParams] = useSearchParams();
	const [selectedPending, setSelectedPending] = useState([]);
	const data = useMemo(() => props.data, [props.data]);
	const appQrIcon = useMemo(() => (restricted ? qrWhiteIcon : qrBlueIcon), [restricted]);
	const queryParams = useRef({ page: 0, sort: searchParams.get("sort") || "" });
	const downloadingFile = useRef(false);

	const onHandleSelectPending = useCallback((boolean, idNo) => {
		setSelectedPending((prev) => {
			let nextPending = [...prev];

			if (boolean) {
				nextPending.push(idNo);
			} else {
				nextPending = nextPending.filter((id) => id !== idNo);
			}

			return nextPending;
		});
	}, []);

	// prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data.content.map((item) => item.id);

		setSelectedPending(boolean ? ids : []);
	}, [data]);

	// prettier-ignore
	const onHandleEditAsset = useCallback((obj) => {
		const assetId = data.content[obj.rowIndex]?.id;

		navigate(pathnames.customer.customerEditAsset + assetId);
	}, [data, navigate]);

	const onHandleDownloadQRFile = useCallback(async () => {
		let response = null;

		if (selectedPending.length) {
			if (downloadingFile.current) return;

			downloadingFile.current = true;

			try {
				const payload = [...selectedPending];

				response = await api.post.assetMaintenance.generateAssetPdf(payload);
			} catch (error) {
				serveLayoutRequestErrors(error);
			} finally {
				downloadingFile.current = false;
			}

			if (response) {
				const url = response.fileUrl;
				const a = document.createElement("a");
				document.body.appendChild(a);

				a.href = url;
				a.download = response.fileName;
				a.click();

				setTimeout(() => {
					window.URL.revokeObjectURL(url);
					document.body.removeChild(a);
				}, 0);
			}
		}
	}, [selectedPending]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.content.length > 0 && selectedPending.length === data.content.length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />

							{!!selectedPending.length && <div className="app-table__cell app-table__cell--header-text">{selectedPending.length} Record(s) Selected</div>}
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePendingID = data.content[rowIndex]?.id;
					const selected = selectedPending.includes(tablePendingID);

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">
										<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectPending(v, tablePendingID)} value={selected} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				}
			}
		},
		{
			name: "customerAssetName",
			label: "Customer Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "assetName",
			label: "Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "assetType",
			label: "Asset Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "frequency",
			label: "Frequency",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "lastService",
			label: "Last Service",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => value || "-"
			}
		},
		{
			name: "workspace",
			label: "Workspace",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (props.disabled) return;

					return (
						<button type="button" className="table__action">
							<img src={editIcon} alt="edit-icon" onClick={() => onHandleEditAsset(tableMeta)} />
						</button>
					);
				}
			}
		}
	], [data.content, onHandleEditAsset, onHandleSelectAll, onHandleSelectPending, props.disabled, selectedPending]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					props.onHandleGetAssetList(id, queryParams.current);
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					props.onHandleGetAssetList(id, queryParams.current);
					break;
				default:
					break;
			}
		}
	}), [data, id, props]);

	return (
		<div className="app-customer-asset-list-table">
			<div className="customer-asset-list-table">
				<div className="customer-asset-list-table__header">
					<p className="customer-asset-list-table__title">Asset List</p>

					<div className="customer-asset-list-table__button">{!!selectedPending.length && <AppButton outline type="button" disabled={restricted} label="Generate QR" icon={appQrIcon} onClick={onHandleDownloadQRFile} />}</div>
				</div>

				<AppTable data={data?.content || []} columns={tableColumns} tableOptions={tableOptions} />
			</div>
		</div>
	);
};

export default AppCustomerAssetListTable;
