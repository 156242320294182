import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";

import closeIcon from "assets/images/close-icon.png";

export const AppConvertWithExceptionModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState(null);

	const onHandleConfirm = useCallback(() => {
		props.onConfirm();

		setVisible(false);
	}, [props]);

	const onHandleShow = useCallback((values) => {
		setData(values);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-convert-with-exception-modal" }} open={visible}>
			<div className="convert-with-exception-modal">
				<button type="button" className="convert-with-exception-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="convert-with-exception-modal__title">Proceed to Sales Order</h1>

				<p className="convert-with-exception-modal__description">
					After clicking confirm, the Quotation <br />
					<span>{data}</span> will be converted to Sales Order.
				</p>

				<p className="convert-with-exception-modal__info">
					The status for Quotation will be set to <span>Won With Exception</span>.
				</p>

				<div className="convert-with-exception-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="button" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppConvertWithExceptionModal));

AppConvertWithExceptionModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
