import React, { useEffect, useMemo, useState } from "react";

import AppTable from "components/app-table/app-table";

const AppReportDetailsQuotationsTable = () => {
	const [data, setData] = useState([]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
        {
            name: "paginationNumbers",
            label: "#",
            options: {
                sort: false
            }
        },
        {
            name: "id",
            label: "Quotation ID",
            options: {
                sort: false
            }
        },
        {
            name: "title",
            label: "Quotation Title",
            options: {
                sort: false
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                sort: false
            }
        }
    ], []);

	useEffect(() => {
		setData([]);
	}, []);

	return (
		<div className="app-report-details-quotations-table">
			<div className="report-details-quotations-table">
				<AppTable data={data} columns={tableColumns} />
			</div>
		</div>
	);
};

export default AppReportDetailsQuotationsTable;
