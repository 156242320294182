import { memo, useCallback, useMemo } from "react";

import { FormControl, FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import classNames from "common/class-names";

import { ReactComponent as calendar } from "assets/images/components/app-input-date/calendar-icon.svg";

const AppInputDate = (props) => {
	const className = useMemo(() => {
		return classNames({
			"app-input-date": true,
			"app-input-date--disabled": props.disabled,
			...(props.className && {
				[props.className]: true
			})
		});
	}, [props.className, props.disabled]);

	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);

	//prettier-ignore
	const onHandleChange = useCallback((value) => {
		props.onChange(props.name, value);
	}, [props]);

	return (
		<div className={className}>
			<FormControl error={isErrorField}>
				{props.label && (
					<label className="app-input-date__label" htmlFor={props.name}>
						{props.label}
						{props.required && <span className="app-input-date__required">*</span>}
					</label>
				)}

				<DatePicker value={props.value || null} format="DD/MM/YYYY" onChange={onHandleChange} minDate={props.minDate} maxDate={props.maxDate} disabled={props.disabled} slotProps={{ textField: { placeholder: props.placeholder || "", disabled: true, name: props.name, error: isErrorField } }} slots={{ openPickerIcon: calendar }} />

				{isErrorField ? <FormHelperText>{props.error}</FormHelperText> : null}
			</FormControl>
		</div>
	);
};

export default memo(AppInputDate);
