import { useCallback, useContext, useEffect, useMemo } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import stringEncryptRSA from "common/string-encrypt-rsa";
import serveRequestErrors from "common/serve-request-errors";

import REGEX from "constants/regex";
import ERRORS from "constants/errors";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppPasswordChecklist from "components/app-password-checklist";

import logo from "assets/images/logo.png";
import passLock from "assets/images/components/app-input/passLock-icon.png";

const PageResetPassword = () => {
	const props = useContext(AxiosContext);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	// prettier-ignore
	const initialValues = useMemo(() => {
		const data = { password: "", confirmNewPassword: "" };

		return data;
	}, []);

	// prettier-ignore
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: yup.object({
			password: yup.string().min(8, ERRORS.LENGTH).required(ERRORS.REQUIRED).matches(REGEX.UPPERCASE, ERRORS.REQUIRED).matches(REGEX.LOWERCASE, ERRORS.REQUIRED).matches(REGEX.SYMBOL, ERRORS.REQUIRED).matches(REGEX.NUMERIC, ERRORS.REQUIRED),
			confirmNewPassword: yup.string().required(ERRORS.REQUIRED).oneOf([yup.ref("password")], ERRORS.PASSWORDS_MATCH),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		const isClientMobile = searchParams.get("platform") === "cma";

		let response = null;

		try {
			const payload = {
				password: stringEncryptRSA(values.password, isClientMobile ? process.env.REACT_APP_CLIENT_RSA_PUBLIC_KEY : null),
				email: searchParams.get("email"),
				token: searchParams.get("token"),
			};

			const headers = {};


			if(isClientMobile) {
				headers.baseURL = process.env.REACT_APP_CLIENT_API_URL;
			}

			await api.post.forgotPassword.updatePassword(payload, headers);

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			navigate(pathnames.forgotPassword.resetPasswordSuccess + "?platoform=" + searchParams.get("platform"));
		}
	}, [searchParams, navigate, formik]);

	useEffect(() => {
		const onHandleVerifyEmail = async () => {
			try {
				const payload = {
					token: searchParams.get("token"),
					email: searchParams.get("email")
				};

				const headers = {};

				const isClientMobile = searchParams.get("platform") === "cma";

				if (isClientMobile) {
					headers.baseURL = process.env.REACT_APP_CLIENT_API_URL;
				}

				await api.post.forgotPassword.verifyEmail(payload, headers);
			} catch (error) {
				const cancelled = serveRequestErrors(error);

				if (!cancelled) navigate(pathnames.linkExpired + "?platform=" + searchParams.get("platform") + "&action=RESET_PASSWORD");
			}
		};

		onHandleVerifyEmail();

		return () => {
			cancelRequest(ENDPOINT_PATH.FORGOT_PASSWORD.VERIFY_EMAIl);
		};
	}, [navigate, searchParams, cancelRequest]);

	return (
		<div className="page-reset-password">
			<main className="reset-password">
				<form className="reset-password__form" onSubmit={formik.handleSubmit}>
					<Link to={pathnames.login.login} className="reset-password__logo">
						<img src={logo} alt="logo" />
					</Link>

					<h1 className="reset-password__title">Reset Password?</h1>

					<p className="reset-password__description">Set your new password below</p>

					{/* prettier-ignore */}
					<AppInput type="password" name="password"placeholder="New Password" icon={passLock} iconInField={false} value={formik.values.password} error={formik.errors.password} touched={formik.touched.password} onChange={formik.handleChange}/>

					{/* prettier-ignore */}
					<AppInput type="password" name="confirmNewPassword" placeholder="Confirm New Password" icon={passLock} iconInField={false} value={formik.values.confirmNewPassword} error={formik.errors.confirmNewPassword} touched={formik.touched.confirmNewPassword} onChange={formik.handleChange}/>

					<AppPasswordChecklist value={formik.values.password} touched={formik.touched.password} confirmNewPassword={formik.values.confirmNewPassword} />

					<div className="reset-password__button-container">
						<AppButton type="submit" label="Reset Password" disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)} />
					</div>
				</form>
			</main>
		</div>
	);
};

export default PageResetPassword;
