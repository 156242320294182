import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";

const AppPendingCorrectiveAttendanceRejectionModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [ids, setIds] = useState([]);
	const [cancelStatus, setCancelStatus] = useState([]);

	const initialValues = useMemo(() => {
		const values = { rejectReason: "" };

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			rejectReason: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleSubmit = (values) => {
		props.onConfirm(values, ids);

		onHandleDismiss();
	};

	const onHandleShow = useCallback((id) => {
		const updateID = Array.isArray(id) ? [...id] : [id];
		const isCancel = Array.isArray(id);

		setIds(updateID);

		setCancelStatus(isCancel);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleBack = useCallback((id) => {
		props.onHandleBack(id);

		setVisible(false);

		formik.resetForm();
	}, [formik, props]);

	const FooterCancelButton = useCallback((obj) => {
		if (obj.cancelStatus) return <AppButton outline type="button" label="Cancel" onClick={obj.onHandleDismiss} />;

		return <AppButton outline type="button" label="Back" onClick={() => obj.onHandleBack(obj.ids)} />;
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-pending-corrective-attendance-rejection-modal" }} open={visible}>
			<div className="pending-corrective-attendance-rejection-modal">
				<form className="pending-corrective-attendance-rejection-modal__form" onSubmit={formik.handleSubmit}>
					<h1 className="pending-corrective-attendance-rejection-modal__title">Reason for Corrective Attendance Rejection</h1>

					{ids.length > 1 && <p className="pending-corrective-attendance-rejection-modal__description">The reject reason will apply to all selected Corrective Attendance.</p>}

					<AppInput multiline required type="textarea" maxLength={255} name="rejectReason" label="Reject Reason" placeholder="Enter Reject Reason" value={formik.values.rejectReason} error={formik.errors.rejectReason} touched={formik.touched.rejectReason} onChange={formik.handleChange} />

					<div className="pending-corrective-attendance-rejection-modal__button-container">
						<FooterCancelButton cancelStatus={cancelStatus} onHandleDismiss={onHandleDismiss} onHandleBack={onHandleBack} ids={ids} />

						<AppButton type="submit" label="Confirm" />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppPendingCorrectiveAttendanceRejectionModal));

AppPendingCorrectiveAttendanceRejectionModal.propTypes = {
	onConfirm: PropTypes.func,
	onHandleBack: PropTypes.func
};
