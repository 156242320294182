import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";

import PAGE from "constants/page";
import ROLES from "constants/roles";

import AppTabs from "components/app-tabs";
import AppButton from "components/app-button";
import AppOpenEnquiriesTable from "components/pages/customer/app-open-enquiries-table";
import AppClosedEnquiriesTable from "components/pages/customer/app-closed-enquiries-table";
import AppEnquiriesKpiConfigurationModal from "components/pages/customer/app-enquiries-kpi-configuration-modal";

import blueGearIcon from "assets/images/blue-gear-icon.svg";
import groupIcon from "assets/images/pages/customer/open-enquiries-icon.svg";
import clipboardIcon from "assets/images/pages/customer/closed-enquiries-icon.svg";
import activeGroupIcon from "assets/images/pages/customer/open-enquiries-active-icon.svg";
import activeclipboardIcon from "assets/images/pages/customer/closed-enquiries-active-icon.svg";

const PageEnquiries = () => {
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_MAINTENANCE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const [configurationData, setConfigurationData] = useState();
	const appEnquiriresKpiConfigurationModalRef = useRef();

	const onHandleEnquiryConfiguration = useCallback(() => {
		appEnquiriresKpiConfigurationModalRef.current.onHandleShow({ status: PAGE.CREATE });
	}, []);

	//prettier-ignore
	const enquiriesTabs = useMemo(() => [
		{ label: "Open Enquiries", icon: groupIcon, activeIcon: activeGroupIcon, name: "OPEN", component: <AppOpenEnquiriesTable configurationData={configurationData} />, accessible: true },
		{ label: "Closed Enquiries", icon: clipboardIcon, activeIcon: activeclipboardIcon, name: "CLOSED", component: <AppClosedEnquiriesTable configurationData={configurationData} />, accessible: true },
	], [configurationData]);

	const onHandleSendConfigurationData = useCallback((data) => {
		setConfigurationData(data);
	}, []);

	return (
		<div className="page-enquiries">
			<div className="enquiries">
				<div className="enquiries__header">
					<h1 className="enquiries__title">Enquiries</h1>

					{!restricted && (
						<div className="enquiries__configuration-button">
							<AppButton disabled={restricted} type="button" label="Configuration" icon={blueGearIcon} onClick={onHandleEnquiryConfiguration} />
						</div>
					)}
				</div>

				<div className="enquiries__body">
					<AppTabs tabs={enquiriesTabs} />
				</div>
			</div>

			<AppEnquiriesKpiConfigurationModal ref={appEnquiriresKpiConfigurationModalRef} onHandleSendConfigurationData={onHandleSendConfigurationData} />
		</div>
	);
};

export default PageEnquiries;
