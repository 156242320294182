import { memo, useMemo } from "react";

import PropTypes from "prop-types";
import MuiDataTable from "mui-datatables";

import AppTableFooter from "components/app-table/app-table-footer";

import emptyBackground from "assets/images/components/app-table/empty-background.png";

const AppTable = (props) => {
	const data = useMemo(() => props.data, [props.data]);

	const columns = useMemo(() => props.columns, [props.columns]);

	//prettier-ignore
	const options = useMemo(() => ({
		print: false,
		filter: false,
		search: false,
		download: false,
		viewColumns: false,
		fixedHeader: false,
		rowsPerPage: 10,
		responsive: "standard",
		selectableRowsHideCheckboxes: true,
		customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => (props.options?.customFooter ? props.options.customFooter(count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) : <AppTableFooter count={count} page={page} rowsPerPage={rowsPerPage} changeRowsPerPage={changeRowsPerPage} changePage={changePage} textLabels={textLabels} />),
		...props.options
	}), [props.options]);

	const emptyState = useMemo(() => {
		if (data.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={columns.length} align="center">
						<div className="table__empty-container">
							<img className="table__empty-image" src={emptyBackground} alt="empty data" />

							<p className="table__empty-label">You don’t have any records yet</p>
						</div>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [data, columns]);

	const components = useMemo(() => props.components || emptyState, [props.components, emptyState]);

	return (
		<div className="app-table">
			<div className="table">{components ? <MuiDataTable columns={columns} data={data} options={options} components={components} /> : <MuiDataTable columns={columns} data={data} options={options} />}</div>
		</div>
	);
};

export default memo(AppTable);

AppTable.propTypes = {
	data: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	options: PropTypes.object
};
