import { Fragment, memo, useCallback, useEffect, useMemo } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";
import getServiceStatusListing from "services/get-service-status-listing";
import getSparePartCategoryListing from "services/get-spare-part-category-listing";
import getSparePartSubCategoryListing from "services/get-spare-part-sub-category-listing";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";
import CONFIGURATION_TYPE from "constants/configuration-type";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";
import AppServicesLinkageTable from "components/pages/operations/general-configuration/app-services-linkage-table";

const PageSparePartCreateEdit = (props) => {
	const profile = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Add Spare Part" : "Edit Spare Part"), [isCreate]);
	const submitLabel = useMemo(() => (!isCreate ? "Update" : "Add"), [isCreate]);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.SPARE_PART_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const initialValues = useMemo(() => ({ id: "", barcode: "", name: "", sparePartCategoryId: "", brand: "", sparePartSubCategoryId: "", series: "", model: "", status: "", remarks: "", lastModifiedBy: "", lastModifiedByName: "", lastModifiedDate: "", configServiceIds: [], configServices: [], files: [], createdDate: "" }), []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			barcode: yup.string().required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Operations", path: pathnames.operations.generalConfiguration },
			{ label: "General Configuration", path: pathnames.operations.generalConfiguration }
		];

		if (isCreate) {
			data.push({ label: "Add Spare Part", path: pathnames.operations.sparePartCreateEdit + PAGE.CREATE });
		}

		if (!isCreate) {
			data.push({ label: "Edit Spare Part " + formik.values.barcode, path: pathnames.operations.sparePartCreateEdit + id });
		}

		return data;
	}, [isCreate, formik, id]);

	useBreadcrumb({ breadCrumb });

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { 
				name: values.name,
				barcode: values.barcode,
				brand: values.brand,
				sparePartCategoryId: values.sparePartCategoryId || null,
				sparePartSubCategoryId: values.sparePartSubCategoryId || null,
				series: values.series,
				model: values.model,
				remark: values.remarks,
				status: values.status,
				configServiceIds: values.configServiceIds
			};

			if (isCreate) {
				await api.post.sparePart.createSparePart(payload);
			}

			if(!isCreate){
				payload.id = id;

				await api.post.sparePart.updateSparePart(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if(isCreate){
				dispatch(promptLayoutAlertMessage({ message: "Spare Part was added successfully!" }));
			}

			if(!isCreate){
				dispatch(promptLayoutAlertMessage({ message: "Spare Part was updated successfully!" }));
			}

			navigate(pathnames.operations.generalConfiguration);
		}
	}, [navigate, dispatch, formik, id, isCreate]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.sparePart.sparePart(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				id: response.id,
				barcode: response.barcode,
				name: response.name,
				sparePartCategoryId: response.sparePartCategoryId,
				brand: response.brand,
				sparePartSubCategoryId: response.sparePartSubCategoryId,
				series: response.series,
				model: response.model,
				remarks: response.remark,
				status: response.status,
				configServiceIds: [],
				configServices: [],
				lastModifiedBy: response.lastModifiedBy,
				lastModifiedByName: response.lastModifiedByName,
				lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE),
				createdDate: dayjs(response.createdDate).format(DATE_TIME.DD_MM_YYYY)
			});
		}
	}, [memoSetFormValues]);

	// prettier-ignore
	const onHandleUpdateConfigServices = useCallback((values) => {
		formik.setFieldValue("configServices", values);
	}, [formik]);

	// prettier-ignore
	const onHandleUpdateConfigServiceIds = useCallback((values) => {
		formik.setFieldValue("configServiceIds", values);
	}, [formik]);

	// prettier-ignore
	const onHandleRemoveServiceLinkage = useCallback((id) => {
		let nextConfigServiceIds = structuredClone(formik.values.configServiceIds);
		let nextConfigServices = structuredClone(formik.values.configServices);

		nextConfigServiceIds = nextConfigServiceIds.filter((o) => id !== o);
		nextConfigServices = nextConfigServices.filter((o) => id !== o.id);

		formik.setFieldValue("configServiceIds", nextConfigServiceIds);
		formik.setFieldValue("configServices", nextConfigServices);
	},[formik]);

	const onHandleCancel = useCallback(() => {
		navigate(pathnames.operations.generalConfiguration);
	}, [navigate]);

	const ServicesLinkageSection = useCallback((obj) => {
		return (
			<Fragment>
				<div className="spare-part-create-edit__label">Services Linkage</div>

				<div className="spare-part-create-edit__table">
					<AppServicesLinkageTable pageType={obj.pageType} formValues={obj.values} onHandleUpdateConfigServiceIds={obj.onHandleUpdateConfigServiceIds} onHandleUpdateConfigServices={obj.onHandleUpdateConfigServices} onHandleRemoveServiceLinkage={obj.onHandleRemoveServiceLinkage} onHandleGetDetails={obj.onHandleGetDetails} />
				</div>
			</Fragment>
		);
	}, []);

	useEffect(() => {
		if (!isCreate) onHandleGetDetails(id);

		return () => {
			if (id) memoCancelRequest(ENDPOINT_PATH.SPARE_PART.SPARE_PART);
		};
	}, [isCreate, id, memoCancelRequest, onHandleGetDetails]);

	return (
		<div className="page-spare-part-create-edit">
			<div className="spare-part-create-edit">
				<div className="spare-part-create-edit__header">
					<h1 className="spare-part-create-edit__title">{title}</h1>

					{!isCreate && (
						<p className="spare-part-create-edit__last-update">
							<span>Last Updated By</span> {formik.values.lastModifiedByName}, {formik.values.lastModifiedDate}
						</p>
					)}
				</div>

				<form className="spare-part-create-edit__form" onSubmit={formik.handleSubmit}>
					<div className="spare-part-create-edit__container">
						<div className="spare-part-create-edit__label">Details</div>

						<div className="spare-part-create-edit__row">
							<AppInput required name="barcode" label="Barcode ID" placeholder="Enter Barcode ID" type="text" value={formik.values.barcode} error={formik.errors.barcode} touched={formik.touched.barcode} onChange={formik.handleChange} />

							<AppSelectInput searchable={false} required type="text" name="status" label="Status" placeholder="Select..." disabled={restricted} loadOptions={getServiceStatusListing} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
						</div>

						{isCreate && (
							<Fragment>
								<div className="spare-part-create-edit__row">
									<AppInput required name="name" label="Spare Part Name" placeholder="Enter Spare Part Name" type="text" disabled={restricted} value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

									<AppSelectInput pagination name="sparePartCategoryId" label="Spare Part Category" placeholder="Select..." loadOptions={getSparePartCategoryListing} value={formik.values.sparePartCategoryId} error={formik.errors.sparePartCategoryId} touched={formik.touched.sparePartCategoryId} onChange={formik.handleChange} />
								</div>

								<div className="spare-part-create-edit__row">
									<AppInput disabled={restricted} name="brand" label="Brand" placeholder="Enter Brand Name" type="text" value={formik.values.brand} error={formik.errors.brand} touched={formik.touched.brand} onChange={formik.handleChange} />

									<AppSelectInput pagination name="sparePartSubCategoryId" label="Spare Part Sub-Category" placeholder="Select..." loadOptions={getSparePartSubCategoryListing} value={formik.values.sparePartSubCategoryId} error={formik.errors.sparePartSubCategoryId} touched={formik.touched.sparePartSubCategoryId} onChange={formik.handleChange} />
								</div>

								<div className="spare-part-create-edit__row spare-part-create-edit__row--divider">
									<div className="spare-part-create-edit__column">
										<AppInput disabled={restricted} name="model" label="Model" placeholder="Enter Model Name" type="text" value={formik.values.model} error={formik.errors.model} touched={formik.touched.model} onChange={formik.handleChange} />

										<AppInput disabled={restricted} name="series" label="Series" placeholder="Enter Series Name" type="text" value={formik.values.series} error={formik.errors.series} touched={formik.touched.series} onChange={formik.handleChange} />
									</div>

									<AppInput disabled={restricted} name="remarks" label="Remarks" placeholder="Remarks" type="textarea" multiline maxLength={255} value={formik.values.remarks} touched={formik.touched.remarks} error={formik.errors.remarks} onChange={formik.handleChange} />
								</div>
							</Fragment>
						)}

						{!isCreate && (
							<Fragment>
								<div className="spare-part-create-edit__row">
									<AppInput required name="name" label="Spare Part Name" placeholder="Enter Spare Part Name" type="text" disabled={restricted} value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

									<AppInput name="createdDate" label="Date Added" type="text" disabled={!isCreate} value={formik.values.createdDate} error={formik.errors.createdDate} touched={formik.touched.createdDate} onChange={formik.handleChange} />
								</div>

								<div className="spare-part-create-edit__row">
									<AppInput disabled={restricted} name="brand" label="Brand" placeholder="Enter Brand Name" type="text" value={formik.values.brand} error={formik.errors.brand} touched={formik.touched.brand} onChange={formik.handleChange} />

									<AppSelectInput pagination name="sparePartCategoryId" label="Spare Part Category" placeholder="Select..." loadOptions={getSparePartCategoryListing} value={formik.values.sparePartCategoryId} error={formik.errors.sparePartCategoryId} touched={formik.touched.sparePartCategoryId} onChange={formik.handleChange} />
								</div>

								<div className="spare-part-create-edit__row">
									<AppInput disabled={restricted} name="series" label="Series" placeholder="Enter Series Name" type="text" value={formik.values.series} error={formik.errors.series} touched={formik.touched.series} onChange={formik.handleChange} />

									<AppSelectInput pagination name="sparePartSubCategoryId" label="Spare Part Sub-Category" placeholder="Select..." loadOptions={getSparePartSubCategoryListing} value={formik.values.sparePartSubCategoryId} error={formik.errors.sparePartSubCategoryId} touched={formik.touched.sparePartSubCategoryId} onChange={formik.handleChange} />
								</div>

								<div className="spare-part-create-edit__row spare-part-create-edit__row--divider">
									<AppInput disabled={restricted} name="model" label="Model" placeholder="Enter Model Name" type="text" value={formik.values.model} error={formik.errors.model} touched={formik.touched.model} onChange={formik.handleChange} />

									<AppInput disabled={restricted} name="remarks" label="Remarks" placeholder="Remarks" type="textarea" multiline maxLength={255} value={formik.values.remarks} touched={formik.touched.remarks} error={formik.errors.remarks} onChange={formik.handleChange} />
								</div>
							</Fragment>
						)}

						<p className="spare-part-create-edit__label">Thumbnail</p>

						<AppInputDragAndDropFiles name="files" accept="image/png, image/jpg" onChange={formik.setFieldValue} error={formik.errors.files} touched={formik.touched.files} value={formik.values.files} />
					</div>

					<div className="spare-part-create-edit__container">
						<ServicesLinkageSection pageType={CONFIGURATION_TYPE.SPARE_PART} values={formik.values} onHandleUpdateConfigServices={onHandleUpdateConfigServices} onHandleUpdateConfigServiceIds={onHandleUpdateConfigServiceIds} onHandleRemoveServiceLinkage={onHandleRemoveServiceLinkage} onHandleGetDetails={onHandleGetDetails} />
					</div>

					<div className="spare-part-create-edit__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleCancel} />

						<AppButton type="submit" disabled={formik.isSubmitting || restricted} label={submitLabel} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default memo(PageSparePartCreateEdit);
