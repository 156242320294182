import api from "services/api";

import serveRequestErrors from "common/serve-request-errors";

const getRegisteredNameListing = async (payload) => {
	let response = null;
	let errorMessage = null;

	try {
		const params = { size: 10, page: payload?.page || 0, keyword: payload?.keyword || "" };

		const res = await api.get.customer.customers(params);

		const content = res.content.map((item) => ({ value: item.id, label: item.registeredName }));

		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getRegisteredNameListing;
