import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppViewPendingLeaveModal from "components/pages/human-resources/app-view-pending-leave-modal";
import AppViewEmployeePendingLeaveRejectionModal from "components/pages/human-resources/app-view-employee-pending-leave-rejection-modal";

import eyeOpenIcon from "assets/images/eye-open-icon.png";
import rejectIcon from "assets/images/pages/customer/reject-icon.svg";
import approveIcon from "assets/images/pages/customer/approve-icon.svg";

const AppViewEmployeePendingLeaveTable = () => {
	const { id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const pendingLeaveRef = useRef();
	const rejectionRef = useRef();
	const dispatch = useDispatch();
	const [data, setData] = useState(convertPaginationTableData());
	const [selectedPending, setSelectedPending] = useState([]);
	const memoSearchParams = useRef(setSearchParams);
	const defaultStatus = useMemo(() => [STATUS.PENDING, STATUS.APPROVED_BY_MANAGER].join(","), []);
	const queryParams = useRef({ page: 0, sort: searchParams.get("sort") || "", "employee-id": id, status: defaultStatus, tab: "PENDING_LEAVE" });

	const onHandleSelectPending = useCallback((boolean, idNo) => {
		setSelectedPending((prev) => {
			let nextPending = [...prev];

			if (boolean) {
				nextPending.push(idNo);
			} else {
				nextPending = nextPending.filter((employeeId) => employeeId !== idNo);
			}

			return nextPending;
		});
	}, []);

	const onHandleGetList = useCallback(async (id) => {
		let response = null;

		const params = sanitizeObject({ ...queryParams.current, size: 10 });

		try {
			memoSearchParams.current(params);

			response = await api.get.humanResource.employeePendingLeave(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data.content.map((item) => item.id);

		setSelectedPending(boolean ? ids : []);
	}, [data]);

	//prettier-ignore
	const onHandleViewPendingLeave = useCallback((obj) => {
		pendingLeaveRef.current.onHandleShow(data.content[obj.rowIndex]);
	}, [data]);

	//prettier-ignore
	const onHandleRejectReason = useCallback(async (values, id) => {
		let response = null;

		try {
			await api.post.humanResource.rejectEmployeeLeave({ "leave-id": id, reason: values });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Leave was rejected successfully" }));

			onHandleGetList();
		}
	}, [dispatch, onHandleGetList]);

	//prettier-ignore
	const onHandleRejectReasons = useCallback(async (values, ids) => {
		let response = null;

		try {
			const payload = { leaveRequestIds: ids, rejectReason: values };

			await api.post.humanResource.rejectPendingLeave(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Leave was rejected successfully" }));

			onHandleGetList();
		}
	}, [dispatch, onHandleGetList]);

	const onHandleRejectLeave = useCallback((id) => {
		rejectionRef.current.onHandleShow(id);
	}, []);

	const onHandleRejectLeaves = useCallback(() => {
		rejectionRef.current.onHandleShow(selectedPending);
	}, [selectedPending]);

	const onHandleApproveLeave = useCallback(async () => {
		let response = null;

		try {
			const payload = { leaveRequestIds: selectedPending };

			await api.post.humanResource.approvePendingLeave(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Leave was approved successfully" }));

			onHandleGetList();
		}
	}, [selectedPending, dispatch, onHandleGetList]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.content.length > 0 && selectedPending.length === data.content.length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePendingID = data.content[rowIndex]?.id;
					const selected = selectedPending.includes(tablePendingID);

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">
										<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectPending(v, tablePendingID)} value={selected} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				},
			},
		},	
		{
			name: "leaveType",
			label: "Leave Type",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "days",
			label: "Days",
			options: {
				sort: false,
			},
		},	
		{
			name: "submitDate",
			label: "Submit Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY),
			},
		},
		{
			name: "startDate",
			label: "Start Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY),
			},
		},
		{
			name: "endDate",
			label: "End Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY),
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value) => <AppStatus status={value} />,
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleViewPendingLeave(tableMeta)}>
							<img src={eyeOpenIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [data, onHandleSelectAll, onHandleSelectPending, onHandleViewPendingLeave, selectedPending]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	//prettier-ignore
	const HeaderApprovalButton = useCallback((obj) => {
		if (!obj.selectedPending.length) return null;

		return (
			<div className="view-employee-pending-leave-table__header-button">
				<div className="view-employee-pending-leave-table__reject-button">
					<AppButton outline type="button" label="Reject" icon={rejectIcon} onClick={obj.onHandleRejectLeaves} />
				</div>

				<div className="view-employee-pending-leave-table__approve-button">
					<AppButton type="button" label="Approve" icon={approveIcon} onClick={obj.onHandleApproveLeave} />
				</div>
			</div>
		);
	}, []);

	useEffect(() => {
		onHandleGetList(id);
	}, [id, onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_PENDING_LEAVE);
		};
	}, [cancelRequest]);

	return (
		<div className="app-view-employee-pending-leave-table">
			<div className="view-employee-pending-leave-table">
				<div className="view-employee-pending-leave-table__header">
					<HeaderApprovalButton selectedPending={selectedPending} onHandleRejectLeaves={onHandleRejectLeaves} onHandleApproveLeave={onHandleApproveLeave} />
				</div>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			<AppViewPendingLeaveModal ref={pendingLeaveRef} onHandleRejectLeave={onHandleRejectLeave} onHandleGetList={onHandleGetList} />

			<AppViewEmployeePendingLeaveRejectionModal ref={rejectionRef} onHandleRejectReasons={onHandleRejectReasons} onHandleRejectReason={onHandleRejectReason} />
		</div>
	);
};

export default AppViewEmployeePendingLeaveTable;
