import { memo, useMemo } from "react";

import PropTypes from "prop-types";
import { TableCell, TableFooter, TableRow } from "@mui/material";

const AppCalendarScheduleFooter = (props) => {
	const data = useMemo(() => props.data, [props.data]);
	const rowsPerPage = useMemo(() => props.rowsPerPage, [props.rowsPerPage]);

	const accumulatedTime = useMemo(() => {
		let totalHours = 0;
		let totalMinutes = 0;

		if (data) {
			for (let i = 0; i < Math.min(rowsPerPage, data.length); i++) {
				const timeWorked = data[i]?.timeWorked;

				if (timeWorked) {
					const [hoursPart, minutesPart] = timeWorked.split("and ");
					const hours = parseInt(hoursPart, 10);
					const minutes = parseInt(minutesPart, 10);

					totalHours += hours;
					totalMinutes += minutes;
				}
			}

			totalHours += Math.floor(totalMinutes / 60);
			totalMinutes %= 60;
		}

		return { hours: totalHours, minutes: totalMinutes };
	}, [data, rowsPerPage]);

	return (
		<TableFooter>
			<TableRow>
				<TableCell className="app-calendar-schedule-footer" colSpan={100}>
					<div className="app-calendar-schedule-footer__accumulated-time">
						<span>Accumulated Time</span>
					</div>

					<div className="app-calendar-schedule-footer__time">
						<span>
							= {accumulatedTime.hours} Hours, {accumulatedTime.minutes} Minutes
						</span>
					</div>
				</TableCell>
			</TableRow>
		</TableFooter>
	);
};

export default memo(AppCalendarScheduleFooter);

AppCalendarScheduleFooter.propTypes = {
	rowsPerPage: PropTypes.number,
	data: PropTypes.array
};
