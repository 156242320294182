import COMMON from "common";

import Page404 from "pages/page-404";
import PagePayment from "pages/page-payment";
import PageDashboard from "pages/page-homePage";
import PageLinkExpired from "pages/page-link-expired";
import PageUserAccount from "pages/user-account/page-user-account";

import pathnames from "routes/pathnames";
import workCompletionReportRoutes from "routes/work-completion-report-routes";

import AppLayout from "components/app-layout";

import loginRoutes from "./login-routes";
import salesRoutes from "./sales-routes";
import customerRoutes from "./customer-routes";
import inventoryRoutes from "./inventory-routes";
import workOrderRoutes from "./work-order-routes";
import operationsRoutes from "./operations-routes";
import { protectedRoutes } from "../common/auth-routes";
import humanResourceRoutes from "./human-resources-routes";
import forgotPasswordRoutes from "./forget-password-routes";
import bulkDataHandlingRoutes from "./bulk-data-handling-routes";
import reportsAndInquiryRoutes from "./reports-and-inquiry-routes";
import deactivateMobileUserRoutes from "./deactivate-mobile-user-routes";
import workInspectionListingRoutes from "./work-inspection-listing-routes";

const token = localStorage.getItem(COMMON.AUTH_TOKEN) || sessionStorage.getItem(COMMON.AUTH_TOKEN);

const routes = [
	...loginRoutes,
	...salesRoutes,
	...customerRoutes,
	...inventoryRoutes,
	...workOrderRoutes,
	...operationsRoutes,
	...humanResourceRoutes,
	...forgotPasswordRoutes,
	...bulkDataHandlingRoutes,
	...reportsAndInquiryRoutes,
	...deactivateMobileUserRoutes,
	...workCompletionReportRoutes,
	...workInspectionListingRoutes,
	{
		path: pathnames.userAccount,
		element: <PageUserAccount />,
		authenticated: true
	},
	{
		path: pathnames.home,
		element: <PageDashboard />,
		authenticated: true
	},
	{
		path: pathnames.paymentPage,
		element: <PagePayment />,
		authenticated: true
	},
	{
		path: pathnames.linkExpired,
		element: <PageLinkExpired />,
		authenticated: false
	},
	{
		path: "*",
		element: <Page404 />,
		authenticated: !!token
	}
];

const enrichedRoutes = routes.map((route) => {
	if (route.authenticated) {
		return { ...route, loader: protectedRoutes, element: <AppLayout>{route.element}</AppLayout> };
	} else {
		return route;
	}
});

export default enrichedRoutes;
