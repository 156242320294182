import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import classNames from "common/class-names";
import stringEncryptRSA from "common/string-encrypt-rsa";
import generateRandomPassword from "common/generate-random-password";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import REGEX from "constants/regex";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppSelectInput from "components/app-select-input";
import AppCloseIcon from "components/icons/app-close-icon";
import AppPasswordChecklist from "components/app-password-checklist";
import AppMobileAppResetPasswordSuccessfulModal from "components/pages/customer/app-mobile-app-reset-password-successful-modal";
import AppMobileAppConfirmDeactivateAccountModal from "components/pages/customer/app-mobile-app-confirm-deactivate-account-modal";

import padLock from "assets/images/pad-lock-icon.png";
import deleteAccountIcon from "assets/images/pages/customer/delete-account-icon.svg";

const PageMobileAppCreateEdit = (props) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_USER], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const [isReactivating, setIsReactivating] = useState(false);
	const mobileAppResetPasswordSuccessfulModalRef = useRef();
	const mobileAppConfirmDeactivateAccountModalRef = useRef();

	//prettier-ignore
	const statusOptions = useMemo(() => [
		{ label: "Active", value: STATUS.ACTIVE },
		{ label: "Inactive", value: STATUS.INACTIVE }
	], []);

	const initialValues = useMemo(() => {
		const values = {
			email: "",
			status: "",
			approvalBy: "",
			newPassword: "",
			customerName: "",
			customerPicName: "",
			approvalDateTime: "",
			registerDateTime: "",
			lastModifiedDate: "",
			confirmNewPassword: "",
			lastModifiedByName: ""
		};

		return values;
	}, []);

	//prettier-ignore
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			newPassword: yup.string().min(8, ERRORS.LENGTH).required(ERRORS.REQUIRED).matches(REGEX.UPPERCASE, ERRORS.REQUIRED).matches(REGEX.LOWERCASE, ERRORS.REQUIRED).matches(REGEX.SYMBOL, ERRORS.REQUIRED).matches(REGEX.NUMERIC, ERRORS.REQUIRED),
			confirmNewPassword: yup.string().required(ERRORS.REQUIRED).oneOf([yup.ref("newPassword")], ERRORS.PASSWORDS_MATCH)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const accountActivationButtonClassName = useMemo(() => classNames({
		"mobile-app-create-edit__activation-button": true,
		"mobile-app-create-edit__activation-button--reactivate": formik.values.status === STATUS.INACTIVE
	}), [formik.values.status]);

	const accountActivationLabel = useMemo(() => (formik.values.status === STATUS.ACTIVE ? "Account Deactivation" : "Account Reactivation"), [formik.values.status]);

	const accountActivationText = useMemo(() => (formik.values.status === STATUS.ACTIVE ? "Deactivating a client’s account will disable their ability to use their email and password to access the M-Cube Client Mobile App" : "Reactivating a client’s account will allow the client to use the email and password as stated above to access the M-Cube Client Mobile App."), [formik.values.status]);

	const accountActivationButtonLabel = useMemo(() => (formik.values.status === STATUS.ACTIVE ? "Deactivate Account" : "Reactivate Account"), [formik.values.status]);

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Customer", path: pathnames.customer.customers },
			{ label: "Customer Listing", path: pathnames.customer.customers },
			{ label: "Edit Mobile App User", path: pathnames.customer.mobileAppCreateEdit + id }
		];

		return data;
	}, [id]);

	useBreadcrumb({ breadCrumb });

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				customerUserId: id,
				newPassword: stringEncryptRSA(values.confirmNewPassword, process.env.REACT_APP_CLIENT_RSA_PUBLIC_KEY)
			};

			await api.post.mobile.changePassword(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			mobileAppResetPasswordSuccessfulModalRef.current.onHandleShow({ confirmNewPassword: values.confirmNewPassword });
		}
	}, [formik, id]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.mobile.user(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				newPassword: "",
				confirmNewPassword: "",
				email: response.email,
				status: response.status,
				approvalBy: response.approvalBy,
				customerName: response.customerName,
				customerPicName: response.customerPicName,
				lastModifiedByName: response.lastModifiedByName,
				registerDateTime: dayjs(response.registerDateTime),
				approvalDateTime: dayjs(response.approvalDateTime),
				customercustomerPicNameName: response.customercustomerPicNameName,
				lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE)
			});
		}
	}, [memoSetFormValues]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.customer.customers);
	}, [navigate]);

	const onHandleGeneratePassword = useCallback(() => {
		const generatedPassword = generateRandomPassword();

		formik.setFieldValue("newPassword", generatedPassword);
		formik.setFieldValue("confirmNewPassword", generatedPassword);
	}, [formik]);

	const onHandleAccountActivation = useCallback(async () => {
		if (formik.values.status === STATUS.ACTIVE) {
			mobileAppConfirmDeactivateAccountModalRef.current.onHandleShow();
		}

		if (formik.values.status === STATUS.INACTIVE) {
			setIsReactivating(true);

			let response = null;
			let params = { id, "user-status": STATUS.ACTIVE };

			try {
				await api.post.mobile.changeUserStatus(params);

				response = true;
			} catch (error) {
				serveLayoutRequestErrors(error);
			} finally {
				setIsReactivating(false);
			}

			if (response) {
				dispatch(promptLayoutAlertMessage({ message: `Mobile App User was reactivated successfully!` }));

				onHandleGetDetails(id);
			}
		}
	}, [formik.values.status, id, dispatch, onHandleGetDetails]);

	useEffect(() => {
		onHandleGetDetails(id);

		return () => {
			if (id) memoCancelRequest(ENDPOINT_PATH.MOBILE.USER);
		};
	}, [id, memoCancelRequest, onHandleGetDetails]);

	return (
		<div className="page-mobile-app-create-edit">
			<div className="mobile-app-create-edit">
				<div className="mobile-app-create-edit__header">
					<h1 className="mobile-app-create-edit__title">Edit Mobile App User</h1>

					<p className="mobile-app-create-edit__last-update">
						<span>Last Updated By</span> {formik.values.lastModifiedByName}, {formik.values.lastModifiedDate}
					</p>
				</div>

				<form className="mobile-app-create-edit__form" onSubmit={formik.handleSubmit}>
					<div className="mobile-app-create-edit__container">
						<p className="mobile-app-create-edit__label">Details</p>

						<div className="mobile-app-create-edit__row">
							{/* prettier-ignore */}
							<AppSelectInput disabled searchable={false} name="status" label="Status" placeholder="Select..." options={statusOptions} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
						</div>

						<div className="mobile-app-create-edit__row">
							{/* prettier-ignore */}
							<AppInput disabled type="text" name="email" label="Email" placeholder="Enter Email" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInputDate disabled name="registerDateTime" label="Submit Date" placeholder="Submit Date" value={formik.values.registerDateTime} error={formik.errors.registerDateTime} touched={formik.touched.registerDateTime} onChange={formik.handleChange} />
						</div>

						<div className="mobile-app-create-edit__row">
							{/* prettier-ignore */}
							<AppInput disabled type="text" name="customerName" label="Customer" placeholder="Customer" value={formik.values.customerName} error={formik.errors.customerName} touched={formik.touched.customerName} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInput disabled type="text" name="customerPicName" label="Link to PIC" placeholder="PIC" value={formik.values.customerPicName} error={formik.errors.customerPicName} touched={formik.touched.customerPicName} onChange={formik.handleChange} />
						</div>

						<div className="mobile-app-create-edit__row">
							{/* prettier-ignore */}
							<AppInput disabled type="text" name="approvalBy" label="Approved By" placeholder="Approved By" value={formik.values.approvalBy} error={formik.errors.approvalBy} touched={formik.touched.approvalBy} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInputDate disabled name="approvalDateTime" label="Approved Date" placeholder="Approved Date" value={formik.values.approvalDateTime} error={formik.errors.approvalDateTime} touched={formik.touched.approvalDateTime} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="mobile-app-create-edit__container">
						<p className="mobile-app-create-edit__label">Reset Password</p>

						<div className="mobile-app-create-edit__row">
							<div className="mobile-app-create-edit__column">
								<AppInput disabled={restricted} type="password" name="newPassword" label="Reset Password" placeholder="Enter New Password" value={formik.values.newPassword} error={formik.errors.newPassword} touched={formik.touched.newPassword} onChange={formik.handleChange} />

								<div className="mobile-app-create-edit__password-details">
									<AppButton className="mobile-app-create-edit__generate-password-button" disabled={restricted} outline type="button" label="System Generate" icon={padLock} onClick={onHandleGeneratePassword} />

									<AppPasswordChecklist value={formik.values.newPassword} touched={formik.touched.newPassword} />
								</div>
							</div>

							<div className="mobile-app-create-edit__column">
								<AppInput disabled={restricted} type="password" name="confirmNewPassword" label="Confirm New Password" placeholder="Confirm New Password" iconInField={false} value={formik.values.confirmNewPassword} error={formik.errors.confirmNewPassword} touched={formik.touched.confirmNewPassword} onChange={formik.handleChange} />

								{formik.values.newPassword !== formik.values.confirmNewPassword && (
									<p className="mobile-app-create-edit__confirm-new-password">
										<span className="mobile-app-create-edit__cross">
											<AppCloseIcon color="#DC4C4C" width="12" height="12" viewBox="0 0 16 11" />
										</span>
										Passwords do not match
									</p>
								)}
							</div>
						</div>

						<div className="mobile-app-create-edit__reset-button">
							<AppButton type="submit" label="Reset" disabled={restricted || formik.isSubmitting} />
						</div>
					</div>

					<div className="mobile-app-create-edit__container">
						<p className="mobile-app-create-edit__label">{accountActivationLabel}</p>

						<div className="mobile-app-create-edit__row mobile-app-create-edit__row--activation-row">
							<p className="mobile-app-create-edit__text">{accountActivationText}</p>

							<AppButton className={accountActivationButtonClassName} disabled={restricted || isReactivating} outline type="button" label={accountActivationButtonLabel} icon={deleteAccountIcon} onClick={onHandleAccountActivation} />
						</div>
					</div>

					<div className="mobile-app-create-edit__button-container">
						<AppButton outline type="button" label="Back" onClick={onHandleBack} />
					</div>
				</form>
			</div>

			<AppMobileAppResetPasswordSuccessfulModal ref={mobileAppResetPasswordSuccessfulModalRef} />

			<AppMobileAppConfirmDeactivateAccountModal ref={mobileAppConfirmDeactivateAccountModalRef} onHandleGetDetails={onHandleGetDetails} />
		</div>
	);
};

export default PageMobileAppCreateEdit;
