import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import capitalizeCharacter from "common/capitalize-character";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ENDPOINT_PATH from "constants/end-point-path";

import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

/* prettier-ignore */
const AppCustomerTable = () => {	
	const navigate = useNavigate();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_USER], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", sort: searchParams.get("sort") || "", tab: "CUSTOMERS" });
	const [data, setData] = useState(convertPaginationTableData());

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.customer.customers(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleEditCustomer = useCallback((tableMeta) => {        
		const id = data.content[tableMeta.rowIndex]?.id;

		navigate(pathnames.customer.customerCreateEdit + id);
    }, [data, navigate]);

	const onHandleCreateCustomerList = useCallback(() => {
        navigate(pathnames.customer.customerCreateEdit + PAGE.CREATE);
    }, [navigate]);

	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false,
			},
		},
		{
			name: "referenceNo",
			label: "Customer ID",
			options: {
				sort: false,
			},
		},
		{
			name: "registeredName",
			label: "Registered Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "customerType",
			label: "Customer Type",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => capitalizeCharacter(value),
			},
		},
		{
			name: "companyNumber",
			label: "Company Number",
			options: {
				sort: false,
				customBodyRender: (value) => value || "-",
			},
		},
		{
			name: "customerAlias",
			label: "Customer Name",
			options: {
				sort: false,
				customBodyRender: (value) => value || "-",
			},
		},
		{
			name: "noOfContracts",
			label: "No. of Contracts",
			options: {
				sort: false,
			},
		},
		{
			name: "noOfSites",
			label: "No. of Sites",
			options: {
				sort: false,
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditCustomer(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [onHandleEditCustomer]);	

	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;
					
					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) } 

					onHandleGetList();
					break;
				default:
					break;    
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.CUSTOMER.CUSTOMERS);
		};
	}, [cancelRequest]);

	return (
		<div className="app-customer-table">
			<div className="customer-table">
				{/* prettier-ignore */}
				<AppTableFilterHeader searchPlaceholder="Search by Registered Name or Customer Name" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleCreateCustomerList} /> 
								
				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>
		</div>
	);
};

export default AppCustomerTable;
